import React, { useEffect, useState } from 'react';
import { BoxV2 as Box, Button, Flex, TextInput } from 'portal-commons';
import { faRectangleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../../../../components';
import { isValidInput } from '../../../../../utils';

interface Props {
  open: boolean;
  campaignUid: string;
  onClose?: () => void;
  onSubmit?: (value?: string) => void;
}

const REASON_MAX_LENGTH = 2048;

const CancelMigrationModal: React.FC<Props> = ({
  open,
  campaignUid,
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setError('');
    }
  }, [open]);

  const handleChange = (val: string) => {
    setValue(val);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    let errorMsg: string | undefined;
    if (typeof value === 'string' && value.length > REASON_MAX_LENGTH) {
      errorMsg = `Maximum ${REASON_MAX_LENGTH} chars are allowed`;
    } else if (!isValidInput(value)) {
      errorMsg = 'Invalid input';
    }

    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    onSubmit?.(value);
  };

  return (
    <Modal testId="cancelMigrationModal" open={open}>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faRectangleXmark} />
        <Box as="span">Cancel Migration</Box>
      </Flex>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
          width: '100%',
        }}
      >
        Please confirm that you are canceling{' '}
        <Box as="span" sx={{ fontWeight: 800 }}>
          1 campaign migration(s).
        </Box>
        <br />
        <br />
        Campaign ID(s):
        <br />
        <Box as="span" sx={{ fontWeight: 600 }}>
          {campaignUid}
        </Box>
      </Box>
      <Flex
        sx={{
          mt: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '12px',
          width: '100%',
          color: '#333E41',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <TextInput
            label="Explanation"
            placeholder="Provide a cancel explanation"
            error={!!error}
            maxLength={REASON_MAX_LENGTH}
            note={error}
            value={value}
            onChange={handleChange}
            data-testid="cancelMigrationExplanation"
          />
        </Box>
      </Flex>
      <Flex
        sx={{
          justifyContent: 'center',
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button
          variant="outline"
          onClick={handleCancel}
          data-testid="cancelMigrationCancelButton"
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          disabled={!!error}
          data-testid="cancelMigrationApplyButton"
        >
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};

export default CancelMigrationModal;
