import { BoxV2 as Box } from 'portal-commons';
import { forwardRef } from 'react';

import { CampaignOperation } from '../../types';

interface CarrierTermsProps {
  mnoList: CampaignOperation[];
}

const CarrierTerms = forwardRef<HTMLDivElement, CarrierTermsProps>(
  ({ mnoList }, ref) => {
    return (
      <Box
        ref={ref}
        id="mnoInfoList"
        sx={{
          position: 'absolute',
          left: 0,
          top: '38px',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          rowGap: '9px',
          width: 'calc(100% - 20px)',
          padding: '12px 0',
          margin: '0 16px',
          borderTop: '1px solid #C0CCD4',
          textAlign: 'left',
        }}
      >
        {mnoList.map((item, index) => (
          <Box as="span" key={index}>
            <Box
              as="p"
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
                borderRight:
                  index % 3 === 2
                    ? 'none'
                    : '1px solid rgba(104, 115, 122, 0.2)',
                paddingLeft: index % 3 === 0 ? 0 : '74px',
              }}
            >
              <Box
                as="span"
                sx={{
                  marginRight: '2px',
                  fontWeight: 500,
                  color: '#D41C54',
                }}
              >
                {item.rulesEngineResults.mno}
              </Box>
              {item.rulesEngineResults.mno === 'T-Mobile' ? (
                <>
                  Brand Tier:
                  <Box
                    as="span"
                    sx={{
                      marginLeft: '4px',
                      fontWeight: 700,
                      color: '#68737A',
                    }}
                  >
                    {item.rulesEngineResults.brandTier}
                  </Box>
                </>
              ) : (
                <>
                  Message Class:
                  <Box
                    as="span"
                    sx={{
                      marginLeft: '4px',
                      fontWeight: 700,
                      color: '#68737A',
                    }}
                  >
                    {item.rulesEngineResults.msgClass || 'N/A'}
                  </Box>
                </>
              )}
              <br />
              MNO Status:
              <Box
                as="span"
                sx={{
                  marginLeft: '4px',
                  fontWeight: 700,
                  color: '#68737A',
                }}
              >
                {item.status.toLowerCase() === 'approved'
                  ? 'REGISTERED'
                  : item.status}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
);

export default CarrierTerms;
