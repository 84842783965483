import {
  BoxV2 as Box,
  Button,
  CustomCheckbox,
  Flex,
  RoleGuard,
} from 'portal-commons';
import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { faHourglass, faSquareXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import CarrierTerms from '../CarrierTerms';
import { getCampaignOperations } from '../../apis';
import { CampaignOperation, PartnerCampaign } from '../../types';
import { remapTCRStatus } from '../../utils';
import { sortMnoList } from '../../../../../utils';
import { convertTimeWithTimezone } from '../../../../../utils/time';

interface PendingCampaignListingRowProps {
  selectable?: boolean;
  selected?: boolean;
  error?: boolean;
  campaign: PartnerCampaign;
  onChange?: (selected: boolean) => void;
  onElect?: () => void;
  onDecline?: () => void;
}

const PendingCnpCampaignListingRow: FunctionComponent<
  PendingCampaignListingRowProps
> = ({
  selectable = false,
  selected = false,
  error = false,
  campaign,
  onChange,
  onElect,
  onDecline,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [height, setHeight] = useState(0);
  const [mnoInfo, setMnoInfo] = useState<CampaignOperation[]>([]);
  const [showMnoInfo, setShowMnoInfo] = useState(false);
  const carrierTermRef = useRef<HTMLDivElement>(null);
  const dataExpired = campaign.status === 'EXPIRED';

  const additionalCellStyle = useMemo(
    () =>
      height > 0
        ? { paddingTop: '12px' }
        : { height: '38px', paddingTop: 0, paddingBottom: 0 },
    [height]
  );

  useEffect(() => {
    if (showMnoInfo && carrierTermRef.current) {
      setHeight(carrierTermRef.current.clientHeight + 38);
    }
  }, [showMnoInfo]);

  const getMnoInfo = async () => {
    if (mnoInfo.length) {
      setShowMnoInfo(true);
    } else {
      const operations = await getCampaignOperations(campaign.uid);
      if (operations.length > 0) {
        setMnoInfo(operations);
        setShowMnoInfo(true);
      }
    }
  };

  const handleToggleCarrierTerm = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    const nextVisibility = !showMnoInfo;
    if (nextVisibility) {
      getMnoInfo();
    } else {
      setShowMnoInfo(false);
      setHeight(0);
    }
  };

  return (
    <TableRow
      style={{
        position: 'relative',
        height: mnoInfo ? `${height}px` : '38px',
        cursor: 'pointer',
        ...(error ? { background: '#F5DDE2' } : {}),
        ...(height ? { verticalAlign: 'top' } : {}),
      }}
      onClick={() =>
        history.push({
          pathname: `/cnp-campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      {selectable && (
        <TableCell
          align="left"
          style={{ width: 40, ...additionalCellStyle }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <CustomCheckbox
            checked={selected}
            onChange={(event) => {
              onChange?.(event.target.checked);
            }}
          />
        </TableCell>
      )}
      <TableCell align="left" style={{ width: 100, ...additionalCellStyle }}>
        {campaign.uid}
      </TableCell>
      <TableCell align="left" style={{ width: 79, ...additionalCellStyle }}>
        {campaign.brandUid}
      </TableCell>
      <TableCell
        align="left"
        style={{
          width: '10%',
          maxWidth: '150px',
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...additionalCellStyle,
        }}
      >
        <span title={campaign.usecase}>{campaign.usecase}</span>
      </TableCell>
      <TableCell align="left" style={{ width: 91, ...additionalCellStyle }}>
        {campaign.registeredOnDate
          ? convertTimeWithTimezone(
              campaign.registeredOnDate,
              undefined,
              'MM/DD/YYYY'
            )
          : ''}
      </TableCell>
      <TableCell
        align="left"
        style={{
          width: '10%',
          maxWidth: '150px',
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...additionalCellStyle,
        }}
      >
        <span title={campaign.downstreamCnpName}>
          {campaign.downstreamCnpName}
        </span>
      </TableCell>
      <TableCell align="left" style={{ width: 145, ...additionalCellStyle }}>
        {campaign.sharedOnDate
          ? convertTimeWithTimezone(
              campaign.sharedOnDate,
              undefined,
              'MM/DD/YYYY'
            )
          : ''}
      </TableCell>
      <TableCell align="left" style={{ width: 92, ...additionalCellStyle }}>
        <Box
          as="span"
          sx={{
            position: 'relative',
            left: '10px',
            cursor: 'pointer',
            ...(height ? { top: '-5px', color: '#D41C54' } : {}),
            '&:hover': {
              color: '#D41C54',
            },
          }}
        >
          <MoreHorizIcon onClick={handleToggleCarrierTerm} />
        </Box>
        {showMnoInfo && !!mnoInfo.length && (
          <CarrierTerms
            mnoList={sortMnoList(mnoInfo, ['rulesEngineResultsJson', 'mno'])}
            ref={carrierTermRef}
          />
        )}
      </TableCell>
      <TableCell
        align="left"
        style={{
          width: 85,
          maxWidth: '150px',
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...additionalCellStyle,
        }}
      >
        <span title={remapTCRStatus(campaign.status)}>
          {remapTCRStatus(campaign.status)}
        </span>
      </TableCell>
      <TableCell align="left" style={{ width: 223, ...additionalCellStyle }}>
        <Flex
          sx={{
            alignItems: 'center',
            ...(height && !dataExpired ? { marginTop: '-5px' } : {}),
          }}
        >
          <Flex sx={{ alignItems: 'center', width: '90px', fontWeight: 500 }}>
            <FontAwesomeIcon
              icon={
                campaign.sharedToStatus === 'DECLINED'
                  ? faSquareXmark
                  : faHourglass
              }
              style={{ marginRight: 4 }}
            />
            {campaign.sharedToStatus === 'DECLINED' ? 'REJECTED' : 'PENDING'}
          </Flex>
          {!dataExpired && (
            <RoleGuard feature="cnpCampaignList.elect">
              <Button
                style={{
                  height: '24px',
                  padding: '5px 10px',
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: 1.17,
                }}
                shape="rounded"
                disabled={campaign.status === 'EXPIRED'}
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  onElect?.();
                }}
              >
                {campaign.sharedToDate ? 'Re-Elect' : 'Elect'}
              </Button>
            </RoleGuard>
          )}
          {campaign.sharingStatus === 'PENDING' && !dataExpired && (
            <RoleGuard feature="cnpCampaignList.decline">
              <Button
                style={{
                  height: '24px',
                  padding: '5px 10px',
                  marginLeft: '5px',
                  borderColor: '#68737A',
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: 1.17,
                  background: '#68737A',
                }}
                shape="rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  onDecline?.();
                }}
              >
                Decline
              </Button>
            </RoleGuard>
          )}
        </Flex>
      </TableCell>
    </TableRow>
  );
};

export default PendingCnpCampaignListingRow;
