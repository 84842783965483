import { Button, Flex, BoxV2 as Box, RoleGuard } from 'portal-commons';
import { faAddressBook } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';

import { BrandDetail } from '../../types';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { resend2faEmail } from '../../apis';
import { toastFlashMessage } from '../../../../../utils';
import BrandDetailItem from '../BrandDetailItem';

interface BusinessContactDetailsProps {
  brand: BrandDetail;
}

const BusinessContactDetails: React.FC<BusinessContactDetailsProps> = ({
  brand,
}) => {
  const handleResend = async () => {
    const response = await resend2faEmail(brand.uid!);
    if (response) {
      toastFlashMessage('2FA email sent successfully', 'success');
    }
  };

  return (
    <>
      <div className="details-heading contact-details">
        <div className="title" style={{ width: '100%' }}>
          <Flex
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: '8px',
            }}
          >
            <FontAwesomeIcon icon={faAddressBook} size="xl" />
            <h3 className="heading1" style={{ flex: 1 }}>
              Business Contact Details
            </h3>
            {brand.businessContactEmail &&
              !brand.businessContactEmailVerifiedDate && (
                <RoleGuard feature="brandDetail.send2faEmail">
                  <Button shape="square" size="small" onClick={handleResend}>
                    Resend 2FA Email
                  </Button>
                </RoleGuard>
              )}
          </Flex>
        </div>
      </div>
      <Grid container data-testid="businessContactDetails">
        <Grid item xs={6}>
          <BrandDetailItem
            xs={5}
            title="Name"
            value={`${brand.businessContactFirstName ?? ''} ${
              brand.businessContactLastName ?? ''
            }`.trim()}
          />
          <BrandDetailItem
            xs={5}
            title="Business Email Address"
            value={brand.businessContactEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <BrandDetailItem
            xs={5}
            title="Title"
            value={brand.businessContactTitle}
          />
          <BrandDetailItem
            xs={5}
            title="Email Verified Date"
            value={
              brand.businessContactEmailVerifiedDate
                ? convertTimeWithTimezone(
                    brand.businessContactEmailVerifiedDate,
                    undefined,
                    'MM/DD/YYYY'
                  )
                : ''
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessContactDetails;
