import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tooltip } from 'portal-commons';

// To override Tooltip's default styles portal-commons
const useStyles = makeStyles({
  arrow: {
    '&&': {
      color: '#0091B3',
    },
  },
  tooltip: {
    '&&': {
      color: '#fff',
      fontSize: 12,
      borderRadius: '3px !important',
      maxWidth: '400px !important',
      fontFamily: 'Roboto',
      fontWeight: 400,
      padding: '5px 15px !important',
      backgroundColor: '#0091B3',
    },
  },
  tooltipPlacementTop: {
    '&&': {
      top: 10,
    },
  },
  tooltipPlacementLeft: {
    '&&': {
      borderBottomRightRadius: '3px !important',
    },
  },
  tooltipPlacementRight: {
    '&&': {
      borderBottomLeftRadius: '3px !important',
    },
  },
});

interface Props {
  children?: ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const CustomTooltip: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.title}
      classes={classes}
      style={{ color: '#68737A' }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
