import React, { FC, useState, MouseEvent } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface MenuItemConfig<T> {
  label: string;
  onClick: (data: T) => Promise<void> | void;
  disabled?: boolean;
  hidden?: boolean;
  [key: string]: any;
}

interface MoreButtonProps<T> {
  data: T;
  items: MenuItemConfig<T>[];
  menuClass?: string;
}

const MoreButton = <T,>({
  data,
  items,
  menuClass,
  ...restProps
}: MoreButtonProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MoreVertIcon
        className="pointer"
        style={{ color: '#D41C54' }}
        onClick={handleClick}
        {...restProps}
      />
      <Menu
        className={menuClass}
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items
          .filter(({ hidden }) => !hidden)
          .map((item) => {
            const { label, onClick, ...rest } = item;
            return (
              <MenuItem
                key={label}
                onClick={() => {
                  onClick?.(data);
                  handleClose();
                }}
                {...rest}
              >
                {label}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default MoreButton;
