import { CustomCheckbox } from 'portal-commons';
import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';

import { CspCampaign } from '../../types';
import { remapTCRStatus } from '../../utils';
import { convertTimeWithTimezone } from '../../../../../utils/time';

interface MyCampaignListingRowProps {
  selectable?: boolean;
  selected?: boolean;
  error?: boolean;
  campaign: CspCampaign;
  onChange?: (selected: boolean) => void;
}

const MyCampaignListingRow: FunctionComponent<MyCampaignListingRowProps> = ({
  selectable = false,
  selected = false,
  error = false,
  campaign,
  onChange,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <TableRow
      className="link"
      style={error ? { background: '#F5DDE2' } : {}}
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      {selectable && (
        <TableCell
          align="left"
          style={{ width: 40 }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <CustomCheckbox
            checked={selected}
            onChange={(event) => {
              onChange?.(event.target.checked);
            }}
          />
        </TableCell>
      )}
      <TableCell align="left" style={{ width: 125 }}>
        <span>{campaign.uid}</span>
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {campaign.brandUid}
      </TableCell>
      <TableCell align="left" style={{ width: 175 }}>
        {campaign.brandName}
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {campaign.usecase}
      </TableCell>
      <TableCell align="left" style={{ width: 150 }}>
        {convertTimeWithTimezone(campaign.createDate, undefined, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell align="left" style={{ width: 170 }}>
        {campaign.upstreamCnpName ?? 'N/A'}
      </TableCell>
      <TableCell align="left" style={{ width: 170 }}>
        {campaign.resellerName ?? 'N/A'}
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {remapTCRStatus(campaign.status)}
      </TableCell>
    </TableRow>
  );
};

export default MyCampaignListingRow;
