import { OperationErrorDetails } from './types';

export class BulkOperationError extends Error {
  details: Record<string, OperationErrorDetails>;

  constructor(
    details: Record<string, OperationErrorDetails>,
    message?: string
  ) {
    super(message);
    this.name = 'BulkOperationError';
    this.details = details;
    Object.setPrototypeOf(this, BulkOperationError.prototype);
  }
}
