import React, { useState } from 'react';
import { Container, Grid, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { charCounter } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '40px',
    // maxHeight: '40px',
    padding: '13px 10px',
    background: '#FFFFFF',
  },
  input: {
    width: '100%',
    padding: '0',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  'MuiFormHelperText-root': {
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  },
}));

const MessageInput = ({
  placeholder,
  error,
  value,
  onValueChange,
  required,
  onBlur,
  showCharCounter,
  maxLength,
}) => {
  const classes = useStyles();

  const handleTextChange = (event) => {
    onValueChange && onValueChange(event.target.value);
  };

  const getHelperText = (helperText = '') => {
    return showCharCounter && value
      ? charCounter({ value, maxLength: maxLength || 1024 })
      : helperText;
  };

  return (
    <Container
      classes={{ root: classes.container }}
      style={{ border: error ? '1px solid red' : 'none' }}
    >
      <TextField
        classes={{
          root: showCharCounter ? classes['MuiFormHelperText-root'] : '',
        }}
        required={required}
        style={{ width: '100%' }}
        InputProps={{ disableUnderline: true }}
        inputProps={{ className: classes.input, maxLength: maxLength || 1024 }}
        placeholder={`${placeholder} ${required ? '*' : ''}`}
        value={value}
        onChange={handleTextChange}
        onBlur={onBlur}
        helperText={getHelperText()}
      />
      {error && (
        <h6 className="error-msg" style={{ marginTop: 16 }}>
          {error}
        </h6>
      )}
    </Container>
  );
};

MessageInput.propTypes = {
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
};

export default MessageInput;
