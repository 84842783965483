import React, { useState } from 'react';
import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
} from 'portal-commons';
import { faArrowProgress } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectItem } from '../../../../../utils/types';
import { Modal } from '../../../../../components';

interface Props {
  open: boolean;
  options?: SelectItem[];
  onClose?: () => void;
  onSubmit?: (value: string) => void;
}

const InitiateMigrationModal: React.FC<Props> = ({
  open,
  options = [],
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const handleChange = (val: string) => {
    setValue(val);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    if (!value) {
      setError('Select a connectivity partner');
      return;
    }

    onSubmit?.(value);
    setValue('');
  };

  return (
    <Modal testId="initiateMigrationModal" open={open}>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faArrowProgress} />
        <Box as="span">Initiate Migration</Box>
      </Flex>
      <Box
        sx={{
          paddingBottom: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
        }}
      >
        Please select a new CNP who will receive the messaging campaign(s).{' '}
        <Box as="span" sx={{ fontWeight: 700 }}>
          By making a selection you are automatically accepting the campaign
          that has been shared with you.
        </Box>{' '}
        Failure to elect a CNP may hinder the campaign from reaching the MNO.
        <br />
        <br />
        The initiator can cancel a migration at any time before it is complete.
        If migrations are not completed within 30 calendar days, they are
        automatically canceled.
        <br />
        <br />
        <Box as="span" sx={{ fontWeight: 700 }}>
          Note:
        </Box>{' '}
        If a Sole Proprietor campaign is in the selection, the CNP list will
        only show connectivity partners capable of managing Sole Proprietor
        campaigns.
        <br />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '12px',
          width: '100%',
          color: '#333E41',
        }}
      >
        <Box as="span" sx={{ fontSize: '10px', lineHeight: '12px' }}>
          *Required Field
        </Box>
        <Dropdown
          required
          label="Select new Connectivity Partner"
          error={!!error}
          helperText={error}
          options={options}
          value={value}
          onChange={handleChange}
          data-testid="initiateMigrationOptions"
        />
      </Flex>
      <Flex
        sx={{
          justifyContent: 'center',
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button
          variant="outline"
          onClick={handleCancel}
          data-testid="initiateMigrationCancelButton"
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          data-testid="initiateMigrationApplyButton"
        >
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};

export default InitiateMigrationModal;
