import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, Slide, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from 'portal-commons';
import { S3_ASSETS_PATH } from '../../constants';
import PDFViewer from './PDFViewer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TermsAndConditions = (props) => {
  const [open, setOpen] = useState(false);
  const [enable, setEnabled] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [termsConditions, setTermsConditions] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
    setEnabled(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setTermsConditions(props.termsConditions[0]);
  }, [props.termsConditions]);
  return (
    <div className="terms-conditions-wrapper">
      <p className="tc-button text-center">
        <a onClick={handleClickOpen}>
          Click <span>here to view and agree to T&Cs.</span>
        </a>
      </p>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="temrs-conditions-dialog"
      >
        <Grid container className="terms-conditions-container">
          <Grid item xs={12}>
            <h3 className="heading2 text-center">
              <img src={`${S3_ASSETS_PATH}/images/terms-conditions-icon.svg`} />
              <span>
                {termsConditions?.title
                  ? `${termsConditions.title.toUpperCase()}`
                  : 'LEGAL TERMS AND CONDITIONS'}
              </span>
              <CloseIcon onClick={handleClose} className="close-icon" />
            </h3>
          </Grid>
          <PDFViewer
            url="https://www.campaignregistry.com/TCR-T&Cs.pdf"
            onScrollToBottom={() => setEnabled(true)}
          />
          <Grid item xs={12} className="footer-blk">
            <ul className="list-inline">
              <li>
                <p>
                  <a onClick={handleClose} className="secondary-btn">
                    Cancel
                  </a>
                </p>
              </li>
              <li>
                <Tooltip
                  open={openTooltip}
                  title={
                    enable
                      ? ''
                      : 'Please scroll down to read and agree the terms&conditions'
                  }
                  placement="top-end"
                >
                  <p
                    onMouseEnter={() => setOpenTooltip(true)}
                    onMouseLeave={() => setOpenTooltip(false)}
                  >
                    <a
                      className={
                        enable ? 'primary-btn' : 'primary-btn disabled'
                      }
                      onClick={() => {
                        handleClose();
                        props.handleClick();
                      }}
                    >
                      I Agree
                    </a>
                  </p>
                </Tooltip>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => ({
  termsConditions: state.shareReducer.termsConditions,
});
export default connect(mapStateToProps)(TermsAndConditions);
