import {
  Table,
  Button,
  BoxV2 as Box,
  Flex,
  MultimediaUploader,
  MultimediaList,
  AttachmentPreview,
  withPermission,
  RoleGuard,
  Tooltip,
} from 'portal-commons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  faShareNodes,
  faSquarePollHorizontal,
  faMessageDots,
  faFileImage,
  faArrowProgress,
  faCalendarRange,
  faPen,
  faAngleLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, withStyles } from '@material-ui/core';
import {
  getSupportedFileFormats,
  getUseCaseGreyOutFields,
  initiateCampaignMigrations,
  cancelCampaignMigrations,
  getCnpList,
} from '../apis';
import {
  getCampaignDetailsApi,
  getCampaignOperationsApi,
  getResellerDetailApi,
  updateCampaignApi,
  deactivateCampaignApi,
  listCampaignElectedDcaApi,
  getNominationStatusApi,
  getCampaignAttachmentsApi,
  downloadAttachmentApi,
  deleteAttachmentApi,
  uploadAttachmentForCampaignApi,
  getCampaignTermsAndConditions,
  getEventHistory,
  nudgeUpstreamCnp,
  downloadAttachmentToDesktop,
  getBuilderInfo,
  resubmitCampaign,
  updateCampaign,
  getCampaignRoles,
} from '../apiServices';
import {
  CampaignDetailsBasic,
  CampaignSampleMessage,
  MnoStatusTableNew,
  CampaignAttributeNew,
  ReSellerDetails,
  CampaignEditModal,
  ConfirmationDialog,
  ElectCNPAlert,
  CNPDetails,
  ElectCNPDialog,
  NumbersProvisioned,
  MaskedContainer,
} from '../components';
import CampaignDetailsSection from '../components/CampaignDetailsSection';
import CampaignResubmissionModal from '../components/CampaignResubmissionModal';
import DeleteSampleMultimediaModal from '../components/DeleteSampleMultimediaModal';
import EmbeddedLinkPopup from '../components/EmbeddedLinkPopup';
import EventsListingRow from '../components/EventListingRow';
import MenuButton from '../components/MenuButton';
import MessageFlowAttributePopup from '../components/MessageFlowAttributePopup';
import RequestAppealRejectionModal from '../components/RequestAppealRejectionModal';
import RequestReviewModal from '../components/RequestReviewModal';
import ResubmissionConfirmationModal from '../components/ResubmissionConfirmationModal';
import SupportingDocumentSection from '../components/SupportingDocumentSection';
import UploadSizeExceededModal from '../components/UploadSizeExceededModal';
import {
  ATTACHMENT_FOLDER_MMS,
  MAX_FILE_SIZE,
  MAX_SAMPLE_MULTIMEDIA_COUNT,
  NUDGE_TYPES,
} from '../constants';
import { AttachmentFolder } from '../enums';
import { getMigrationAndProvisionalInfo, isFieldGreyOut } from '../utils';
import { campaignErrorCode as errorCode } from '../../index';
import { Loader } from '../../../../shared_elements';
import { toastFlashMessage } from '../../../../utils';
import { fieldValidation } from '../../../../utils/validator';
import '../../../../assets/styles/campaign-detail-module.scss';
import {
  MY_CAMPAIGNS_DEFAULT_ENTRY,
  MY_CAMPAIGNS_PATH,
} from '../../../../constants/paths';

const REVIEW_ACTION_VALUE = 0;
const APPEAL_REJECTION_ACTION_VALUE = 1;
const RESUBMIT_ACTION_VALUE = 2;
const DEACTIVATE_ACTION_VALUE = 3;

const MENU_OPTIONS = [
  {
    label: 'Nudge CNP to Review',
    value: REVIEW_ACTION_VALUE,
  },
  {
    label: 'Nudge CNP to Appeal Rejection',
    value: APPEAL_REJECTION_ACTION_VALUE,
  },
  {
    label: 'Resubmit Campaign',
    value: RESUBMIT_ACTION_VALUE,
  },
  {
    label: 'Deactivate Campaign',
    value: DEACTIVATE_ACTION_VALUE,
  },
];

const headRows = [
  {
    id: 'eventType',
    label: 'EVENT TYPE',
    sortable: false,
  },
  {
    id: 'categoryType',
    label: 'SOURCE',
    sortable: false,
  },
  {
    id: 'campaignUid',
    label: 'CAMPAIGN ID',
    sortable: false,
  },
  {
    id: 'brandUid',
    label: 'BRAND ID',
    sortable: false,
  },
  {
    id: 'updateDate',
    label: 'DATE & TIME',
    sortable: false,
  },
  {
    id: 'description',
    label: 'DESCRIPTION',
    sortable: false,
  },
];

const useStyles = (theme) => ({
  button: {
    background: '#D41C54',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      background: '#D41C54',
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 226,
    background: '#FFFFFF',
  },
});

const HeadingBlock = ({ icon, title, children, ...props }) => (
  <div className="details-heading" {...props}>
    <Flex sx={{ alignItems: 'center' }}>
      <Box sx={{ mr: 'xs' }}>
        <FontAwesomeIcon icon={icon} size="xl" color="#49535D" />
      </Box>
      <h3 className="heading1">{title}</h3>
    </Flex>
    {children}
  </div>
);

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      campaign: {},
      goBackPage: MY_CAMPAIGNS_DEFAULT_ENTRY,
      resellerInfo: {},
      dcaInfo: {},
      renewalData: '',
      deactivateData: '',
      electedDcas: [],
      electCnpOptions: [],
      migrateCnpOptions: [],
      nominationInfo: {},
      openAlert: false,
      electCnpFlag: false,
      campaignUid: '',
      sampleMultimedia: [],
      sampleMultimediaIndex: -1,
      sampleMultimediaFile: null,
      sampleMultimediaDeleteIndex: -1,
      sampleMultimediaLoading: false,
      uploadSizeExceededOpen: false,
      termsAndConditions: [],
      optinKeywords: [],
      optinMessage: '',
      optinPopup: false,
      optinChange: false,
      optoutKeywords: [],
      optoutMessage: '',
      optoutPopup: false,
      optoutChange: false,
      helpKeywords: [],
      helpMessage: '',
      helpPopup: false,
      helpChange: false,
      embeddedLinkSample: '',
      embeddedLinkPopup: false,
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: false,
      reviewFlag: false,
      appealRejectionFlag: false,
      campaignResubmissionModalOpen: false,
      resubmissionConfirmationModalOpen: false,
      resubmissionConfirmationDisabled: false,
      deactivateFlag: false,
      error: {
        optinMessage: '',
        optoutMessage: '',
        helpMessage: '',
      },
      newMnoTerms: {},
      selectedNewMnos: [],
      mnoStatusInfo: [],
      migrationInfo: null,
      provisional: null,
      supportedFileFormats: {},
      greyOutFields: [],
      cnpMigrating: false,
    };
    this.getCampaignDetailsApi = getCampaignDetailsApi.bind(this);
    this.getCampaignOperationsApi = getCampaignOperationsApi.bind(this);
    this.getResellerDetailApi = getResellerDetailApi.bind(this);
    this.updateCampaignApi = updateCampaignApi.bind(this);
    this.deactivateCampaignApi = deactivateCampaignApi.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    document.title = 'The Campaign Registry - Campaigns';
    const campaignUid = this.deriveCampaignId(this.props);
    if (this.props.location.state) {
      const { goBackPage } = this.props.location.state;
      this.setState({
        goBackPage,
      });
    }

    try {
      const roles = await getCampaignRoles(campaignUid);
      const isSharedCampaign =
        roles?.normalRole === 'CNP' || roles?.provisionalRole === 'CNP';
      if (isSharedCampaign) {
        this.props.history.replace({
          pathname: `/cnp-campaigns/${campaignUid}`,
          state: { goBackPage: this.state.goBackPage },
        });
      } else {
        this.updateProvisionalFlag(campaignUid);
        this.initialize(campaignUid);
      }
    } catch (error) {}

    const fileFormats = await getSupportedFileFormats(
      AttachmentFolder.SampleMultimedia
    );
    if (fileFormats) {
      this.setState({
        supportedFileFormats: fileFormats,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.campaign?.uid !== this.state.campaign?.uid) {
      this.updateGreyOutFields(this.state.campaign);
    }
    const prevCampaignUid = this.deriveCampaignId(prevProps);
    const campaignUid = this.deriveCampaignId(this.props);

    if (prevCampaignUid !== campaignUid) {
      this.setState({ provisional: null, campaign: null });
      this.updateProvisionalFlag(campaignUid);
      return;
    }

    if (this.state.provisional == null) {
      return;
    } else if (prevState.provisional !== this.state.provisional) {
      this.updateProvisionalDependentData(campaignUid, this.state.provisional);
      return;
    }

    if (this.props.cspData.length && this.state.renewalData == '') {
      const renewalData = this.props.cspData.find(
        (item) =>
          item.lookup_key == 'csp-campaign-dialog-confirm#cancel-auto-renewal'
      );
      this.setState({
        renewalData,
      });
    }
    if (this.props.cspData.length && this.state.deactivateData == '') {
      const deactivateData = this.props.cspData.find(
        (item) => item.lookup_key == 'csp-campaign-dialog-confirm#deactivate'
      );
      this.setState({
        deactivateData,
      });
    }
    if (
      this.state.campaign.brandUid &&
      prevState.campaign.brandUid !== this.state.campaign.brandUid
    ) {
      getBuilderInfo(this.state.campaign.brandUid, this.state.campaign.usecase)
        .then((response) => {
          this.setState({ newMnoTerms: response.mnoMetadata });
        })
        .catch((e) => {});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deriveCampaignId = (props) => {
    return props.match.params.id;
  };

  updateProvisionalDependentData = (campaignUid, provisional) => {
    this.updateCampaignDetails(campaignUid, provisional);
    this.updateNominationStatus(campaignUid, provisional);
    this.updateElectedDca(campaignUid, provisional);
  };

  updateCampaignDetails = async (campaignUid, provisional) => {
    this.setState({
      tableLoader: true,
    });
    const campaign = await this.getCampaignDetailsApi(campaignUid, {
      provisional,
    });

    this.setState({
      tableLoader: false,
    });

    this.updateCnpOptions(campaign?.usecase, provisional);
  };

  updateCnpOptions = async (usecase, provisional) => {
    const soleProprietor = usecase === 'SOLE_PROPRIETOR' ? true : undefined;
    const electCnpParams = {
      cnpMigrationSupported: !provisional ? undefined : true,
      soleProprietorEnabled: soleProprietor,
    };
    const migrateCnpParams = {
      cnpMigrationSupported: true,
      soleProprietorEnabled: soleProprietor,
    };
    const [electCnps, migrateCnps] = await Promise.all([
      getCnpList(electCnpParams),
      getCnpList(migrateCnpParams),
    ]);

    this.setState({
      electCnpOptions: electCnps.map((cnp) => ({
        label: cnp.displayName,
        value: cnp.uid,
      })),
      migrateCnpOptions: migrateCnps.map((cnp) => ({
        label: cnp.displayName,
        value: cnp.uid,
      })),
    });
  };

  updateProvisionalFlag = async (campaignUid) => {
    const result = await getMigrationAndProvisionalInfo(campaignUid);
    this.setState({
      migrationInfo: result.migrationInfo,
      provisional: result.provisional,
    });
    return result.provisional;
  };

  // requests that are independent on provisional flag
  initialize = async (campaignUid) => {
    try {
      this.setState({
        tableLoader: true,
      });
      this.getCampaignOperationsApi(campaignUid);
      const eventInfo = await getEventHistory({
        campaignUid,
      });
      this.setState({
        eventInfo: eventInfo ? eventInfo : this.state.eventInfo,
        tableLoader: false,
      });

      getCampaignAttachmentsApi(campaignUid, ATTACHMENT_FOLDER_MMS).then(
        (sampleMultimedia) => {
          this.setState({
            sampleMultimedia,
          });
        }
      );

      getCampaignTermsAndConditions().then((termsAndConditions) => {
        this.setState({
          termsAndConditions,
        });
      });
    } catch (e) {}
  };

  updateElectedDca = (campaignUid, provisional) => {
    listCampaignElectedDcaApi(campaignUid, { provisional }).then(
      (electedDcas) => {
        this.setState({
          electedDcas,
        });
      }
    );
  };

  handleUploadChange = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      this.setState({
        sampleMultimediaFile: null,
        uploadSizeExceededOpen: true,
      });
    } else {
      this.setState({
        sampleMultimediaFile: file,
      });
    }
  };

  handleMultimediaAdd = async (file) => {
    const { sampleMultimedia } = this.state;
    if (file && sampleMultimedia.length < 5) {
      try {
        const metadata = await uploadAttachmentForCampaignApi(
          this.props.match.params.id,
          ATTACHMENT_FOLDER_MMS,
          file
        );
        this.setState((prevState) => ({
          ...prevState,
          sampleMultimedia: [
            ...sampleMultimedia,
            {
              ...metadata,
              file,
            },
          ],
        }));
      } catch (e) {
        // TODO: error handling
      }
    }
  };

  handleMultimediaDelete = async (index) => {
    this.setState({
      sampleMultimediaDeleteIndex: index,
    });
  };

  handleMultimediaSelect = async (index) => {
    this.setState({
      sampleMultimediaIndex: index,
    });
    if (!this.state.sampleMultimedia[index].file) {
      const response = await downloadAttachmentApi(
        this.state.sampleMultimedia[index].uuid
      );
      const newSampleMultimedia = [...this.state.sampleMultimedia];
      newSampleMultimedia.splice(index, 1, {
        ...this.state.sampleMultimedia[index],
        file: new File(
          [response],
          this.state.sampleMultimedia[index].fileName,
          {
            type: this.state.sampleMultimedia[index].mimeType,
          }
        ),
      });
      this.setState({
        sampleMultimedia: newSampleMultimedia,
      });
    }
  };

  handleMultimediaDownload = async (index) => {
    await downloadAttachmentToDesktop(
      this.state.sampleMultimedia[index].uuid,
      this.state.sampleMultimedia[index].fileName
    );
  };

  handleMultimediaDeleteSubmit = async (index) => {
    const { sampleMultimedia } = this.state;
    if (sampleMultimedia.length > index) {
      const newSampleMultimedia = [...sampleMultimedia];
      newSampleMultimedia.splice(index, 1);
      this.setState((prevState) => ({
        ...prevState,
        sampleMultimedia: newSampleMultimedia,
      }));
      try {
        const media = sampleMultimedia[index];
        await deleteAttachmentApi(media.uuid);
      } catch (e) {
        // TODO: error handling
        this.setState((prevState) => ({
          ...prevState,
          sampleMultimedia,
        }));
      }
    }
  };

  handleOptinKeywordsChange = (keywords) => {
    this.setState({
      optinKeywords: keywords,
      optinChange: true,
    });
  };

  handleOptinMessageChange = (message) => {
    this.setState({
      optinMessage: message,
      optinChange: true,
    });
  };

  handleOptinMessageBlur = () => {
    if (this.state.optinChange) {
      this.setState({
        error: {
          ...this.state.error,
          optinMessage:
            errorCode.optinMessage[
              fieldValidation({
                ...errorCode.optinMessageObj,
                fieldval: this.state.optinMessage,
              })
            ],
        },
      });
    }
  };

  handleOptinPopupChange = (active) => {
    this.setState({
      optinPopup: active,
      optoutPopup: false,
      helpPopup: false,
      embeddedLinkPopup: false,
    });
  };

  handleOptinPopupClose = () => {
    const { campaign, optinChange, optinKeywords, optinMessage } = this.state;

    if (campaign.status !== 'EXPIRED' && optinChange) {
      const error =
        errorCode.optinMessage[
          fieldValidation({
            ...errorCode.optinMessageObj,
            fieldval: this.state.optinMessage,
          })
        ];
      if (error) {
        this.setState({
          error: {
            ...this.state.error,
            optinMessage: error,
          },
        });
        return;
      }

      this.setState({
        optinChange: false,
      });
      this.handleInlineUpdate({
        optinKeywords: optinKeywords.join(','),
        optinMessage,
      });
    }
    this.handleOptinPopupChange(false);
  };

  handleOptoutKeywordsChange = (keywords) => {
    this.setState({
      optoutKeywords: keywords,
      optoutChange: true,
    });
  };

  handleOptoutMessageChange = (message) => {
    this.setState({
      optoutMessage: message,
      optoutChange: true,
    });
  };

  handleOptoutMessageBlur = () => {
    if (this.state.optoutChange) {
      this.setState({
        error: {
          ...this.state.error,
          optoutMessage:
            errorCode.optoutMessage[
              fieldValidation({
                ...errorCode.optoutMessageObj,
                fieldval: this.state.optoutMessage,
              })
            ],
        },
      });
    }
  };

  handleOptoutPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: active,
      helpPopup: false,
      embeddedLinkPopup: false,
    });
  };

  handleOptoutPopupClose = () => {
    const { campaign, optoutChange, optoutKeywords, optoutMessage } =
      this.state;

    if (campaign.status !== 'EXPIRED' && optoutChange) {
      const error =
        errorCode.optoutMessage[
          fieldValidation({
            ...errorCode.optoutMessageObj,
            fieldval: this.state.optoutMessage,
          })
        ];
      if (error) {
        this.setState({
          error: {
            ...this.state.error,
            optoutMessage: error,
          },
        });
        return;
      }

      this.setState({
        optoutChange: false,
      });
      this.handleInlineUpdate({
        optoutKeywords: optoutKeywords.join(','),
        optoutMessage,
      });
    }
    this.handleOptoutPopupChange(false);
  };

  handleHelpKeywordsChange = (keywords) => {
    this.setState({
      helpKeywords: keywords,
      helpChange: true,
    });
  };

  handleHelpMessageChange = (message) => {
    this.setState({
      helpMessage: message,
      helpChange: true,
    });
  };

  handleHelpMessageBlur = () => {
    if (this.state.helpChange) {
      this.setState({
        error: {
          ...this.state.error,
          helpMessage:
            errorCode.helpMessage[
              fieldValidation({
                ...errorCode.helpMessageObj,
                fieldval: this.state.helpMessage,
              })
            ],
        },
      });
    }
  };

  handleHelpPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: false,
      helpPopup: active,
      embeddedLinkPopup: false,
    });
  };

  handleHelpPopupClose = () => {
    const { campaign, helpChange, helpKeywords, helpMessage } = this.state;

    if (campaign.status !== 'EXPIRED' && helpChange) {
      const error =
        errorCode.helpMessage[
          fieldValidation({
            ...errorCode.helpMessageObj,
            fieldval: this.state.helpMessage,
          })
        ];
      if (error) {
        this.setState({
          error: {
            ...this.state.error,
            helpMessage: error,
          },
        });
        return;
      }

      this.setState({
        helpChange: false,
      });
      this.handleInlineUpdate({
        helpKeywords: helpKeywords.join(','),
        helpMessage,
      });
    }
    this.handleHelpPopupChange(false);
  };

  handleEmbeddedLinkPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: false,
      helpPopup: false,
      embeddedLinkPopup: active,
    });
  };

  handleEmbeddedLinkSampleChange = (sample) => {
    this.setState({
      embeddedLinkSample: sample,
    });
  };

  handleEmbeddedLinkSampleBlur = () => {
    this.setState({
      error: {
        ...this.state.error,
        embeddedLinkSample:
          errorCode.embeddedLinkSample[
            fieldValidation({
              ...errorCode.embeddedLinkSampleObj,
              fieldval: this.state.embeddedLinkSample,
            })
          ],
      },
    });
  };

  handleEmbeddedLinkPopupClose = () => {
    const { embeddedLinkSample } = this.state;
    const error =
      errorCode.embeddedLinkSample[
        fieldValidation({
          ...errorCode.embeddedLinkSampleObj,
          fieldval: embeddedLinkSample,
        })
      ];
    if (error) {
      this.setState({
        error: {
          ...this.state.error,
          embeddedLinkSample: error,
        },
      });
    } else {
      this.handleInlineUpdate({
        embeddedLinkSample,
      });
      this.setState({
        embeddedLinkPopup: false,
      });
    }
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    this.props.history.push(`${goBackPage}`);
  };

  handleEditClick = (type) => {
    this.setState({
      openEditModal: true,
      editType: type,
    });
  };

  handleSubmitEdit = (data) => {
    const { editType, campaignUid } = this.state;
    if (editType === 'multimedia') {
      this.setState({
        openEditModal: false,
        sampleMultimedia: data.sampleMultimedia,
        sampleMultimediaIndex: -1,
      });
    } else {
      this.updateCampaignApi(campaignUid, data);
    }
  };

  handleInlineUpdate = async (data) => {
    const { campaign, campaignUid } = this.state;
    try {
      const response = await updateCampaign(campaignUid, data);
      this.setState({
        campaign: response,
      });
      toastFlashMessage('Campaign updated successfully', 'success');
    } catch {
      const defaultData = {};
      Object.keys(data).forEach((key) => {
        defaultData[key] = Array.isArray(this.state[key])
          ? campaign[key]
            ? campaign[key].split(',')
            : []
          : campaign[key];
      });
      this.setState(defaultData);
    }
  };

  handleDeactivateCampaign = () => {
    const { campaignUid } = this.state;
    this.deactivateCampaignApi(campaignUid);
  };

  handleCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  handleClickCnpAction = (flag, success = false) => {
    this.setState({
      electCnpFlag: success ? false : flag,
    });
    if (success) {
      const { campaignUid, provisional } = this.state;
      this.updateNominationStatus(campaignUid, provisional);
      this.updateElectedDca(campaignUid, provisional);
    }
  };

  handleChangePage = async (page) => {
    try {
      this.setState({
        tableLoader: true,
      });
      const eventInfo = await getEventHistory({
        campaignUid: this.deriveCampaignId(this.props),
        page,
      });
      this.setState({
        eventInfo,
        tableLoader: false,
      });
    } catch (e) {}
  };

  handleActionSelect = (value) => {
    if (value === REVIEW_ACTION_VALUE) {
      this.setState({
        reviewFlag: true,
      });
    } else if (value === APPEAL_REJECTION_ACTION_VALUE) {
      this.setState({
        appealRejectionFlag: true,
      });
    } else if (value === RESUBMIT_ACTION_VALUE) {
      this.setState({
        campaignResubmissionModalOpen: true,
      });
    } else if (value === DEACTIVATE_ACTION_VALUE) {
      this.setState({
        deactivateFlag: true,
      });
    }
  };

  handleReviewRequest = async (message) => {
    try {
      const response = await nudgeUpstreamCnp(
        this.deriveCampaignId(this.props),
        NUDGE_TYPES.REVIEW,
        message
      );
      toastFlashMessage('Nudge successfully sent.', 'success');
    } catch (e) {}
  };

  handleAppealRejectionRequest = async (message) => {
    try {
      const response = await nudgeUpstreamCnp(
        this.props.match.params.id,
        NUDGE_TYPES.APPEAL_REJECTION,
        message
      );
      toastFlashMessage('Notification successfully sent.', 'success');
    } catch (e) {}
  };

  handleCampaignResubmission = async () => {
    const campaignUid = this.deriveCampaignId(this.props);
    this.setState({
      resubmissionConfirmationDisabled: true,
    });
    try {
      await resubmitCampaign(campaignUid, this.state.selectedNewMnos);
      this.setState({
        resubmissionConfirmationModalOpen: false,
      });
      toastFlashMessage('Campaign resubmission successful', 'success');
      this.getCampaignOperationsApi(campaignUid);
    } catch (e) {}
    this.setState({
      resubmissionConfirmationDisabled: false,
    });
  };

  handleUpdateReseller = (resellerUid) => {
    const { campaignUid, campaign } = this.state;
    const data = { ...campaign, resellerUid };
    this.updateCampaignApi(campaignUid, data);
  };

  handleAttributeChange = async (value, key, label) => {
    const { campaign, campaignUid } = this.state;
    if (campaign[key] !== value) {
      try {
        this.setState({
          campaign: {
            ...campaign,
            [key]: value,
          },
        });
        await updateCampaign(campaignUid, { [key]: value });
        toastFlashMessage(`${label} attribute updated`, 'success');
      } catch (e) {
        this.setState({
          campaign,
        });
      }
    }
  };

  updateNominationStatus = (campaignUid, provisional) => {
    getNominationStatusApi(campaignUid, {
      provisional,
    }).then((data) => {
      this.setState({
        nominationInfo: data.nominationInfo,
        openAlert: !!data.openAlert,
      });
    });
  };

  updateGreyOutFields = async (campaign) => {
    this.setState({ greyOutFields: [] });
    const { brandUid, usecase } = campaign ?? {};
    if (brandUid && usecase) {
      const result = await getUseCaseGreyOutFields(brandUid, usecase);
      this.setState({
        greyOutFields: result,
      });
    }
  };

  isGreyOut = (field) => isFieldGreyOut(this.state.greyOutFields, field);

  handleMigration = async (value) => {
    const { campaignUid } = this.state;
    this.setState({ cnpMigrating: true });
    try {
      const result = await initiateCampaignMigrations(value, [campaignUid]);
      if (result) {
        toastFlashMessage('Success: Campaign migrated', 'success');
        const newProvisional = await this.updateProvisionalFlag(campaignUid);
        this.updateProvisionalDependentData(campaignUid, newProvisional);
      }
    } catch (e) {
      let errorMessage = 'Action failed. Cannot migrate campaign: ';
      Object.entries(e.details).forEach(([_key, errorDetail]) => {
        errorMessage += `${errorDetail.description}`;
      });
      toastFlashMessage(errorMessage, 'error');
    } finally {
      this.setState({ cnpMigrating: false });
    }
  };

  handleCancelMigration = async (explanation) => {
    const { campaignUid } = this.state;
    this.setState({ cnpMigrating: true });
    try {
      const result = await cancelCampaignMigrations([campaignUid], explanation);
      if (result) {
        toastFlashMessage('Success: Campaign migration canceled', 'success');
        const newProvisional = await this.updateProvisionalFlag(campaignUid);
        this.updateProvisionalDependentData(campaignUid, newProvisional);
      }
    } catch (e) {
      let errorMessage = 'Action failed. Cannot cancel campaign migration: ';
      typeof e.details === 'object' &&
        Object.entries(e.details).forEach(([_key, errorDetail]) => {
          errorMessage += `${errorDetail.description}`;
        });
      toastFlashMessage(errorMessage, 'error');
    } finally {
      this.setState({ cnpMigrating: false });
    }
  };

  render() {
    const {
      campaign,
      loader,
      mnoStatusInfo,
      goBackPage,
      resellerInfo,
      dcaInfo,
      openEditModal,
      editType,
      editLoader,
      deactivateFlag,
      renewalData,
      deactivateData,
      electedDcas,
      electCnpOptions,
      migrateCnpOptions,
      nominationInfo,
      openAlert,
      campaignUid,
      electCnpFlag,
      sampleMultimedia,
      sampleMultimediaIndex,
      sampleMultimediaFile,
      sampleMultimediaDeleteIndex,
      sampleMultimediaLoading,
      supportedFileFormats,
      uploadSizeExceededOpen,
      termsAndConditions,
      optinKeywords,
      optinMessage,
      optinPopup,
      optinChange,
      optoutKeywords,
      optoutMessage,
      optoutPopup,
      optoutChange,
      helpKeywords,
      helpMessage,
      helpPopup,
      helpChange,
      embeddedLinkPopup,
      embeddedLinkSample,
      eventInfo,
      tableLoader,
      error,
      cnpMigrating,
    } = this.state;
    const expiredCampaign = campaign.status === 'EXPIRED';

    return (
      <section className="campaign-detail-section" data-testid="campaignDetail">
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="campaign-detail-container">
            <Grid container className="top-blk">
              <Grid item xs={6} className="back-btn">
                <a
                  onClick={this.handleGoBack}
                  className="paragraph"
                  data-testid="campaignDetailBackButton"
                >
                  <Flex>
                    <Box sx={{ mr: 'xs' }}>
                      <FontAwesomeIcon icon={faAngleLeft} color="#68737A" />
                    </Box>
                    {goBackPage.startsWith(MY_CAMPAIGNS_PATH)
                      ? 'back to all campaigns'
                      : 'back'}
                  </Flex>
                </a>
              </Grid>
              <Grid item xs={6} className="button-wrapper">
                <RoleGuard feature="campaignDetail.viewMoreActions">
                  <p>
                    <MenuButton
                      style={{ float: 'right' }}
                      disabled={expiredCampaign}
                      options={MENU_OPTIONS}
                      onSelect={this.handleActionSelect}
                    />
                    <Tooltip
                      title={
                        <div>
                          <div>
                            <b>Review</b>: Use this to remind your CNP to review
                            the campaign and/or take action.
                          </div>
                          <br />
                          <div>
                            <b>Appeal Rejection</b>: Use this after you have
                            edited and resubmitted a campaign to appeal the
                            rejection by your CNP/DCA.
                          </div>
                          <br />
                          <div>
                            <b>Resubmit Campaign</b>: Use this to resubmit a
                            campaign to pick up the highest carrier terms or
                            vetting results. This does not trigger a review by
                            your CNP or DCA partner. Do not use this to resubmit
                            a campaign that has been rejected by your CNP or
                            DCA.
                          </div>
                          <br />
                          <div>
                            <b>Deactivate</b>: The Campaign will be immediately
                            deactivated on all MNOs.
                          </div>
                        </div>
                      }
                    />
                  </p>
                </RoleGuard>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="title" data-testid="campaignDetailCampaignId">
                  <h3 className="heading1">Campaign ID: {campaign.uid}</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CampaignDetailsBasic
                  campaign={campaign}
                  handleSubmitEdit={this.handleSubmitEdit}
                  loader={editLoader}
                  renewalData={renewalData}
                  migrationInfo={this.state.migrationInfo}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div
                  className="mno details"
                  data-testid="campaignDetailCarrierStatus"
                >
                  <HeadingBlock icon={faShareNodes} title="Carrier Status" />
                  <MnoStatusTableNew
                    mnoStatusInfo={mnoStatusInfo}
                    electedDcas={electedDcas}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <NumbersProvisioned campaignUid={campaign.uid} />
              </Grid>
              <Grid item xs={12}>
                <div
                  className="attributes details"
                  data-testid="campaignDetailCampaignAndContentAttributes"
                >
                  <HeadingBlock
                    icon={faSquarePollHorizontal}
                    title="Campaign Attributes"
                  />
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: 'l',
                      rowGap: 's',
                    }}
                  >
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type="view"
                          id="subscriberOptIn"
                          disabled={this.isGreyOut('subscriberOptin')}
                          value={campaign.subscriberOptin}
                          label="Subscriber Opt-in"
                          tooltip="Indicates whether the campaign is collecting and processing consumer opt-ins"
                          additionalSetup={
                            <MessageFlowAttributePopup
                              disabled={expiredCampaign}
                              defaultReadonly
                              showCharCounter
                              editable={authorized}
                              hideCloseButton
                              label="Opt-in"
                              keywordsTooltip="A keyword consumers can text to opt-in."
                              messageTooltip="If consumers can text in a keyword, the response should include the Brand name, confirmation of opt-in enrollment to a recurring message campaign, how to get help, and clear description of how to opt-out."
                              keywords={optinKeywords}
                              message={optinMessage}
                              error={error.optinMessage}
                              onKeywordsChange={this.handleOptinKeywordsChange}
                              onMessageChange={this.handleOptinMessageChange}
                              onMessageBlur={this.handleOptinMessageBlur}
                              onClose={this.handleOptinPopupClose}
                            />
                          }
                          additionalSetupActive={optinPopup}
                          setAdditionalSetupActive={this.handleOptinPopupChange}
                        />
                      )}
                    />
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type={expiredCampaign || !authorized ? 'view' : ''}
                          campaignInfo={campaign}
                          value={campaign.embeddedLink}
                          id="embeddedLink"
                          disabled={this.isGreyOut('embeddedLink')}
                          label="Embedded Link"
                          tooltip="Indicates whether the campaign is using an embedded link of any kind. Note that public URL shorteners (bitly, tinyurl) are not accepted"
                          handleChange={this.handleAttributeChange}
                          additionalSetup={
                            <EmbeddedLinkPopup
                              disabled={expiredCampaign}
                              editable={authorized}
                              defaultReadonly
                              hideCloseButton
                              error={error.embeddedLinkSample}
                              value={embeddedLinkSample}
                              onChange={this.handleEmbeddedLinkSampleChange}
                              onBlur={this.handleEmbeddedLinkSampleBlur}
                              onClose={this.handleEmbeddedLinkPopupClose}
                            />
                          }
                          additionalSetupActive={embeddedLinkPopup}
                          setAdditionalSetupActive={
                            this.handleEmbeddedLinkPopupChange
                          }
                        />
                      )}
                    />
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type="view"
                          value={campaign.subscriberOptout}
                          label="Subscriber Opt-Out"
                          id="subscriberOptOut"
                          disabled={this.isGreyOut('subscriberOptout')}
                          tooltip="Indicates whether the campaign is collecting and processing consumer opt-outs"
                          additionalSetup={
                            <MessageFlowAttributePopup
                              disabled={expiredCampaign}
                              defaultReadonly
                              messageRequired
                              showCharCounter
                              editable={authorized}
                              hideCloseButton
                              label="Opt-out"
                              keywordsTooltip="A keyword consumers can text to opt-out."
                              messageTooltip="The response to the STOP keyword may include the Brand name but should include an acknowledgement of opt-out request and confirmation that no further messages will be sent."
                              keywords={optoutKeywords}
                              message={optoutMessage}
                              error={error.optoutMessage}
                              onKeywordsChange={this.handleOptoutKeywordsChange}
                              onMessageChange={this.handleOptoutMessageChange}
                              onMessageBlur={this.handleOptoutMessageBlur}
                              onClose={this.handleOptoutPopupClose}
                            />
                          }
                          additionalSetupActive={optoutPopup}
                          setAdditionalSetupActive={
                            this.handleOptoutPopupChange
                          }
                        />
                      )}
                    />
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type={expiredCampaign || !authorized ? 'view' : ''}
                          campaignInfo={campaign}
                          value={campaign.embeddedPhone}
                          id="embeddedPhone"
                          disabled={this.isGreyOut('embeddedPhone')}
                          label="Embedded Phone Number"
                          tooltip="Indicates whether the campaign is using an embedded phone number (except the required HELP information contact phone number)"
                          handleChange={this.handleAttributeChange}
                        />
                      )}
                    />
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type="view"
                          value={campaign.subscriberHelp}
                          label="Subscriber Help"
                          id="subscriberHelp"
                          disabled={this.isGreyOut('subscriberHelp')}
                          tooltip="Indicates whether the campaign has implemented implemented message reply providing customers on how they can contact the message sender after they reply with the “HELP” keyword."
                          additionalSetup={
                            <MessageFlowAttributePopup
                              disabled={expiredCampaign}
                              defaultReadonly
                              messageRequired
                              showCharCounter
                              editable={authorized}
                              hideCloseButton
                              label="Help"
                              keywordsTooltip="A keyword consumers use to get help or more information."
                              messageTooltip="The response to HELP keyword may include the Brand name and additional support contact information."
                              keywords={helpKeywords}
                              message={helpMessage}
                              error={error.helpMessage}
                              onKeywordsChange={this.handleHelpKeywordsChange}
                              onMessageChange={this.handleHelpMessageChange}
                              onMessageBlur={this.handleHelpMessageBlur}
                              onClose={this.handleHelpPopupClose}
                            />
                          }
                          additionalSetupActive={helpPopup}
                          setAdditionalSetupActive={this.handleHelpPopupChange}
                        />
                      )}
                    />
                    <RoleGuard
                      feature="campaignDetail.editCampaignAttributes"
                      render={(authorized) => (
                        <CampaignAttributeNew
                          type={expiredCampaign || !authorized ? 'view' : ''}
                          campaignInfo={campaign}
                          value={campaign.ageGated}
                          id="ageGated"
                          disabled={this.isGreyOut('ageGated')}
                          label="Age-Gated Content"
                          tooltip="Indicates whether the campaign include any age-gated content as defined by Carrier and CTIA guidelines"
                          handleChange={this.handleAttributeChange}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: 'l',
                      mt: 's',
                    }}
                  >
                    <Flex sx={{ flexDirection: 'column', gap: 's' }}>
                      <RoleGuard
                        feature="campaignDetail.editCampaignAttributes"
                        render={(authorized) => (
                          <CampaignAttributeNew
                            type={expiredCampaign || !authorized ? 'view' : ''}
                            campaignInfo={campaign}
                            value={campaign.numberPool}
                            id="numberPool"
                            disabled={this.isGreyOut('numberPool')}
                            label="Number Pooling"
                            tooltip="Indicates whether more than 50 numbers will be used for the campaign."
                            handleChange={this.handleAttributeChange}
                          />
                        )}
                      />
                      <RoleGuard
                        feature="campaignDetail.editCampaignAttributes"
                        render={(authorized) => (
                          <CampaignAttributeNew
                            type={expiredCampaign || !authorized ? 'view' : ''}
                            campaignInfo={campaign}
                            value={campaign.directLending}
                            id="directLending"
                            disabled={this.isGreyOut('directLending')}
                            label="Direct Lending or Loan Arrangement"
                            tooltip="Indicates whether the campaign include content related to direct lending or other loan arrangements"
                            handleChange={this.handleAttributeChange}
                          />
                        )}
                      />
                    </Flex>
                    <CampaignAttributeNew
                      type="view"
                      label="Terms & Conditions"
                      id="termsAndConditions"
                      disabled={this.isGreyOut('termsAndConditions')}
                      tooltip="Indicates the campaign follows CTIA messaging principles and best practices"
                      notes={termsAndConditions}
                      value={campaign.termsAndConditions}
                    />
                  </Box>
                </div>
              </Grid>
              <MaskedContainer
                masked={[
                  this.isGreyOut('description'),
                  this.isGreyOut('privacyPolicyLink'),
                  this.isGreyOut('termsAndConditionsLink'),
                  this.isGreyOut('sampleMessage'),
                ].every((v) => v)}
              >
                <CampaignDetailsSection
                  disabled={expiredCampaign}
                  campaign={campaign}
                  onChange={(data) => {
                    this.setState({
                      campaign: {
                        ...campaign,
                        ...data,
                      },
                    });
                  }}
                  greyOutFields={this.state.greyOutFields}
                />
              </MaskedContainer>
              <MaskedContainer
                masked={this.isGreyOut('supportingDocumentUuid')}
              >
                <SupportingDocumentSection
                  readonly={expiredCampaign}
                  campaignUid={this.props.match.params.id}
                />
              </MaskedContainer>
              <Grid item xs={12}>
                <MaskedContainer
                  className="sample-messages details"
                  masked={this.isGreyOut('sampleMessage')}
                >
                  <HeadingBlock
                    data-testid="campaignDetailSampleMessage"
                    icon={faMessageDots}
                    title="Sample Messages"
                  >
                    <RoleGuard feature="campaignDetail.editSampleMessages">
                      <Flex
                        data-testid="campaignDetailSampleMessageEditButton"
                        className={
                          expiredCampaign ? 'edit-btn disabled' : 'edit-btn'
                        }
                        onClick={() => this.handleEditClick('messages')}
                      >
                        <Box sx={{ mr: 'xs' }}>
                          <FontAwesomeIcon icon={faPen} color="#0091B3" />
                        </Box>
                        <span>Edit</span>
                      </Flex>
                    </RoleGuard>
                  </HeadingBlock>
                  {Object.keys(campaign).map((k, index) => {
                    if (k.includes('sample')) {
                      return (
                        campaign[k] && (
                          <CampaignSampleMessage
                            key={index}
                            index={k.slice(-1)}
                            message={campaign[k]}
                          />
                        )
                      );
                    }
                  })}
                </MaskedContainer>
              </Grid>
              <Grid item xs={12}>
                <MaskedContainer
                  className="sample-messages details"
                  data-testid="campaignDetailSampleMultimedia"
                  masked={this.isGreyOut('mmsMediaUuid')}
                >
                  <HeadingBlock
                    icon={faFileImage}
                    title="Sample Multimedia"
                    style={{
                      borderBottom: 'none',
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: '8px',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16px',
                      }}
                    >
                      Provides an area to upload sample MMS content. Not
                      intended for call-to-action, terms and conditions, or
                      privacy policy information.
                    </Box>
                  </HeadingBlock>
                  <Grid container spacing={4}>
                    {!expiredCampaign && (
                      <RoleGuard feature="campaignDetail.addSampleMultimedia">
                        <Grid
                          item
                          xs={6}
                          data-testid="campaignDetailSampleMultimediaUploader"
                        >
                          <MultimediaUploader
                            disabled={
                              sampleMultimedia.length >=
                              MAX_SAMPLE_MULTIMEDIA_COUNT
                            }
                            disabledText={`Max ${MAX_SAMPLE_MULTIMEDIA_COUNT} uploads per campaign is allowed`}
                            supportedMimeTypes={supportedFileFormats}
                            onChange={this.handleUploadChange}
                          />
                        </Grid>
                      </RoleGuard>
                    )}
                    <Grid item xs={6} style={{ minHeight: '307px' }}>
                      <RoleGuard
                        feature="campaignDetail.deleteSampleMultimedia"
                        render={(authorized) => (
                          <MultimediaList
                            data-testid="campaignDetailSampleMultimediaFiles"
                            title="Sample Multimedia Files"
                            editable={authorized && !expiredCampaign}
                            loading={
                              sampleMultimediaIndex > -1 &&
                              !sampleMultimedia[sampleMultimediaIndex].file
                            }
                            attachments={sampleMultimedia}
                            onSelect={this.handleMultimediaSelect}
                            onDownload={this.handleMultimediaDownload}
                            onDelete={this.handleMultimediaDelete}
                          />
                        )}
                      />
                    </Grid>
                    {sampleMultimedia.length < MAX_SAMPLE_MULTIMEDIA_COUNT &&
                      !expiredCampaign && (
                        <RoleGuard feature="campaignDetail.addSampleMultimedia">
                          <Grid
                            item
                            xs={12}
                            className="flex-centered"
                            style={{ justifyContent: 'center' }}
                          >
                            <Button
                              data-testid="campaignDetailSampleMultimediaFilesAddButton"
                              classes={{
                                root: this.props.classes.button,
                              }}
                              disabled={
                                !sampleMultimediaFile || sampleMultimediaLoading
                              }
                              variant="standard"
                              shape="square"
                              color="primary"
                              size="small"
                              onClick={async () => {
                                if (sampleMultimediaFile) {
                                  this.setState({
                                    sampleMultimediaLoading: true,
                                  });
                                  await this.handleMultimediaAdd(
                                    sampleMultimediaFile
                                  );
                                  this.setState({
                                    sampleMultimediaFile: null,
                                    sampleMultimediaLoading: false,
                                  });
                                }
                              }}
                            >
                              {sampleMultimediaLoading
                                ? 'Processing'
                                : '+ Add Sample Multimedia'}
                            </Button>
                          </Grid>
                        </RoleGuard>
                      )}
                    {sampleMultimediaIndex > -1 &&
                      !!sampleMultimedia[sampleMultimediaIndex].file && (
                        <AttachmentPreview
                          file={sampleMultimedia[sampleMultimediaIndex].file}
                          mimeType={
                            sampleMultimedia[sampleMultimediaIndex].mimeType
                          }
                          onClose={() =>
                            this.setState({ sampleMultimediaIndex: -1 })
                          }
                          data-testid="campaignDetailSampleMultimediaBackdrop"
                        />
                      )}
                  </Grid>
                </MaskedContainer>
              </Grid>
              <Grid item xs={12}>
                <div className="other-responsible-parties details">
                  <HeadingBlock
                    icon={faArrowProgress}
                    title="Other Responsible Parties"
                  />
                  <CNPDetails
                    campaign={campaign}
                    nominationInfo={nominationInfo}
                    electedDcas={electedDcas}
                    handleClickCnpAction={this.handleClickCnpAction}
                    cnpOptions={migrateCnpOptions}
                    isMigrating={cnpMigrating}
                    handleMigration={this.handleMigration}
                    handleCancelMigration={this.handleCancelMigration}
                    {...this.props}
                  />
                  <ReSellerDetails
                    campaignStatus={campaign.status}
                    resellerInfo={resellerInfo}
                    loader={editLoader}
                    handleAddReseller={this.handleUpdateReseller}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div
                  className="campaign-events details"
                  data-testid="campaignDetailEvents"
                >
                  <div className="details-heading">
                    <Flex>
                      <Box sx={{ mr: 'xs' }}>
                        <FontAwesomeIcon icon={faCalendarRange} size="xl" />
                      </Box>
                      <h3 className="heading1">Events</h3>
                    </Flex>
                  </div>
                  <Grid container>
                    <Table
                      testId="campaignDetailEventsTable"
                      className="campaign-detail-events-table"
                      headRows={headRows}
                      emptyState="no events to view"
                      disableHover
                      loading={tableLoader}
                      tableData={eventInfo.records.map((record, index) => {
                        return <EventsListingRow key={index} event={record} />;
                      })}
                      handleChangePage={this.handleChangePage}
                      pagination={{
                        rowsPerPage: eventInfo.recordsPerPage,
                        page: eventInfo.page,
                        totalRecords: eventInfo.totalRecords,
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <CampaignEditModal
              open={openEditModal}
              handleClose={() => this.setState({ openEditModal: false })}
              type={editType}
              campaignInfo={campaign}
              sampleMultimedia={this.state.sampleMultimedia}
              loader={editLoader}
              handleSubmitEdit={this.handleSubmitEdit}
            />
            <UploadSizeExceededModal
              open={uploadSizeExceededOpen}
              onClose={() => {
                this.setState({
                  uploadSizeExceededOpen: false,
                });
              }}
            />
            <DeleteSampleMultimediaModal
              open={!!sampleMultimedia[sampleMultimediaDeleteIndex]}
              fileName={
                sampleMultimedia[sampleMultimediaDeleteIndex]
                  ? sampleMultimedia[sampleMultimediaDeleteIndex].fileName
                  : ''
              }
              onCancel={() => {
                this.setState({
                  sampleMultimediaDeleteIndex: -1,
                });
              }}
              onSubmit={async () => {
                this.setState({
                  sampleMultimediaDeleteIndex: -1,
                });
                this.handleMultimediaDeleteSubmit(sampleMultimediaDeleteIndex);
              }}
            />
            <ConfirmationDialog
              open={deactivateFlag}
              type="deactivate"
              handleCancel={() => this.setState({ deactivateFlag: false })}
              handleSuccess={this.handleDeactivateCampaign}
              loader={editLoader}
              text={deactivateData ? deactivateData.text : ''}
            />
            <ElectCNPAlert
              handleClose={this.handleCloseAlert}
              open={openAlert}
            />
            <RequestReviewModal
              open={this.state.reviewFlag}
              onClose={() => this.setState({ reviewFlag: false })}
              onSend={this.handleReviewRequest}
            />
            <RequestAppealRejectionModal
              open={this.state.appealRejectionFlag}
              onClose={() => this.setState({ appealRejectionFlag: false })}
              onSend={this.handleAppealRejectionRequest}
            />
            <CampaignResubmissionModal
              open={this.state.campaignResubmissionModalOpen}
              mnoStatus={this.state.mnoStatusInfo}
              newMnoTerms={this.state.newMnoTerms}
              onClose={() =>
                this.setState({ campaignResubmissionModalOpen: false })
              }
              onSubmit={(mnos) => {
                this.setState({
                  campaignResubmissionModalOpen: false,
                  resubmissionConfirmationModalOpen: true,
                  selectedNewMnos: mnos,
                });
              }}
            />
            <ResubmissionConfirmationModal
              open={this.state.resubmissionConfirmationModalOpen}
              disabled={this.state.resubmissionConfirmationDisabled}
              onClose={() =>
                this.setState({ resubmissionConfirmationModalOpen: false })
              }
              onSubmit={() => this.handleCampaignResubmission()}
            />
          </Container>
        )}
        <ElectCNPDialog
          open={electCnpFlag}
          cnpOptions={electCnpOptions}
          handleClose={(flag) =>
            this.handleClickCnpAction(flag, flag ? true : false)
          }
          campaignUid={campaignUid}
          usecase={campaign.usecase}
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(
  withStyles(useStyles)(withPermission(CampaignDetail))
);
