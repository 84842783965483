import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { BoxV2 as Box, Flex, RoleGuard, Tooltip } from 'portal-commons';
import { BrandAsset, BrandAssetExtended, BrandAssetStatus } from '../../types';
import { capitalizeFirstLetter } from '../../utils';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import MoreButton from '../../../../../shared_elements/components/MoreButton';

type Props = {
  data: BrandAssetExtended;
  loading?: boolean;
  onPreview: (data: BrandAssetExtended) => void;
  onVerify: (data: BrandAssetExtended) => void;
  onDelete: (data: BrandAssetExtended) => void;
  onAppeal: (data: BrandAssetExtended) => void;
};
const BrandAssetsListingRow: React.FC<Props> = ({
  data,
  loading,
  onPreview,
  onVerify,
  onDelete,
  onAppeal,
}) => {
  const showAppeal = data.verificationStatus === BrandAssetStatus.Unverified;
  const showVerify =
    !data.verificationStatus ||
    data.verificationStatus === BrandAssetStatus.Expired;
  const showDelete = showAppeal || showVerify;

  return (
    <>
      <TableRow data-testid="brandAssetsListingRow">
        <TableCell
          align="left"
          style={{ maxWidth: 220 }}
          data-testid="brandAssetsListingRowName"
        >
          <Flex
            sx={{
              position: 'relative',
              fontSize: 'H200',
              fontWeight: 600,
              lineHeight: 'normal',
              color: 't.blue100',
              cursor: 'pointer',
              alignItems: 'center',
              maxWidth: 'fit-content',
              gap: 5,
            }}
            onClick={() => onPreview(data)}
          >
            {data.name}
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                style={{ color: '#68737A', position: 'absolute', right: -20 }}
                spin
              />
            )}
          </Flex>
        </TableCell>
        <TableCell align="left" data-testid="brandAssetsListingRowType">
          {capitalizeFirstLetter(data.type)}
        </TableCell>
        <TableCell align="left" data-testid="brandAssetsListingRowStatus">
          <VettingStatus
            status={data?.verificationStatus}
            reasons={data?.reasons}
          />
        </TableCell>
        <TableCell align="left" data-testid="brandAssetsListingRowCreateDate">
          {data?.createDate ? convertTimeWithTimezone(data.createDate) : 'N/A'}
        </TableCell>
        <TableCell align="left" data-testid="brandAssetsListingRowExpireDate">
          {data?.expireDate ? convertTimeWithTimezone(data.expireDate) : 'N/A'}
        </TableCell>
        <RoleGuard feature="brandDetail.handleBrandAssets">
          <TableCell
            align="left"
            data-testid="brandAssetsListingRowAction"
            style={{ width: '5%' }}
          >
            <MoreButton<BrandAsset>
              data={data}
              menuClass="tcr-menu"
              data-testid="brandAssetsListingRowMoreButton"
              items={[
                {
                  label: 'Verify',
                  onClick: () => {
                    onVerify(data);
                  },
                  hidden: !showVerify,
                  'data-testid': 'brandAssetsListingRowMoreMenuItemVerify',
                },
                {
                  label: 'Appeal Result',
                  onClick: () => {
                    onAppeal(data);
                  },
                  hidden: !showAppeal,
                  'data-testid':
                    'brandAssetsListingRowMoreMenuItemAppealResult',
                },
                {
                  label: 'Delete',
                  disabled: !showDelete,
                  onClick: () => {
                    onDelete(data);
                  },
                  'data-testid': 'brandAssetsListingRowMoreMenuItemDelete',
                },
              ]}
            />
          </TableCell>
        </RoleGuard>
      </TableRow>
    </>
  );
};

const VettingStatus: React.FC<{
  status?: BrandAssetStatus | null;
  reasons?: string;
}> = ({ status, reasons }) => {
  const basicStyle = {
    fontSize: 'H200',
    fontWeight: 600,
    lineHeight: 'normal',
  };

  if (!status) return <Box sx={{ ...basicStyle }}>N/A</Box>;
  if (status === BrandAssetStatus.Pending) {
    return (
      <Flex sx={{ alignItems: 'center', gap: 7, ...basicStyle }}>
        <FontAwesomeIcon icon={faHourglass} />
        <span>{status.toLocaleUpperCase()}</span>
      </Flex>
    );
  }
  if (status === BrandAssetStatus.Unverified) {
    return (
      <Flex
        sx={{
          alignItems: 'center',
          gap: 5,
          ...basicStyle,
        }}
      >
        <span>{status.toLocaleUpperCase()}</span>
        {reasons && reasons.length > 0 && (
          <Tooltip
            title={<Box sx={{ whiteSpace: 'pre-wrap' }}>{reasons ?? ''}</Box>}
            style={{
              position: 'unset',
              top: 0,
              right: 0,
              marginTop: '-3px',
              fontSize: '12px',
            }}
          />
        )}
      </Flex>
    );
  }
  return <Box sx={{ ...basicStyle }}>{status.toLocaleUpperCase()}</Box>;
};

export default BrandAssetsListingRow;
