import { BoxV2 as Box, Button, Flex, TextInput } from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import { faRectangleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../../../../components';
import { isValidInput } from '../../../../../utils';

interface BulkDeclineModalProps {
  open?: boolean;
  disabled?: boolean;
  campaignUids: string[];
  onClose?: () => void;
  onSubmit?: (value: string) => void;
}

const EXPLANATION_MAX_LENGTH = 2048;

const BulkDeclineModal: FunctionComponent<BulkDeclineModalProps> = ({
  open,
  disabled,
  campaignUids,
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setValue('');
      setError('');
    }
  }, [open]);

  const handleChange = (value: string) => {
    setValue(value);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    if (!value) {
      setError('Enter explanation');
      return;
    }

    if (value.length > EXPLANATION_MAX_LENGTH) {
      setError(`Maximum ${EXPLANATION_MAX_LENGTH} chars are allowed`);
      return;
    }

    if (!isValidInput(value)) {
      setError('Invalid input');
      return;
    }

    onSubmit?.(value);
  };

  return (
    <Modal testId="bulkDeclineModal" open={open}>
      <Flex
        sx={{
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faRectangleXmark} />
        <Box as="span">Decline Campaigns</Box>
      </Flex>
      <Box
        sx={{
          width: '100%',
          paddingBottom: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
        }}
      >
        Please confirm that you are declining{' '}
        <Box as="span" sx={{ fontWeight: 800 }}>
          {campaignUids.length} campaign(s)
        </Box>
        .
        <br />
        <br />
        Campaign ID(s):
        <br />
        <Box as="span" sx={{ fontWeight: 600 }}>
          {campaignUids.join(', ')}
        </Box>
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '12px',
          width: '100%',
          color: '#333E41',
        }}
      >
        <Box as="span" sx={{ fontSize: '10px', lineHeight: '12px' }}>
          *Required Field
        </Box>
        <Box sx={{ width: '100%' }}>
          <TextInput
            required
            maxLength={EXPLANATION_MAX_LENGTH}
            disabled={disabled}
            label="Explanation"
            error={!!error}
            note={error}
            value={value}
            onChange={handleChange}
          />
        </Box>
      </Flex>
      <Flex
        sx={{
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={disabled} onClick={handleApply}>
          Decline
        </Button>
      </Flex>
    </Modal>
  );
};

export default BulkDeclineModal;
