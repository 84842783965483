import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import KeywordsInput from './KeywordsInput';
import MessageInput from './MessageInput';
import { isValidInput } from '../../../../utils';

import { Box as BoxCommons, Tooltip } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    right: 0,
    left: 0,
    background: '#FFFFFF',
    boxShadow: '0 2px 4px rgba(104, 115, 122, 0.2)',
    zIndex: 5,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    background: 'rgba(0, 145, 179, 0.05)',
    borderTop: '1px solid rgba(0, 145, 179, 0.2)',
    boxSizing: 'border-box',
  },
  icons: {
    position: 'absolute',
    top: 4,
    right: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 8,
    objectFit: 'cover',
    cursor: 'pointer',
    '&.disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
  viewer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px 8px 8px 10px',
    background: '#FFFFFF',
  },
  keywordsView: {
    position: 'relative',
    '& > img': {
      width: '6px !important',
      top: 'auto !important',
      right: '-17px !important',
      bottom: '2px !important',
    },
  },
  messageView: {
    position: 'relative',
    '& > img': {
      width: '6px !important',
      top: 'auto !important',
      right: '-17px !important',
      bottom: '2px !important',
    },
  },
  setup: {
    '& > *:not(:first-child)': {
      marginTop: 15,
    },
  },
  keywordsInput: {
    position: 'relative',
    '& > img': {
      top: 'auto !important',
      bottom: '4px !important',
    },
  },
  messageInput: {
    position: 'relative',
    '& > img': {
      top: 'auto !important',
      bottom: '4px !important',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 25,
    marginTop: 20,
    fontSize: 12,
    lineHeight: 14,
    color: '#0091B3',
    background: 'transparent',
    border: '1px solid #0091B3',
    borderRadius: 200,
    cursor: 'pointer',
  },
});

const MessageFlowAttributePopup = ({
  disabled,
  defaultReadonly,
  editable,
  hideCloseButton,
  label,
  keywordsTooltip,
  messageTooltip,
  error,
  keywords,
  message,
  onKeywordsChange,
  onMessageChange,
  onClose,
  messageRequired,
  onMessageBlur,
  showCharCounter,
}) => {
  const [readonly, setReadonly] = useState(!!defaultReadonly);
  const [editError, setEditError] = useState('');
  const classes = useStyles();

  const handleEdit = () => {
    setReadonly(false);
  };

  const handleClose = () => {
    setReadonly(disabled || !error);
    onClose && onClose();
  };

  const handleMessageChange = (value) => {
    onMessageChange && onMessageChange(value);
    setEditError('');
  };

  useEffect(() => {
    if (disabled) {
      setReadonly(true);
    }
  }, [disabled]);

  return (
    <Box className={classes.root} data-testid="messageFlowAttirbutePopup">
      <Box className={classes.container}>
        <Box className={classes.icons}>
          {editable !== false && readonly && (
            <BoxCommons float="left" margin={{ right: 'xs' }}>
              <FontAwesomeIcon
                icon={faPen}
                color="#0091B3"
                onClick={handleEdit}
                size="xs"
                data-testid="messageFlowAttirbutePopupEditButton"
                style={
                  disabled
                    ? {
                        opacity: 0.5,
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                      }
                    : {
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }
                }
              />
            </BoxCommons>
          )}
          {!hideCloseButton && (
            <FontAwesomeIcon
              icon={faXmark}
              color="#0091B3"
              onClick={handleClose}
              size="xs"
              data-testid="messageFlowAttirbutePopupCloseButton"
              style={{
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          )}
        </Box>

        {readonly ? (
          <div
            className={classes.viewer}
            style={{ border: error || editError ? '1px solid red' : 'none' }}
          >
            <div
              className={classes.keywordsView}
              data-testid="messageFlowAttirbutePopupKeywrods"
            >
              <Grid container>
                <Grid item xs={4}>
                  {label ? `${label} ` : ''}Keywords:
                </Grid>
                <Grid item xs={8} style={{ wordBreak: 'break-all' }}>
                  {keywords && keywords.join(', ')}
                </Grid>
              </Grid>
              <Tooltip title={keywordsTooltip} style={{ top: 2 }} />
            </div>
            <div
              className={classes.messageView}
              data-testid="messageFlowAttirbutePopupMessage"
            >
              <Grid style={{ marginTop: 20 }} container>
                <Grid item xs={4}>
                  {label ? `${label} ` : ''}Message:
                </Grid>
                <Grid style={{ wordBreak: 'break-all' }} item xs={8}>
                  {message}
                </Grid>
              </Grid>
              <Tooltip title={messageTooltip} style={{ top: 20 }} />
            </div>
            {(error || editError) && (
              <h6
                className="error-msg"
                style={{ position: 'absolute', bottom: -16 }}
              >
                {error || editError}
              </h6>
            )}
          </div>
        ) : (
          <Grid classes={{ root: classes.setup }} container direction="column">
            <div
              className={classes.keywordsInput}
              data-testid="messageFlowAttirbutePopupKeywordsInput"
            >
              <KeywordsInput
                placeholder={`Type${label ? ` ${label}` : ''} Keywords here`}
                value={keywords}
                onValueChange={onKeywordsChange}
              />
              <Tooltip title={keywordsTooltip} />
            </div>
            <div
              className={classes.messageInput}
              data-testid="messageFlowAttirbutePopupMessageInput"
            >
              <MessageInput
                required={messageRequired}
                placeholder={`Type${label ? ` ${label}` : ''} Message here`}
                error={error || editError}
                value={message}
                onValueChange={handleMessageChange}
                onBlur={disabled ? undefined : onMessageBlur}
                showCharCounter={showCharCounter}
              />
              <Tooltip title={messageTooltip} />
            </div>
          </Grid>
        )}

        {!readonly && (
          <button
            className={classes.button}
            onClick={handleClose}
            data-testid="messageFlowAttirbutePopupDoneButton"
          >
            Done
          </button>
        )}
      </Box>
    </Box>
  );
};

MessageFlowAttributePopup.propTypes = {
  disabled: PropTypes.bool,
  defaultReadonly: PropTypes.bool,
  editable: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  label: PropTypes.string,
  keywordsTooltip: PropTypes.string,
  messageTooltip: PropTypes.string,
  error: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string,
  onKeywordsChange: PropTypes.func,
  onMessageChange: PropTypes.func,
  onMessageBlur: PropTypes.func,
  onClose: PropTypes.func,
  messageRequired: PropTypes.bool,
};

export default MessageFlowAttributePopup;
