export enum AttachmentFolder {
  SampleMultimedia = 'MMS_SAMPLE_MEDIA',
  SupportingDocument = 'SUPPORTING_DOCUMENT',
  VettingAppeal = 'VET_APPEAL',
}

export enum BulkCampaignAction {
  Elect = 'elect',
  Decline = 'decline',
  InitiateMigration = 'initiateMigration',
  CancelMigration = 'cancelMigration',
}
