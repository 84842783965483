import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
} from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../../../../components';
import { SelectItem } from '../../../../../utils/types';

interface BulkElectModalProps {
  open?: boolean;
  disabled?: boolean;
  campaignUids: string[];
  options?: SelectItem[];
  onClose?: () => void;
  onSubmit?: (value: string) => void;
}

const BulkElectModal: FunctionComponent<BulkElectModalProps> = ({
  open,
  disabled,
  campaignUids,
  options = [],
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setValue('');
      setError('');
    }
  }, [open]);

  const handleChange = (value: string) => {
    setValue(value);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    if (!value) {
      setError('Select a connectivity partner');
      return;
    }

    onSubmit?.(value);
  };

  return (
    <Modal testId="bulkElectModal" open={open}>
      <Flex
        sx={{
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faBullhorn} />
        <Box as="span">Elect Connectivity Partner</Box>
      </Flex>
      <Box
        sx={{
          paddingBottom: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
        }}
      >
        You have selected{' '}
        <Box as="span" sx={{ fontWeight: 700 }}>
          {campaignUids.length} campaign(s)
        </Box>
        . Please identify the partner you will be forwarding the messaging
        campaign to.{' '}
        <Box as="span" sx={{ fontWeight: 800 }}>
          By making a selection you are automatically accepting the campaign
          that has been shared with you.
        </Box>{' '}
        Failing to elect a CNP may hinder the campaign from reaching the MNO.
        <br />
        <br />
        <Box as="span" sx={{ fontWeight: 700 }}>
          Note:
        </Box>{' '}
        If Sole Proprietor campaigns are included in the selection, the CNP list
        will only show connectivity partners capable of managing Sole Proprietor
        campaigns.
        <br />
        <br />
        Campaign ID(s):
        <br />
        <Box as="span" sx={{ fontWeight: 600 }}>
          {campaignUids.join(', ')}
        </Box>
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '12px',
          width: '100%',
          color: '#333E41',
        }}
      >
        <Box as="span" sx={{ fontSize: '10px', lineHeight: '12px' }}>
          *Required Field
        </Box>
        <Dropdown
          required
          disabled={disabled}
          label="Select Connectivity Partner"
          error={!!error}
          helperText={error}
          options={options}
          value={value}
          onChange={handleChange}
        />
      </Flex>
      <Flex
        sx={{
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={disabled} onClick={handleApply}>
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};

export default BulkElectModal;
