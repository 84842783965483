import { LogoUpload, BannerUpload } from './Brands/components';
import BrandAssets from './Brands/containers/BrandAssets';
import BrandDetails from './Brands/containers/BrandDetails';
import BrandList from './Brands/containers/BrandList';
import BrandRegisterForm from './Brands/containers/BrandRegisterForm';
import AcceptedCnpCampaigns from './Campaigns/containers/AcceptedCnpCampaigns';
import CampaignDetail from './Campaigns/containers/CampaignDetail';
import CampaignsListing from './Campaigns/containers/CampaignsListing';
import CampaignRegisterForm from './Campaigns/containers/CampaignRegisterForm';
import CampaignRegisterSuccess from './Campaigns/containers/CampaignRegisterSuccess';
import MyCampaigns from './Campaigns/containers/MyCampaigns';
import PartnerCampaigns from './Campaigns/containers/PartnerCampaigns';
import PartnerCampaignDetail from './Campaigns/containers/PartnerCampaignDetail';
import PendingCnpCampaigns from './Campaigns/containers/PendingCnpCampaigns';
import CspVerificationForm from './CSP-Form/containers/CspVerificationForm';
import CspVerificationStatus from './CSP-Form/containers/CspVerificationStatus';
import InitiateCspAccount from './CSP-Form/containers/InitiateCspAccount';
import DashboardNew from './Dashboard/containers/DashboardNew';
import EventsList from './Events/containers/EventsList';
import Integrations from './Integrations/containers/Integrations';
import KnowledgeBaseNew from './Knowledge Base/containers/KnowledgeBase';
import ProfileManagement from './Profile/containers/ProfileManagement';
import Resellers from './Resellers/containers/Resellers';
import { Role, RouteConfig } from '../../types';
import {
  CNP_CAMPAIGN_DETAILS_PATH,
  CNP_CAMPAIGNS_HISTORY_PATH,
  CNP_CAMPAIGNS_PATH,
  MY_CAMPAIGN_DETAILS_PATH,
  MY_CAMPAIGNS_PATH,
} from '../../constants/paths';
import { BRAND_ASSETS_ENABLED, CNP_MIGRATION_ENABLED } from '../../constants';

const AllRoles = Object.values(Role);

const routes: RouteConfig[] = [
  {
    key: 'csp-account',
    path: '/user/csp',
    title: 'Welcome to The Campaign Registry',
    component: InitiateCspAccount,
    roles: AllRoles,
  },
  {
    key: 'csp-form',
    path: '/user/csp/create',
    title: 'CSP Verification',
    component: CspVerificationForm,
    roles: AllRoles,
  },
  {
    key: 'csp-status',
    path: '/user/csp/status',
    title: 'Approval Status',
    component: CspVerificationStatus,
    roles: AllRoles,
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    title: 'Dashboard',
    component: DashboardNew,
    roles: AllRoles,
  },
  {
    key: 'profile',
    path: '/profile',
    title: 'CSP Profile',
    component: ProfileManagement,
    roles: AllRoles,
  },
  {
    key: 'brand-create',
    path: '/brand/create',
    title: 'Register a Brand',
    component: BrandRegisterForm,
    roles: [Role.User, Role.Manager],
  },
  {
    key: 'brand-details',
    path: '/brands/:id',
    title: 'Brands',
    component: BrandDetails,
    roles: AllRoles,
  },
  {
    key: 'brands-list',
    path: '/brands',
    title: 'Brands',
    component: BrandList,
    roles: AllRoles,
  },
  {
    key: 'campaign-create-success',
    path: '/campaign/create/successfully',
    title: 'Register a Campaign',
    component: CampaignRegisterSuccess,
    roles: [Role.User, Role.Manager],
  },
  {
    key: 'campaign-create-class',
    path: '/campaign/create',
    title: 'Register a Campaign',
    component: CampaignRegisterForm,
    roles: [Role.User, Role.Manager],
  },
  {
    key: 'campaign-details',
    path: MY_CAMPAIGN_DETAILS_PATH,
    title: 'Campaigns',
    component: CampaignDetail,
    roles: AllRoles,
  },
  {
    key: 'campaigns-list',
    path: MY_CAMPAIGNS_PATH,
    title: 'My Campaigns',
    component: CNP_MIGRATION_ENABLED ? MyCampaigns : CampaignsListing,
    roles: AllRoles,
  },
  {
    key: 'cnp-campaigns-history-list',
    path: CNP_CAMPAIGNS_HISTORY_PATH,
    title: 'History of Campaigns by Partners',
    component: CNP_MIGRATION_ENABLED ? AcceptedCnpCampaigns : PartnerCampaigns,
    roles: AllRoles,
  },
  {
    key: 'cnp-campaign-details',
    path: CNP_CAMPAIGN_DETAILS_PATH,
    title: 'Campaigns',
    component: PartnerCampaignDetail,
    roles: AllRoles,
  },
  {
    key: 'cnp-campaigns-list',
    path: CNP_CAMPAIGNS_PATH,
    title: 'CNP Campaigns',
    component: CNP_MIGRATION_ENABLED ? PendingCnpCampaigns : PartnerCampaigns,
    roles: AllRoles,
  },
  {
    key: 'events-list',
    path: '/events',
    title: 'Events',
    component: EventsList,
    roles: AllRoles,
  },
  {
    key: 'knowledge-base-new',
    path: '/knowledge-base',
    title: 'Knowledge Base',
    component: KnowledgeBaseNew,
    roles: AllRoles,
  },
  {
    key: 'edit-user',
    path: '/resellers',
    title: 'Resellers',
    component: Resellers,
    roles: AllRoles,
  },
  {
    key: 'integrations',
    path: '/integrations',
    title: 'Integrations',
    component: Integrations,
    roles: [Role.User, Role.Manager],
  },
  ...(BRAND_ASSETS_ENABLED
    ? [
        {
          key: 'brand-logo-upload',
          path: '/brands/:id/upload/logo',
          title: 'Logo Upload',
          component: LogoUpload,
          roles: [Role.User, Role.Manager],
        },
        {
          key: 'brand-banner-upload',
          path: '/brands/:id/upload/banner',
          title: 'Banner Upload',
          component: BannerUpload,
          roles: [Role.User, Role.Manager],
        },
        {
          key: 'brand-assets',
          path: '/brands/:id/emulator',
          title: 'Brands',
          component: BrandAssets,
          roles: AllRoles,
        },
      ]
    : []),
];

export default routes;
