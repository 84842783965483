import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Container, Grid, makeStyles } from '@material-ui/core';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getCnpList,
  getAllUsecasesTypes,
  getPartnerCampaigns,
  downloadCnpCampaignsAsCsv,
} from '../apis';
import { generateAndDownloadFile } from '../../../../utils/download';
import { toastFlashMessage } from '../../../../utils';
import {
  PartnerCampaignListingRow,
  ElectCNPDialog,
  RejectCampaignDialog,
} from '../components';
import Loader from '../../../../shared_elements/containers/Loader';
import {
  Button,
  Table,
  FilterType,
  TableFilter,
  WithFiltersConfig,
  FilterConfig,
  useFilters,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { PartnerCampaign } from '../types';
import {
  CombinedFilters,
  Pagination,
  SelectItem,
  UsecaseType,
} from '../../../../utils/types';
import '../../../../assets/styles/partner-campaigns-listing-module.scss';
import {
  CNP_CAMPAIGNS_HISTORY_DEFAULT_ENTRY,
  CNP_CAMPAIGNS_PATH,
} from '../../../../constants/paths';

const normalFilterConfigs = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  downstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    width: 230,
  },
  outSharingStatus: {
    type: FilterType.Dropdown,
    label: 'Sharing Status',
    placeholder: 'Select sharing status',
    options: [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Rejected', value: 'DECLINED' },
    ],
    width: 230,
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const historyFilterConfigs = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  downstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  upstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Upstream CNP',
    placeholder: 'Select upstream CNP',
    options: [],
    width: 260,
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'registeredOnDate', label: 'REGISTERED ON', sortable: false },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  { id: '', label: 'CARRIER TERMS', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: '', label: 'SHARING STATUS', sortable: false },
];

const historyHeadRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  { id: 'upstreamCnpName', label: 'UPSTREAM CNP', sortable: false },
  { id: 'sharedToDate', label: 'SHARED TO ON', sortable: false },
  { id: '', label: 'CARRIER TERMS', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: false },
];

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
  icon: {
    width: '16px',
    height: '16px',
    objectFit: 'contain',
  },
});

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'sharedOnDate',
  ascendingOrder: false,
};
const EmptyPaginationData: Pagination<PartnerCampaign> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
};
enum DialogType {
  None = 'none',
  Elect = 'elect',
  Reject = 'reject',
}
enum ActionType {
  ElectCnp = 'electCnp',
  RejectCnp = 'rejectCnp',
}

const PartnerCampaigns: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({ type: SET_LOADING_MESSAGE, payload: message });
  };
  const clearLoadingMessage = () => {
    dispatch({ type: CLEAR_LOADING_MESSAGE });
  };
  const historyMode = location.pathname !== CNP_CAMPAIGNS_PATH;
  const filter = useFilters({
    configs: historyMode ? historyFilterConfigs : normalFilterConfigs,
  });
  const searchParams = new URLSearchParams(location.search);
  const [currProvisional, setCurrProvisional] = useState<string>(
    searchParams.get('provisional') ?? ''
  );
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [openingDialog, setOpeningDialog] = useState(DialogType.None);
  const [cnpOptions, setCnpOptions] = useState<SelectItem[]>([]);
  const [SPCnpOptions, setSPCnpOptions] = useState<SelectItem[]>([]);
  const [editingCampaign, setEditingCampaign] = useState<PartnerCampaign>();
  const [filtersShown, setFiltersShown] = useState(false);
  const [additionalFilters, setAdditionalFilters] = useState<CombinedFilters>(
    DEFAULT_ADDITIONAL_FILTERS
  );
  const [partnerCampaignsInfo, setPartnerCampaignsInfo] =
    useState<Pagination<PartnerCampaign>>(EmptyPaginationData);

  useEffect(() => {
    const { updateConfigObject } = filter;
    updateConfigObject(
      historyMode ? historyFilterConfigs : normalFilterConfigs
    );
    fetchFilterOptions();
  }, [historyMode]);

  useEffect(() => {
    if (!replaceUrlIfMissRequiredFilter()) {
      const searchParams = new URLSearchParams(location.search);
      const newProvisional = searchParams.get('provisional') as string;
      setCurrProvisional(newProvisional);
      updateFiltersAndFetchCampaigns();
    }
  }, [location.search]);

  useEffect(() => {
    if (currProvisional) {
      updateElectedCnpList();
    }
  }, [currProvisional]);

  const isValidProvisional = (provisional: string | null): boolean => {
    return Boolean(provisional && ['true', 'false'].includes(provisional));
  };

  const replaceUrlIfMissRequiredFilter = () => {
    const searchParams = new URLSearchParams(location.search);
    const provisional = searchParams.get('provisional');

    if (!isValidProvisional(provisional)) {
      searchParams.set('provisional', 'false');
      history.replace({ search: searchParams.toString() });
      return true;
    }
    return false;
  };

  const parseSearchParams = (params = ''): CombinedFilters => {
    let newAdditionFilters = additionalFilters;
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      newAdditionFilters = { sortField: '', ascendingOrder: false };
    } else if (queries.sortField || queries.ascendingOrder) {
      newAdditionFilters = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }
    setAdditionalFilters(newAdditionFilters);

    return {
      ...newAdditionFilters,
      ...appliedFilters,
      page: (queries?.page as string) ?? 1,
    };
  };

  const updateElectedCnpList = async () => {
    if (historyMode) return;

    const cnpMigrationSupported = currProvisional === 'true' ? true : undefined;
    const [cnpList, SPCnpList] = await Promise.all([
      getCnpList({ cnpMigrationSupported }),
      getCnpList({ soleProprietorEnabled: true, cnpMigrationSupported }),
    ]);

    setCnpOptions(
      cnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setSPCnpOptions(
      SPCnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
  };

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const [usecaseTypes, cnpList] = await Promise.all([
      getAllUsecasesTypes(),
      getCnpList(),
    ]);

    const usecaseTypeOptions: SelectItem[] = usecaseTypes.map(
      (item: UsecaseType) => ({
        label: item.displayName,
        value: item.id,
      })
    );
    const updatedCnpOptions: SelectItem[] = cnpList.map((item) => ({
      label: item.displayName,
      value: item.uid,
    }));

    setOptions('usecase', usecaseTypeOptions);
    setOptions('downstreamCnpUid', updatedCnpOptions);
    if (historyMode) {
      setOptions('upstreamCnpUid', updatedCnpOptions);
    }
  };

  const updateFiltersAndFetchCampaigns = () => {
    const queries = parseSearchParams(location.search);
    const queryEntries = Object.entries(queries);
    const { configs } = filter;
    const newAdditionalFilters: CombinedFilters = {
      ...DEFAULT_ADDITIONAL_FILTERS,
      ...additionalFilters,
    };
    queryEntries.forEach(([key, value]) => {
      if (!configs[key] && key !== 'page') {
        newAdditionalFilters[key] =
          key === 'ascendingOrder' || key === 'actionableOnly'
            ? JSON.parse(String(value))
            : value;
      }
    });
    if (historyMode) {
      newAdditionalFilters.sharingStatus = 'ACCEPTED';
      newAdditionalFilters.outSharingStatus = 'PENDING,ACCEPTED';
    } else {
      newAdditionalFilters.sharingStatus = '';
    }
    newAdditionalFilters.actionableOnly = !historyMode;
    setAdditionalFilters(newAdditionalFilters);

    fetchPartnerCampaignsList({
      ...filter.appliedFilters,
      ...newAdditionalFilters,
      page: queries?.page ?? 1,
    });
  };

  const preProcessSharingStatusFilter = (queries: CombinedFilters) => {
    /**
     * Notice: On CNP Campaigns Page(NOT History), the Filter "Sharing Status"
     * (use 'outSharingStatus' as parameter in query)
     * - PENDING: change to 'sharingStatus: PENDING' and delete 'outSharingStatus'
     * - REJECTED: keep the same, 'outSharingStatus: REJECTED'
     */
    const query = { ...queries };
    if (
      query.hasOwnProperty('outSharingStatus') &&
      query.outSharingStatus === 'PENDING'
    ) {
      if (query.hasOwnProperty('sharingStatus')) {
        query.sharingStatus =
          query.sharingStatus === ''
            ? 'PENDING'
            : (query.sharingStatus as string).indexOf('PENDING') === -1
            ? `${query.sharingStatus},PENDING`
            : query.sharingStatus;
      }
      delete query.outSharingStatus;
    }
    return query;
  };

  const fetchPartnerCampaignsList = async (queries: CombinedFilters) => {
    setTableLoader(true);
    const query = preProcessSharingStatusFilter(queries);
    const partnerCampaignData = await getPartnerCampaigns(query);
    if (partnerCampaignData) {
      setLoader(false);
      setPartnerCampaignsInfo(partnerCampaignData);

      if (
        partnerCampaignData.records.length === 0 &&
        partnerCampaignData.totalRecords > 0 &&
        partnerCampaignData.page > 1
      ) {
        const lastPageNo = Math.ceil(
          partnerCampaignData.totalRecords / partnerCampaignData.recordsPerPage
        );
        setLoader(true);
        writeQueryString({ ...queries, page: lastPageNo });
        return;
      }
    }
    setTableLoader(false);
  };

  const handleCloseCnpDialog = (type: DialogType, successFlag = false) => {
    setOpeningDialog(DialogType.None);

    if (successFlag) {
      updateFiltersAndFetchCampaigns();
    }
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;
    writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  const createSortHandler = (sortField: string, ascendingOrder: boolean) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (partnerCampaignsInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: partnerCampaignsInfo.page,
        sortField,
        ascendingOrder,
      });
    }
  };

  const handleClickAction = (type: ActionType, campaign: PartnerCampaign) => {
    setEditingCampaign(campaign);

    if (type === ActionType.ElectCnp) {
      setOpeningDialog(DialogType.Elect);
    } else if (type === ActionType.RejectCnp) {
      setOpeningDialog(DialogType.Reject);
    }
  };

  const writeQueryString = (searchParams: CombinedFilters) => {
    history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    const params = {
      ...additionalFilters,
      ...filter.appliedFilters,
    };
    const query = preProcessSharingStatusFilter(params);
    if ('page' in query) {
      delete query.page;
    }
    const response = await downloadCnpCampaignsAsCsv(query);
    if (response) {
      const file = new File([response], 'cnp-campaigns.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  return (
    <>
      <section className="partner-campaigns-listing-section">
        {loader ? (
          <Loader />
        ) : (
          <Container
            maxWidth={false}
            className="partner-campaigns-listing-container"
          >
            <div className={classes.title}>
              {!loader && partnerCampaignsInfo.totalRecords && !historyMode
                ? `${partnerCampaignsInfo.totalRecords} Pending Connectivity Partner Campaigns `
                : historyMode
                ? 'Accepted Connectivity Partner Campaigns'
                : 'Pending Connectivity Partner Campaigns'}
            </div>
            <div className={classes.buttons}>
              <Button
                color="secondary"
                onClick={() => setFiltersShown(!filtersShown)}
                data-testid="tableAddFilterButton"
              >
                <FontAwesomeIcon icon={faFilter} />
                <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
              </Button>
              <Button
                variant="outline"
                color="secondary"
                onClick={handleDownload}
                data-testid="tableDownloadButton"
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
              <div className={classes.spacing} />
              {location.pathname === CNP_CAMPAIGNS_PATH && (
                <Button
                  data-testid="showHistoryButton"
                  onClick={() =>
                    history.push(CNP_CAMPAIGNS_HISTORY_DEFAULT_ENTRY)
                  }
                >
                  <FontAwesomeIcon icon={faRotateRight} />
                  Show History
                </Button>
              )}
            </div>
            {filtersShown && (
              <Grid container style={{ marginBottom: 12 }}>
                <TableFilter
                  configs={filter.configs}
                  candidateValues={filter.candidateFilters}
                  appliedValues={filter.appliedFilters}
                  onCandidateValuesChange={filter.handleEdit}
                  onAppliedValuesChange={filter.handleApply}
                />
              </Grid>
            )}
            <Grid container>
              <Table
                className="partner-campaigns-listing-table"
                loading={tableLoader}
                emptyState="no campaigns to view"
                handleChangePage={handleChangePage}
                createSortHandler={createSortHandler}
                filter={additionalFilters}
                headRows={historyMode ? historyHeadRows : headRows}
                tableData={partnerCampaignsInfo.records.map((record) => (
                  <PartnerCampaignListingRow
                    data={record}
                    key={record.uid}
                    type={historyMode ? 'history' : ''}
                    handleClickAction={handleClickAction}
                  />
                ))}
                pagination={{
                  page: partnerCampaignsInfo.page,
                  rowsPerPage: partnerCampaignsInfo.recordsPerPage,
                  totalRecords: partnerCampaignsInfo.totalRecords,
                }}
              />
            </Grid>
          </Container>
        )}
      </section>
      {editingCampaign && (
        <>
          <ElectCNPDialog
            open={openingDialog === DialogType.Elect}
            cnpOptions={
              editingCampaign.usecase === 'SOLE_PROPRIETOR'
                ? SPCnpOptions
                : cnpOptions
            }
            handleClose={handleCloseCnpDialog}
            campaignUid={editingCampaign.uid}
            usecase={editingCampaign.usecase}
          />
          <RejectCampaignDialog
            open={openingDialog === DialogType.Reject}
            handleClose={handleCloseCnpDialog}
            campaignUid={editingCampaign.uid}
            brandId={editingCampaign.brandUid}
            downstreamCnpName={editingCampaign.downstreamCnpName}
          />
        </>
      )}
    </>
  );
};

export default PartnerCampaigns;
