import { BoxV2 as Box, Button, Flex, TextInput } from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import { faRectangleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../../../../components';
import { isValidInput } from '../../../../../utils';

interface BulkCancelMigrationModalProps {
  open?: boolean;
  disabled?: boolean;
  campaignUids: string[];
  onClose?: () => void;
  onSubmit?: (value?: string) => void;
}

const REASON_MAX_LENGTH = 2048;

const BulkCancelMigrationModal: FunctionComponent<
  BulkCancelMigrationModalProps
> = ({ open, disabled, campaignUids, onClose, onSubmit }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setValue('');
      setError('');
    }
  }, [open]);

  const handleChange = (value: string) => {
    setValue(value);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    let errorMsg: string | undefined;
    if (typeof value === 'string' && value.length > REASON_MAX_LENGTH) {
      errorMsg = `Maximum ${REASON_MAX_LENGTH} chars are allowed`;
    } else if (!isValidInput(value)) {
      errorMsg = 'Invalid input';
    }

    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    onSubmit?.(value || undefined);
  };

  return (
    <Modal testId="bulkCancelMigrationModal" open={open}>
      <Flex
        sx={{
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faRectangleXmark} />
        <Box as="span">Cancel Migration</Box>
      </Flex>
      <Box
        sx={{
          width: '100%',
          paddingBottom: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
        }}
      >
        Please confirm that you are canceling{' '}
        <Box as="span" sx={{ fontWeight: 700 }}>
          {campaignUids.length} campaign migration(s)
        </Box>
        .
        <br />
        <br />
        Campaign ID(s):
        <br />
        <Box as="span" sx={{ fontWeight: 600 }}>
          {campaignUids.join(', ')}
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <TextInput
          disabled={disabled}
          maxLength={REASON_MAX_LENGTH}
          error={!!error}
          label="Explanation"
          note={error}
          value={value}
          onChange={handleChange}
        />
      </Box>
      <Flex
        sx={{
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={disabled} onClick={handleApply}>
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};

export default BulkCancelMigrationModal;
