import React, { ReactNode, useState } from 'react';
import {
  BoxV2 as Box,
  Flex,
  MultimediaList,
  MultimediaUploader,
  AttachmentPreview,
  MimeTypes,
  AttachmentInfo,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons';
import {
  getImageDimensions,
  isDimensionsNotMatch,
  isFileNameInvalid,
  isFileSizeExceeded,
} from './utils';
import UploadErrorDialog from '../UploadErrorDialog';
import { registerBrandAsset } from '../../apis';

type AssetsType = 'Logo' | 'Banner' | 'Multimedia';
const AcceptedMimeTypes = {
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
};

interface Props {
  assetsType: AssetsType;
  dimensions?: {
    width: number;
    height: number;
  };
  fileLimitKB?: number;
  acceptedMimeTypes?: MimeTypes;
  uploadPermission?: boolean;
  deletePermission?: boolean;
  files?: AttachmentInfo[];
  onChange?: (files: AttachmentInfo[]) => void;
}

const AssetsUpload: React.FC<Props> = ({
  assetsType = 'Multimedia',
  dimensions,
  fileLimitKB,
  acceptedMimeTypes = AcceptedMimeTypes,
  uploadPermission = true,
  deletePermission = true,
  files = [],
  onChange,
}) => {
  const [listLoading, setListLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<AttachmentInfo>();
  const [openError, setOpenError] = useState(false);
  const [errorContent, setErrorContent] = useState<string | ReactNode>('');

  const handleFileTypeError = () => {
    openErrorDialog('The uploaded file type is not supported');
  };

  const onFileChange = async (file: File) => {
    setListLoading(true);
    const fileName =
      file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
    if (isFileNameInvalid(fileName)) {
      openErrorDialog(
        <>
          The uploaded file name is invalid <br />
          Must be between 3 and 50 characters and contain only letters, numbers,
          spaces, hyphens, and underscores
        </>
      );
      return;
    }
    if (fileLimitKB && isFileSizeExceeded(file, fileLimitKB)) {
      openErrorDialog('The uploaded file size exceeds the limit');
      return;
    }
    if (dimensions) {
      const image = await getImageDimensions(file);
      if (isDimensionsNotMatch(image, dimensions)) {
        const media = assetsType === 'Multimedia' ? 'Images' : `${assetsType}s`;
        openErrorDialog(
          <>
            The uploaded file does not have the correct dimensions. <br />
            {media} must be {dimensions.width}px x {dimensions.height}px to
            upload.
          </>
        );
        return;
      }
    }

    const payload = {
      name: fileName,
      type: file.type,
      asset: file,
    };
    const response = await registerBrandAsset('brandId', payload);
    if (response) {
      const attachment = {
        uuid: response.attachmentUuid,
        fileName: response.name,
        mimeType: response.type,
        file,
      };
      onChange?.([...files, attachment]);
    }
    setListLoading(false);
  };

  const openErrorDialog = (content: string | ReactNode) => {
    setListLoading(false);
    setOpenError(true);
    setErrorContent(content);
  };

  const onSelectFile = (idx: number) => {
    setPreviewImage(files[idx]);
  };

  const onDeleteFile = (idx: number) => {
    onChange?.(files.filter((_, i) => i !== idx));
  };

  const customAdditionalBlock = () => {
    const unit = fileLimitKB && fileLimitKB > 1024 ? 'MB' : 'KB';
    const fileLimit =
      fileLimitKB && fileLimitKB > 1024
        ? (fileLimitKB / 1024).toFixed(2)
        : fileLimitKB;
    const acceptedFormats = Object.keys(acceptedMimeTypes).reduce(
      (acc, key, idx, arr) => {
        key = key.replace('.', '').toUpperCase();
        if (idx === arr.length - 1) {
          return `${acc}or ${key}`;
        } else {
          return `${acc}${key}, `;
        }
      },
      ''
    );

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 'H200',
          fontWeight: 400,
          lineHeight: 'normal',
          color: 't.grey300',
          textAlign: 'center',
        }}
      >
        {dimensions && (
          <span>
            File dimensions {dimensions.width}x{dimensions.height}px
          </span>
        )}
        {fileLimitKB && (
          <span>
            Max file size of {fileLimit} {unit}
          </span>
        )}
        <span>
          Accepted formats: <br />
          {acceptedFormats}
        </span>
      </Flex>
    );
  };

  return (
    <Flex sx={{ gap: 10 }}>
      {uploadPermission && (
        <Box sx={{ width: 220, minHeight: 295 }}>
          <MultimediaUploader
            onChange={(file) => onFileChange(file)}
            onTypeError={handleFileTypeError}
            supportedMimeTypes={AcceptedMimeTypes}
            icon={<FontAwesomeIcon icon={faArrowUpFromBracket} size="2x" />}
            buttonText="Click here to Upload"
            description={<Box>Drop files to attach or...</Box>}
            additional={customAdditionalBlock()}
            style={{
              minWidth: '220px',
              minHeight: '100%',
              padding: 30,
              justifyContent: 'center',
              gap: '10px',
              border: '1px dashed #949495',
              borderRadius: '5px',
            }}
          />
        </Box>
      )}
      <Box sx={{ flex: 1, height: 295 }}>
        <MultimediaList
          editable={deletePermission}
          thumbnail
          thumbnailWidth={assetsType === 'Banner' ? 187 : 65}
          downloadable={false}
          title={`${assetsType} Files`}
          emptyMessage="No files have been attached"
          loading={listLoading}
          attachments={files}
          onSelect={onSelectFile}
          onDelete={onDeleteFile}
        />
      </Box>
      {previewImage && previewImage.file && (
        <AttachmentPreview
          onClose={() => setPreviewImage(undefined)}
          file={previewImage.file}
          mimeType={previewImage.mimeType}
          style={{ zIndex: 1000 }}
        />
      )}
      <UploadErrorDialog
        open={openError}
        content={errorContent}
        onClose={() => setOpenError(false)}
      />
    </Flex>
  );
};

export default AssetsUpload;
