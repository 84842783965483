import { Box, Flex, Tooltip } from 'portal-commons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  formatPhoneNumberIntl,
  getCountryCallingCode,
} from 'react-phone-number-input';
import {
  faChartNetwork,
  faCircleUser,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container } from '@material-ui/core';

import {
  createNewBrandApi,
  getVerticalTypes,
  getAllStockExchanges,
  getBrandValidationRulesApi,
  getUsStates,
  getCountries,
} from '../apiServices';
import {
  BrandBasicDetailsForm,
  BrandTrustScore,
  BusinessContactDetailsSection,
  ConfirmationDialog,
  EinErrorModal,
  SupportContactDetailsSection,
} from '../components';
import { BrandErrorCode, BRAND_Info } from '../../index';
import { S3_ASSETS_PATH } from '../../../../constants';
import { EntityTypes } from '../../../../shared_elements/enums';
import { HelpSection, SuccessDialogue } from '../../../../shared_elements';
import { isValidInput } from '../../../../utils';
import { fieldValidation } from '../../../../utils/validator';

import '../../../../assets/styles/csp-register-module.scss';

class BrandRegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadFlag: false,
      brandInfo: BRAND_Info,
      error: {},
      errorCode: {
        ...BrandErrorCode,
      },
      checked: false,
      openTooltip: false,
      countryIsoCode: 'US',
      verticalTypes: [],
      stockExchanges: [],
      step: 1,
      brandCreated: false,
      modalOpen: false,
      modalLoadFlag: false,
      einBrandInfo: {},
      einErrorModal: false,
      brandPaymentInfo: '',
      successInfo: '',
      validationRules: [],
      countryOptions: [],
      usStateOptions: [],
    };
    this.getVerticalTypes = getVerticalTypes.bind(this);
    this.getAllStockExchanges = getAllStockExchanges.bind(this);
    this.getBrandValidationRulesApi = getBrandValidationRulesApi.bind(this);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Register Brand';
    this.getVerticalTypes();
    this.getAllStockExchanges();
    this.getBrandValidationRulesApi();
    this.initializeCountries();
    this.initializeUsStates();
  }

  initializeCountries = async () => {
    const response = await getCountries();
    this.setState({
      countryOptions: Object.entries(response)
        .sort(([key1, value1], [key2, value2]) => {
          if (value1 > value2) {
            return 1;
          } else if (value1 < value2) {
            return -1;
          }
          return 0;
        })
        .map(([key, value]) => ({
          label: value,
          value: key,
        })),
    });
  };

  initializeUsStates = async () => {
    const response = await getUsStates();
    this.setState({
      usStateOptions: Object.entries(response)
        .sort(([key1, value1], [key2, value2]) => {
          if (value1 > value2) {
            return 1;
          } else if (value1 < value2) {
            return -1;
          }
          return 0;
        })
        .map(([key, value]) => ({
          label: value,
          value: key,
        })),
    });
  };

  handleChange = (value, key) => {
    const { brandInfo } = this.state;
    // if(key == "website"){
    //     value = value.toLowerCase()
    // }
    if (key === 'companyName') {
      this.setState((prevState) => ({
        ...prevState,
        brandInfo: {
          ...prevState.brandInfo,
          displayName: value,
        },
        error: {
          ...prevState.error,
          displayName: '',
        },
      }));
    }
    if (key === 'country' && value.value !== brandInfo.country.value) {
      this.setState((prevState) => ({
        ...prevState,
        brandInfo: {
          ...prevState.brandInfo,
          state: '',
          street: '',
          city: '',
          postalCode: '',
          ein: '',
          einIssuingCountry:
            brandInfo.entityType === 'SOLE_PROPRIETOR' ? '' : value,
        },
        error: {
          ...prevState.error,
          state: '',
          street: '',
          city: '',
          postalCode: '',
          ein: '',
          einIssuingCountry: '',
        },
      }));
    }
    if (
      key === 'einIssuingCountry' &&
      value.value !== brandInfo.einIssuingCountry.value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          ein: '',
        },
      }));
    }
    if (key === 'entityType' && value !== brandInfo.entityType) {
      if (value === 'SOLE_PROPRIETOR') {
        this.setState((prevState) => ({
          ...prevState,
          brandInfo: {
            ...prevState.brandInfo,
            companyName: '',
            ein: '',
            einIssuingCountry: '',
            stockSymbol: '',
            stockExchange: '',
            altBusinessId: '',
            altBusinessIdType: '',
            brandRelationshipScore: 1,
            businessContactEmail: '',
          },
          error: {
            ...prevState.error,
            companyName: '',
            ein: '',
            einIssuingCountry: '',
            stockSymbol: '',
            stockExchange: '',
            vertical: '',
            altBusinessId: '',
            altBusinessIdType: '',
            brandRelationshipScore: '',
            businessContactEmail: '',
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          brandInfo: {
            ...prevState.brandInfo,
            stockSymbol: '',
            stockExchange: '',
            firstName: '',
            lastName: '',
            mobilePhone: '',
            businessContactEmail:
              value === EntityTypes.PubliclyTradedCompany
                ? prevState.brandInfo.businessContactEmail || ''
                : '',
          },
          error: {
            ...prevState.error,
            stockSymbol: '',
            stockExchange: '',
            referenceId: '',
            firstName: '',
            lastName: '',
            mobilePhone: '',
            businessContactEmail:
              value === EntityTypes.PubliclyTradedCompany
                ? prevState.error.businessContactEmail || ''
                : '',
          },
        }));
      }
    }
    if (key == 'countryIsoCode phone') {
      this.setState((prevState) => ({
        ...prevState,
        countryIsoCode: value,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        brandInfo: {
          ...prevState.brandInfo,
          [key]: value,
        },
        error: {
          ...prevState.error,
          [key]: '',
        },
      }));
    }
  };

  handleError = (value, key) => {
    const { errorCode, brandInfo } = this.state;
    if (key === 'postalCode' && brandInfo.country.value !== 'US') {
      this.setState((prevState) => ({
        ...prevState.error,
        error: {
          ...prevState.error,
          postalCode: value.length
            ? value.length > 20
              ? 'Maximum 20 chars allowed for'
              : ''
            : 'Please enter ',
        },
      }));
    } else if (key === 'phone') {
      const { countryIsoCode } = this.state;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]: value
            ? countryIsoCode === 'IN'
              ? value.length > 13
                ? 'Maximum 13 numbers allowed'
                : ''
              : ''
            : 'Please enter Support Phone Number',
        },
      }));
    } else if (key === 'ein') {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]: this.validationVerification(
            value,
            key,
            brandInfo.einIssuingCountry.value === 'US' ? `EIN` : `Tax Number/ID`
          ),
        },
      }));
    } else if (key === 'referenceId') {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            errorCode[key][
              fieldValidation({
                ...errorCode[`${key}Obj`],
                fieldval: value,
                requiredFlag:
                  brandInfo.entityType === 'SOLE_PROPRIETOR' ? true : false,
              })
            ],
        },
      }));
    } else if (key === 'businessContactEmail') {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            brandInfo.entityType === EntityTypes.PubliclyTradedCompany
              ? errorCode[key][
                  fieldValidation({
                    ...errorCode[`${key}Obj`],
                    fieldval: value,
                  })
                ]
              : '',
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            errorCode[key][
              fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
            ],
        },
        openTooltip: false,
      }));
    }
  };

  validationVerification = (value, key, label = '') => {
    const { validationRules } = this.state;
    if (value && value.trim()) {
      if (key === 'ein') {
        if (label === 'EIN') {
          let updatedValue = value.trim();
          updatedValue = updatedValue.replace(
            /[ ^!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}\s]/gim,
            ''
          );
          const validationObject =
            validationRules &&
            validationRules.find((item) => item.attributeName == 'ein');
          const newRegex =
            validationObject && validationObject.regex
              ? new RegExp(validationObject.regex, 'gmi')
              : /^[0-9]{2}[-.s]?[0-9]{7}$/gim;
          if (newRegex.test(updatedValue)) {
            //
            return '';
          } else {
            return `Invalid ${label} ${
              validationObject && validationObject.structure
                ? `- ${validationObject.structure}`
                : ''
            }`;
          }
        } else {
          return value.length > 21
            ? `Maximum 21 chars allowed for ${label}`
            : ``;
        }
      }
    } else {
      return `Please enter ${label}`;
    }
  };

  handleFocus = (e) => {
    this.setState({
      openTooltip: true,
    });
  };

  registerBrand = () => {
    this.setState({ loadFlag: true });
    const { errorCode, brandInfo, countryIsoCode, step } = this.state;
    const dialCode = countryIsoCode
      ? getCountryCallingCode(countryIsoCode)
      : '';

    if (step === 1) {
      let validateNewInput = {};
      if (brandInfo.entityType === 'SOLE_PROPRIETOR') {
        validateNewInput = {
          displayName:
            errorCode.displayName[
              fieldValidation({
                ...errorCode.displayNameObj,
                fieldval: brandInfo.displayName,
                requiredFlag: true,
              })
            ],
          country: brandInfo.country.value ? '' : 'Please select country',
          entityType: brandInfo.entityType
            ? ''
            : 'Please select your entity type',
          street:
            errorCode.street[
              fieldValidation({
                ...errorCode.streetObj,
                fieldval: brandInfo.street,
              })
            ],
          city: errorCode.city[
            fieldValidation({
              ...errorCode.cityObj,
              fieldval: brandInfo.city,
            })
          ],
          postalCode:
            brandInfo.country.value === 'US'
              ? errorCode.postalCode[
                  fieldValidation({
                    ...errorCode.postalCodeObj,
                    fieldval: brandInfo.postalCode,
                  })
                ]
              : brandInfo.postalCode != ''
              ? brandInfo.postalCode.length > 20
                ? 'Maximum 20 chars allowed for '
                : ''
              : 'Please enter ',
          state:
            brandInfo.country.value === 'US'
              ? brandInfo.state.value
                ? ''
                : 'Please select state'
              : errorCode.state[
                  fieldValidation({
                    ...errorCode.stateObj,
                    fieldval: brandInfo.state,
                  })
                ],
          firstName:
            errorCode.firstName[
              fieldValidation({
                ...errorCode.firstNameObj,
                fieldval: brandInfo.firstName,
              })
            ],
          lastName:
            errorCode.lastName[
              fieldValidation({
                ...errorCode.lastNameObj,
                fieldval: brandInfo.lastName,
              })
            ],
          referenceId:
            errorCode.referenceId[
              fieldValidation({
                ...errorCode.referenceIdObj,
                fieldval: brandInfo.referenceId,
                requiredFlag: true,
              })
            ],
          website:
            errorCode.website[
              fieldValidation({
                ...errorCode.websiteObj,
                fieldval: brandInfo.website,
              })
            ],
          mobilePhone:
            errorCode.mobilePhone[
              fieldValidation({
                ...errorCode.mobilePhoneObj,
                fieldval: brandInfo.mobilePhone,
              })
            ],
        };
      } else {
        validateNewInput = {
          companyName:
            errorCode.companyName[
              fieldValidation({
                ...errorCode.companyNameObj,
                fieldval: brandInfo.companyName,
              })
            ],
          displayName:
            errorCode.displayName[
              fieldValidation({
                ...errorCode.displayNameObj,
                fieldval: brandInfo.displayName,
              })
            ],
          country: brandInfo.country.value ? '' : 'Please select country',
          entityType: brandInfo.entityType
            ? ''
            : 'Please select your entity type',
          ein: this.validationVerification(
            brandInfo.ein,
            'ein',
            brandInfo.einIssuingCountry.value == 'US' ? `EIN` : `Tax Number/ID`
          ),
          street:
            errorCode.street[
              fieldValidation({
                ...errorCode.streetObj,
                fieldval: brandInfo.street,
              })
            ],
          city: errorCode.city[
            fieldValidation({
              ...errorCode.cityObj,
              fieldval: brandInfo.city,
            })
          ],
          postalCode:
            brandInfo.country.value === 'US'
              ? errorCode.postalCode[
                  fieldValidation({
                    ...errorCode.postalCodeObj,
                    fieldval: brandInfo.postalCode,
                  })
                ]
              : brandInfo.postalCode != ''
              ? brandInfo.postalCode.length > 20
                ? 'Maximum 20 chars allowed for '
                : ''
              : 'Please enter ',
          state:
            brandInfo.country.value === 'US'
              ? brandInfo.state.value
                ? ''
                : 'Please select state'
              : errorCode.state[
                  fieldValidation({
                    ...errorCode.stateObj,
                    fieldval: brandInfo.state,
                  })
                ],
          stockSymbol:
            brandInfo.entityType === 'PUBLIC_PROFIT'
              ? errorCode.stockSymbol[
                  fieldValidation({
                    ...errorCode.stockSymbolObj,
                    fieldval: brandInfo.stockSymbol,
                  })
                ]
              : '',
          stockExchange:
            brandInfo.entityType === 'PUBLIC_PROFIT'
              ? errorCode.stockExchange[
                  fieldValidation({
                    ...errorCode.stockExchangeObj,
                    fieldval: brandInfo.stockExchange,
                  })
                ]
              : '',
          vertical:
            errorCode.vertical[
              fieldValidation({
                ...errorCode.verticalObj,
                fieldval: brandInfo.vertical,
              })
            ],
          altBusinessId:
            errorCode.altBusinessId[
              fieldValidation({
                ...errorCode.altBusinessIdObj,
                fieldval: brandInfo.altBusinessId,
              })
            ],
          referenceId:
            errorCode.referenceId[
              fieldValidation({
                ...errorCode.referenceIdObj,
                requiredFlag: false,
                fieldval: brandInfo.referenceId,
              })
            ],
          website:
            errorCode.website[
              fieldValidation({
                ...errorCode.websiteObj,
                fieldval: brandInfo.website,
              })
            ],
        };
      }
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        if (
          Object.keys(validateNewInput).every((k) => isValidInput(brandInfo[k]))
        ) {
          this.setState({ step: 2, loadFlag: false });
        } else {
          Object.keys(validateNewInput).forEach((k) => {
            if (!isValidInput(brandInfo[k])) {
              this.setState((prevState) => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  [k]: 'Invalid input',
                },
                loadFlag: false,
              }));
            }
          });
        }
      } else {
        this.setState({
          loadFlag: false,
          error: validateNewInput,
        });
      }
    } else if (step === 2) {
      const validateNewInput = {
        brandRelationshipScore: brandInfo.brandRelationshipScore
          ? ''
          : 'Please select Brand Relationship',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        this.setState({ step: 3, loadFlag: false });
      } else {
        this.setState({
          loadFlag: false,
          error: validateNewInput,
        });
      }
    } else if (step === 3) {
      const validateNewInput = {
        // firstName: errorCode['firstName'][fieldValidation({...errorCode['firstNameObj'], fieldval: brandInfo.firstName})],
        // lastName: errorCode['lastName'][fieldValidation({...errorCode['lastNameObj'], fieldval: brandInfo.lastName})],
        email:
          errorCode.email[
            fieldValidation({
              ...errorCode.emailObj,
              fieldval: brandInfo.email,
            })
          ],
        phone: brandInfo.phone
          ? brandInfo.phone === `+${dialCode}`
            ? 'Please enter Support Phone Number'
            : countryIsoCode === 'IN'
            ? brandInfo.phone.length > 13
              ? 'Maximum 13 numbers allowed'
              : ''
            : ''
          : 'Please enter Support Phone Number',
        businessContactEmail:
          brandInfo.entityType === EntityTypes.PubliclyTradedCompany
            ? errorCode.businessContactEmail[
                fieldValidation({
                  ...errorCode.businessContactEmailObj,
                  fieldval: brandInfo.businessContactEmail,
                })
              ]
            : '',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        this.setState({
          modalOpen: true,
          loadFlag: false,
        });
      } else {
        this.setState({
          loadFlag: false,
          error: validateNewInput,
        });
      }
    }
  };

  handleCreateBrand = (flag) => {
    if (flag) {
      const { brandInfo } = this.state;
      const formData = { ...brandInfo };
      formData.nonBlocking = true;
      formData.country = brandInfo.country.value;
      formData.phone = formatPhoneNumberIntl(brandInfo.phone)
        ? formatPhoneNumberIntl(brandInfo.phone)
        : brandInfo.phone;
      formData.einIssuingCountry = brandInfo.einIssuingCountry.value;
      if (formData.country == 'US') {
        formData.state = brandInfo.state.value;
      }
      if (formData.displayName == '') {
        formData.displayName = formData.companyName;
      }
      this.setState({ modalLoadFlag: true });
      createNewBrandApi(formData).then((response) => {
        this.setState({
          loadFlag: false,
          modalLoadFlag: false,
          modalOpen: false,
        });
        if (response.status >= 200 && response.status < 300) {
          this.setState({ brandCreated: true, brandUid: response.data.uid });
        }
      });
    } else {
      this.setState({
        modalOpen: false,
        loadFlag: false,
        modalLoadFlag: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cspData.length && this.state.brandPaymentInfo === '') {
      const brandPaymentInfo = this.props.cspData.find(
        (item) => item.lookup_key === 'csp-create-brand-form#agree-payment'
      );
      this.setState({
        brandPaymentInfo,
      });
    }
    if (this.props.cspData.length && this.state.successInfo === '') {
      const successInfo = this.props.cspData.find(
        (item) => item.lookup_key === 'csp-create-brand-form#success'
      );
      this.setState({
        successInfo,
      });
    }
  }

  render() {
    const {
      brandInfo,
      error,
      loadFlag,
      brandUid,
      verticalTypes,
      stockExchanges,
      brandCreated,
      step,
      modalOpen,
      modalLoadFlag,
      einErrorModal,
      einBrandInfo,
      brandPaymentInfo,
      successInfo,
    } = this.state;
    const countryFlag = Object.keys(brandInfo.country).length;
    return (
      <section className="csp-verification-section brands">
        <Container maxWidth={false} style={{ padding: '0px' }}>
          <div className="steps-wrapper text-center">
            <ul className="steps-listing list-unstyled">
              <li className={step === 1 ? 'active' : ''}>
                <div className="step">
                  <span>1</span>
                </div>
                <h3 className="secondaryText">Brand Details</h3>
              </li>
              <li className={step === 2 ? 'active trustScore' : 'trustScore'}>
                <div className="step">
                  <span>2</span>
                </div>
                <h3 className="secondaryText">Brand Relationship</h3>
              </li>
              <li className={step === 3 ? 'active' : ''}>
                <div className={'step'}>
                  <span>3</span>
                </div>
                <h3 className="secondaryText">Contact Details</h3>
              </li>
            </ul>
          </div>
          <form onSubmit={this.registerBrand}>
            <Grid
              container
              spacing={0}
              justify="flex-start"
              className="csp-portal"
            >
              <Grid item lg={12}>
                <div className="csp-verification-form brands">
                  {step === 1 ? (
                    <div className="csp-details basic">
                      <div className="csp-details-heading">
                        <Box flexDirection="row" alignItems="center">
                          <Box margin={{ right: 'xs' }}>
                            <FontAwesomeIcon icon={faTag} size="xl" />
                          </Box>
                          <h3 className="heading1">Brand Details</h3>
                        </Box>
                      </div>
                      <BrandBasicDetailsForm
                        brandInfo={brandInfo}
                        error={error}
                        handleChange={this.handleChange}
                        handleError={this.handleError}
                        brandRegisterFlag={true}
                        openTooltip={this.state.openTooltip}
                        handleFocus={this.handleFocus}
                        countryFlag={countryFlag}
                        verticalTypes={verticalTypes}
                        stockExchanges={stockExchanges}
                        countries={this.state.countryOptions}
                        usStates={this.state.usStateOptions}
                      />
                    </div>
                  ) : step === 2 ? (
                    <div className="csp-details brandRelationship">
                      <div className="csp-details-heading">
                        <Box flexDirection="row" alignItems="center">
                          <Box margin={{ right: 's' }}>
                            <FontAwesomeIcon icon={faChartNetwork} size="lg" />
                          </Box>
                          <div className="title">
                            <h3 className="heading1">Brand Relationship</h3>
                            <Tooltip
                              title={
                                'The “Brand Relationship” reflects the business history you have with a specific brand.'
                              }
                              style={{ top: 1 }}
                            />
                          </div>
                        </Box>
                      </div>
                      <BrandTrustScore
                        brandInfo={brandInfo}
                        error={error}
                        handleChange={this.handleChange}
                        handleError={this.handleError}
                      />
                    </div>
                  ) : step === 3 ? (
                    <Flex
                      sx={{
                        flexDirection: 'column',
                        rowGap: '30px',
                        marginBottom: '30px',
                      }}
                    >
                      <SupportContactDetailsSection
                        email={brandInfo.email}
                        emailError={error.email}
                        phone={brandInfo.phone}
                        phoneError={error.phone}
                        onEmailChange={(value) =>
                          this.handleChange(value, 'email')
                        }
                        onEmailBlur={(value) =>
                          this.handleError(value, 'email')
                        }
                        onPhoneCountryChange={(value) =>
                          this.handleChange(value, 'countryIsoCode phone')
                        }
                        onPhoneChange={(value) =>
                          this.handleChange(value, 'phone')
                        }
                        onPhoneBlur={(value) =>
                          this.handleError(value, 'phone')
                        }
                      />
                      {brandInfo.entityType === 'PUBLIC_PROFIT' && (
                        <BusinessContactDetailsSection
                          email={brandInfo.businessContactEmail}
                          emailError={error.businessContactEmail}
                          onEmailChange={(value) =>
                            this.handleChange(value, 'businessContactEmail')
                          }
                          onEmailBlur={(value) =>
                            this.handleError(value, 'businessContactEmail')
                          }
                        />
                      )}
                    </Flex>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} className="bottom-blk">
                <ul className="list-inline">
                  {step == 1 ? (
                    <li className="form-group-field">
                      <input
                        type="submit"
                        disabled={
                          loadFlag ||
                          !brandInfo.country.value ||
                          !brandInfo.entityType ||
                          Object.keys(error).find((k) => error[k] != '')
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.registerBrand();
                        }}
                        value="Next"
                      />
                    </li>
                  ) : step == 2 ? (
                    <>
                      <li>
                        <p>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ step: 1 });
                            }}
                            className={
                              loadFlag ||
                              Object.keys(error).find((k) => error[k] != '')
                                ? 'secondary-btn disabled'
                                : 'secondary-btn'
                            }
                          >
                            Previous
                          </a>
                        </p>
                      </li>
                      <li className="form-group-field">
                        <input
                          type="submit"
                          disabled={
                            loadFlag ||
                            Object.keys(error).find((k) => error[k] != '')
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.registerBrand();
                          }}
                          value="Next"
                        />
                      </li>{' '}
                    </>
                  ) : step == 3 ? (
                    <>
                      <li>
                        <p>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ step: 2 });
                            }}
                            className={
                              loadFlag ||
                              Object.keys(error).find((k) => error[k] != '')
                                ? 'secondary-btn disabled'
                                : 'secondary-btn'
                            }
                          >
                            Previous
                          </a>
                        </p>
                      </li>
                      <li className="form-group-field">
                        <input
                          type="submit"
                          disabled={
                            loadFlag ||
                            Object.keys(error).find((k) => error[k] != '')
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.registerBrand();
                          }}
                          value={loadFlag ? 'Processing...' : 'Next'}
                        />
                      </li>
                    </>
                  ) : null}
                </ul>
              </Grid>
            </Grid>
          </form>
        </Container>
        <HelpSection path="/brands/create" />
        {
          <SuccessDialogue
            open={brandCreated}
            icon={`${S3_ASSETS_PATH}/images/brand-success.svg`}
            title="Congratulations!"
            content={successInfo ? successInfo.text : ''}
            handleOk={() => this.props.history.push(`/brands/${brandUid}`)}
          />
        }
        {
          <ConfirmationDialog
            open={modalOpen}
            handleClick={this.handleCreateBrand}
            loader={modalLoadFlag}
            content={brandPaymentInfo ? brandPaymentInfo.text : ''}
          />
        }
        {
          <EinErrorModal
            open={einErrorModal}
            handleClick={(flag) => {
              flag
                ? this.props.history.push({
                    pathname: `/brands/${einBrandInfo.uid}`,
                    state: { goBackPage: `/brand/create` },
                  })
                : this.setState({ einErrorModal: false });
            }}
            einBrandInfo={einBrandInfo}
          />
        }
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(BrandRegisterForm);
