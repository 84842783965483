import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
  FilterConfig,
  FilterType,
  Table,
  TableFilter,
  useFilters,
  usePermission,
  useTable,
} from 'portal-commons';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  faFilter,
  faArrowDownToLine,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getCnpList,
  getAllUsecasesTypes,
  getPartnerCampaigns,
  declineCampaigns,
  downloadCnpCampaignsAsCsv,
  electCampaigns,
} from '../apis';
import {
  BulkDeclineModal,
  BulkElectModal,
  ElectCNPDialog,
  PendingCnpCampaignListingRow,
  RejectCampaignDialog,
} from '../components';
import { EmptyFetchedData } from '../constants';
import { BulkCampaignAction } from '../enums';
import { BulkOperationError } from '../errors';
import { PartnerCampaign } from '../types';
import { PageTitle } from '../../../../components';
import { CNP_CAMPAIGNS_HISTORY_DEFAULT_ENTRY } from '../../../../constants/paths';
import { DateRangePresets } from '../../../../constants/options';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import { toastFlashMessage } from '../../../../utils';
import { generateAndDownloadFile } from '../../../../utils/download';
import { SelectItem, UsecaseType } from '../../../../utils/types';
import { convertTimeWithTimezone } from '../../../../utils/time';

const FilterConfigs: Record<string, FilterConfig> = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  downstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  dateRange: {
    type: FilterType.DateRange,
    label: 'Shared With Me On',
    placeholder: 'Select date range',
    delayUpdate: false,
    dateRangePresets: DateRangePresets,
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    width: 230,
  },
  outSharingStatus: {
    type: FilterType.Dropdown,
    label: 'Sharing Status',
    placeholder: 'Select sharing status',
    options: [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Rejected', value: 'DECLINED' },
    ],
    width: 230,
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  { id: 'registeredOnDate', label: 'REGISTERED ON', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  { id: '', label: 'CARRIER TERMS', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: '', label: 'SHARING STATUS', sortable: false },
];

const BulkActionOptions: SelectItem[] = [
  {
    label: 'Elect',
    value: BulkCampaignAction.Elect,
  },
  {
    label: 'Decline',
    value: BulkCampaignAction.Decline,
  },
];

enum DialogType {
  None = 'none',
  Elect = 'elect',
  Reject = 'reject',
}

const PendingCnpCampaigns: FunctionComponent = () => {
  const {
    configs: filterConfigs,
    candidateFilters,
    appliedFilters,
    additionalFilters,
    handleEdit: handleFiltersEdit,
    handleApply: handleFiltersApply,
    setOptions: setFilterOptions,
  } = useFilters({
    configs: FilterConfigs,
  });
  const { hasPermission } = usePermission();
  const history = useHistory();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({ type: SET_LOADING_MESSAGE, payload: message });
  };
  const clearLoadingMessage = () => {
    dispatch({ type: CLEAR_LOADING_MESSAGE });
  };
  const [cnpOptions, setCnpOptions] = useState<SelectItem[]>([]);
  const [soleProprietorCnpOptions, setSoleProprietorCnpOptions] = useState<
    SelectItem[]
  >([]);
  const [editingCampaign, setEditingCampaign] = useState<PartnerCampaign>();
  const [openingDialog, setOpeningDialog] = useState(DialogType.None);
  const [filtersShown, setFiltersShown] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [errorCampaigns, setErrorCampaigns] = useState<string[]>([]);
  const [bulkElectModal, setBulkElectModal] = useState(false);
  const [bulkDeclineModal, setBulkDeclineModal] = useState(false);
  const [bulkActionProcessing, setBulkActionProcessing] = useState(false);

  const fetchCampaigns = async () => {
    const queries = getQueries();
    const { provisional } = queries;
    if (provisional !== 'true' && provisional !== 'false') {
      return EmptyFetchedData;
    }

    const response = await getPartnerCampaigns(queries);
    if (response) {
      return {
        data: response.records,
        rowsPerPage: response.recordsPerPage,
        total: response.totalRecords,
        page: response.page,
      };
    }
    return EmptyFetchedData;
  };

  const {
    loading: tableLoading,
    pageLoading,
    total: totalCampaigns,
    handleSorting: handleSortingChange,
    handleChangePage: handlePageChange,
    tableData: campaigns,
    pagination,
    filter: tableFilters,
    reload,
  } = useTable<PartnerCampaign>(fetchCampaigns, {
    cappingTotal: 10000,
    deps: [appliedFilters, additionalFilters],
    defaultFilter: {
      sortField: 'sharedOnDate',
      ascendingOrder: false,
    },
  });

  const bulkActionOptions = useMemo(() => {
    return BulkActionOptions.map((option) => ({
      ...option,
      disabled: !hasPermission(`cnpCampaignList.${option.value}`),
    }));
  }, [hasPermission]);

  const soleProprietorCampaignsSelected = useMemo(
    () =>
      campaigns
        .filter((campaign) => selectedCampaigns.includes(campaign.uid))
        .some((campaign) => campaign.usecase === 'SOLE_PROPRIETOR'),
    [campaigns, selectedCampaigns]
  );

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const { provisional } = appliedFilters;
    if (provisional === 'true' || provisional === 'false') {
      fetchCnpOptions(provisional);
    } else {
      handleFiltersApply({
        ...appliedFilters,
        provisional: 'false',
      });
    }
  }, [appliedFilters.provisional]);

  useEffect(() => {
    setErrorCampaigns([]);
  }, [bulkAction]);

  useEffect(() => {
    setSelectedCampaigns([]);
    setErrorCampaigns([]);
  }, [campaigns]);

  useEffect(() => {
    const newErrorCampaigns = errorCampaigns.filter((campaign) =>
      selectedCampaigns.includes(campaign)
    );
    setErrorCampaigns(newErrorCampaigns);
  }, [selectedCampaigns]);

  const getQueries = () => {
    const queries = {
      actionableOnly: 'true',
      ...tableFilters,
      ...appliedFilters,
      ...additionalFilters,
    } as Record<string, any>;
    if (queries.dateRange) {
      const dates = (queries.dateRange as string)
        .replace(/\s/gi, '')
        .split('-');
      if (dates.length === 2) {
        queries.sharedOnStartDate = convertTimeWithTimezone(
          dates[0],
          undefined,
          'YYYY-MM-DD'
        );
        queries.sharedOnEndDate = convertTimeWithTimezone(
          dates[1],
          undefined,
          'YYYY-MM-DD'
        );
      }
      delete queries.dateRange;
    }
    /**
     * Notice: On CNP Campaigns Page(NOT History), the Filter "Sharing Status"
     * (use 'outSharingStatus' as parameter in query)
     * - PENDING: change to 'sharingStatus: PENDING' and delete 'outSharingStatus'
     * - REJECTED: keep the same, 'outSharingStatus: REJECTED'
     */
    if (queries.outSharingStatus === 'PENDING') {
      const sharingStatusSet = new Set(queries.sharingStatus?.split(',') ?? []);
      sharingStatusSet.add(queries.outSharingStatus);
      queries.sharingStatus = [...sharingStatusSet].join(',');
      delete queries.outSharingStatus;
    }
    return queries;
  };

  const fetchFilterOptions = async () => {
    const [usecaseTypes, cnpList] = await Promise.all([
      getAllUsecasesTypes(),
      getCnpList(),
    ]);

    const usecaseTypeOptions: SelectItem[] = usecaseTypes.map(
      (item: UsecaseType) => ({
        label: item.displayName,
        value: item.id,
      })
    );
    const updatedCnpOptions: SelectItem[] = cnpList.map((item) => ({
      label: item.displayName,
      value: item.uid,
    }));

    setFilterOptions('usecase', usecaseTypeOptions);
    setFilterOptions('downstreamCnpUid', updatedCnpOptions);
  };

  const fetchCnpOptions = async (provisional: string) => {
    const cnpMigrationSupported = provisional === 'true' || undefined;
    const [cnpList, soleProprietorCnpList] = await Promise.all([
      getCnpList({ cnpMigrationSupported }),
      getCnpList({ soleProprietorEnabled: true, cnpMigrationSupported }),
    ]);

    setCnpOptions(
      cnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setSoleProprietorCnpOptions(
      soleProprietorCnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
  };

  const handleCloseCnpDialog = (type: DialogType, successFlag = false) => {
    setOpeningDialog(DialogType.None);

    if (successFlag) {
      reload();
    }
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    const queries = getQueries();
    if ('page' in queries) {
      delete queries.page;
    }
    const response = await downloadCnpCampaignsAsCsv(queries);
    if (response) {
      const file = new File([response], 'cnp-campaigns.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  const handleBulkActionApply = () => {
    if (bulkAction === BulkCampaignAction.Elect) {
      setBulkElectModal(true);
    } else if (bulkAction === BulkCampaignAction.Decline) {
      setBulkDeclineModal(true);
    }
  };

  const handleBulkElect = async (upstreamCnpUid: string) => {
    try {
      const response = await electCampaigns(upstreamCnpUid, selectedCampaigns);
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) elected`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot elect campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  const handleBulkDecline = async (explanation: string) => {
    try {
      const response = await declineCampaigns(selectedCampaigns, explanation);
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) declined`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot decline campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  return (
    <>
      <Box
        as="section"
        sx={{
          // Fix dropdown width affected by global styling
          '& .MuiAutocomplete-root': {
            width: 'unset',
          },
        }}
        data-testid="campaignListing"
      >
        {pageLoading ? (
          <Loader />
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: '12px',
            }}
          >
            <PageTitle>
              {totalCampaigns > 0
                ? `${totalCampaigns} Pending Connectivity Partner Campaigns `
                : 'Pending Connectivity Partner Campaigns'}
            </PageTitle>
            <Flex
              sx={{
                alignItems: 'center',
                columnGap: '12px',
                width: '100%',
                marginTop: '-8px',
              }}
            >
              <Button
                color="secondary"
                onClick={() => setFiltersShown(!filtersShown)}
                data-testid="tableAddFilterButton"
              >
                <FontAwesomeIcon icon={faFilter} />
                <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
              </Button>
              <Button
                variant="outline"
                color="secondary"
                onClick={handleDownload}
                data-testid="tableDownloadButton"
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
              <Box sx={{ flex: 1 }} />
              <Button
                data-testid="showHistoryButton"
                onClick={() =>
                  history.push(CNP_CAMPAIGNS_HISTORY_DEFAULT_ENTRY)
                }
              >
                <FontAwesomeIcon icon={faRotateRight} />
                Show History
              </Button>
            </Flex>
            {filtersShown && (
              <TableFilter
                configs={filterConfigs}
                candidateValues={candidateFilters}
                appliedValues={appliedFilters}
                onCandidateValuesChange={handleFiltersEdit}
                onAppliedValuesChange={handleFiltersApply}
              />
            )}
            <Flex sx={{ alignItems: 'center', columnGap: '10px' }}>
              <Dropdown
                size="small"
                label="Bulk Actions"
                options={bulkActionOptions}
                value={bulkAction}
                onChange={(value) => {
                  setBulkAction(value);
                }}
              />
              <Button
                disabled={selectedCampaigns.length < 1 || !bulkAction}
                size="small"
                onClick={handleBulkActionApply}
              >
                Apply
              </Button>
              <Box as="span">{selectedCampaigns.length} selected</Box>
            </Flex>
            <Table
              testId="campaignsListingListingTableNew"
              selectable
              loading={tableLoading}
              emptyState="no campaigns to view"
              handleChangePage={handlePageChange}
              createSortHandler={handleSortingChange}
              filter={tableFilters}
              headRows={headRows}
              data={campaigns as Record<string, any>[]}
              rowKey="uid"
              selectedRows={selectedCampaigns}
              onSelectChange={(value) => {
                setSelectedCampaigns(value);
              }}
              renderRow={(data, attributes) => {
                const campaign = data as unknown as PartnerCampaign;
                return (
                  <PendingCnpCampaignListingRow
                    key={campaign.uid}
                    selectable={attributes?.selectable}
                    selected={attributes?.selected}
                    error={errorCampaigns.includes(campaign.uid)}
                    campaign={campaign}
                    onChange={attributes?.onSelectedChange}
                    onElect={() => {
                      setEditingCampaign(campaign);
                      setOpeningDialog(DialogType.Elect);
                    }}
                    onDecline={() => {
                      setEditingCampaign(campaign);
                      setOpeningDialog(DialogType.Reject);
                    }}
                  />
                );
              }}
              pagination={pagination}
            />
          </Flex>
        )}
      </Box>
      {editingCampaign && (
        <>
          <ElectCNPDialog
            open={openingDialog === DialogType.Elect}
            cnpOptions={
              editingCampaign.usecase === 'SOLE_PROPRIETOR'
                ? soleProprietorCnpOptions
                : cnpOptions
            }
            handleClose={handleCloseCnpDialog}
            campaignUid={editingCampaign.uid}
            usecase={editingCampaign.usecase}
          />
          <RejectCampaignDialog
            open={openingDialog === DialogType.Reject}
            handleClose={handleCloseCnpDialog}
            campaignUid={editingCampaign.uid}
            brandId={editingCampaign.brandUid}
            downstreamCnpName={editingCampaign.downstreamCnpName}
          />
        </>
      )}
      <BulkElectModal
        open={bulkElectModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        options={
          soleProprietorCampaignsSelected
            ? soleProprietorCnpOptions
            : cnpOptions
        }
        onClose={() => {
          setBulkElectModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkElect(value);
          setBulkActionProcessing(false);
          setBulkElectModal(false);
        }}
      />
      <BulkDeclineModal
        open={bulkDeclineModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        onClose={() => {
          setBulkDeclineModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkDecline(value);
          setBulkActionProcessing(false);
          setBulkDeclineModal(false);
        }}
      />
    </>
  );
};

export default PendingCnpCampaigns;
