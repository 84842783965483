import { Dialog } from '@material-ui/core';
import { BoxV2 as Box, Button, Flex } from 'portal-commons';
import React, { useEffect, useState } from 'react';
import { BrandAssetExtended, BrandAssetType } from '../../types';
import { getAttachmentAsImageFile } from '../../apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

export type AssetActionModalType = 'verify' | 'delete';
interface Props {
  open: boolean;
  modalType: AssetActionModalType;
  data: BrandAssetExtended;
  onSubmit: (data: BrandAssetExtended) => void;
  onClose: (data: BrandAssetExtended) => void;
}

const AssetActionModal: React.FC<Props> = ({
  open,
  modalType,
  data,
  onSubmit,
  onClose,
}) => {
  const isLogo = data.type === BrandAssetType.Logo;
  const previewHeight = isLogo ? 195 : 203;
  const rectangleStyle = {
    maxHeight: isLogo ? undefined : previewHeight,
    height: isLogo ? previewHeight : 'auto',
    width: isLogo ? previewHeight : 654,
  };
  const [previewFile, setPreviewFile] = useState<File | null>(
    data?.file ?? null
  );

  useEffect(() => {
    if (!data.file) {
      loadPreviewFile();
    } else {
      setPreviewFile(data.file);
    }
    return () => {
      setPreviewFile(null);
    };
  }, [data.attachmentUuid, data.file]);

  const loadPreviewFile = async () => {
    const file = await getAttachmentAsImageFile(data);
    if (file) {
      data.file = file;
      setPreviewFile(file);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={isLogo ? 'sm' : 'lg'}
      onClose={() => onClose(data)}
      data-testid="brandAssetActionModal"
    >
      <Box sx={{ p: '28px 30px 8px', width: isLogo ? 485 : 715 }}>
        <Box
          sx={{
            color: 't.grey500',
            textAlign: 'left',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
          }}
        >
          <Box
            as="h3"
            sx={{
              fontSize: 'H600',
              fontWeight: 600,
              py: 's',
              textAlign: 'center',
            }}
          >
            {modalType === 'verify'
              ? 'Asset Verification'
              : 'Asset Delete Confirmation'}
          </Box>
          <Box sx={{ my: 'xs' }}>
            {modalType === 'verify' ? (
              <>
                A one-time, non-refundable payment of ${isLogo ? 75 : 50} is
                required to verify a {isLogo ? 'logo' : 'banner'}. This will be
                added to your next invoice.
                <br />
                <br />
                Click <strong style={{ fontWeight: 600 }}>I agree</strong> to
                confirm.
              </>
            ) : (
              <>Are you sure you want to delete the asset?</>
            )}
          </Box>
          <Flex
            sx={{
              my: 's',
              justifyContent: 'center',
              alignItems: 'center',
              height: previewHeight,
            }}
            data-test="brandAssetActionModalPreview"
          >
            {!previewFile ? (
              <FontAwesomeIcon icon={faSpinner} size="2x" spin />
            ) : (
              <Box
                sx={{
                  ...rectangleStyle,
                  borderRadius: isLogo ? '50%' : 0,
                  overflow: isLogo ? 'hidden' : 'unset',
                }}
              >
                <img
                  style={rectangleStyle}
                  src={URL.createObjectURL(previewFile)}
                />
              </Box>
            )}
          </Flex>
        </Box>
        <Flex sx={{ py: 'l', justifyContent: 'center', gap: 'l' }}>
          <Button
            onClick={() => onClose(data)}
            color="primary"
            variant="outline"
            data-testid="brandAssetActionModalCancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(data)}
            color="primary"
            data-testid="brandAssetActionModalConfirm"
          >
            {modalType === 'verify' ? 'I Agree' : 'Confirm'}
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
};

export default AssetActionModal;
