import React, { useState } from 'react';
import {
  Grid,
  TextField,
  List,
  ListItem,
  ClickAwayListener,
} from '@material-ui/core';
import UsecasesList from './UsecasesList';
import { S3_ASSETS_PATH } from '../../../../constants';
import { globalGetService } from '../../../../utils/globalApiServices';

import { Box, Tooltip } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseyePointer,
  faLocationCrosshairs,
  faMessageDots,
  faFileImage,
  faSquarePollHorizontal,
  faArrowProgress,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';

export default function SelectBrand(props) {
  const {
    error,
    usecase,
    usecases = [],
    campaignInfo,
    displayName,
    brandUsecases = [],
    brandUid,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [brandName, setBrandName] = useState(displayName);
  const [brandSuggestions, setBrandSuggestions] = useState([]);
  const [suggestionLoader, setSuggestionLoader] = useState(true);

  const fetchBrandSuggestions = (query = {}) => {
    setSuggestionLoader(true);
    globalGetService(`csp/brands/suggestions`, { ...query, limit: 20 }).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setSuggestionLoader(false);
          const suggestions = response.data;
          const updatedSuggestions = [];
          suggestions.forEach((suggestion) => {
            const brandCount = suggestion.brandUid.split(',').length;
            if (suggestion.brandUid && brandCount > 1) {
              suggestion.brandUid.split(',').forEach((brandUid) => {
                updatedSuggestions.push({
                  brandName: suggestion.brandName,
                  brandUid,
                });
              });
            } else {
              updatedSuggestions.push(suggestion);
            }
          });
          setBrandSuggestions(updatedSuggestions);
        }
      }
    );
  };

  return (
    <>
      <Grid item xs={12} data-testid="selectBrand">
        <div className="select-brand wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faLocationCrosshairs} size="xl" />
              </Box>
              <h3 className="heading1">Select Brand</h3>
            </Box>
          </div>
          <Grid container>
            <Grid item xs={6}>
              {/* <div className="form-group-field">
                                <IntegrationReactSelect options={options} label="Brand Name" error={error.brandUid ? true: false} value={options.find(option => option.value == brandUid)} handleChange={(selectedOption, keyName) => props.handleChange(selectedOption.value, keyName)} keyName="brandUid"/>
                                <Tooltip
                                    title={'Select a registered Brand'}
                                    placement="right-end"
                                />
                            {error.brandUid ? <h6 className="error-msg">{error.brandUid}</h6>:null}
                        </div> */}
              <div className="form-group-field">
                <TextField
                  value={brandName}
                  id=""
                  data-testid="selectBrandBrandNameInput"
                  label="Type Brand Name"
                  fullWidth={true}
                  onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                    setBrandName(e.target.value);
                    if (e.target.value && e.target.value.length >= 1) {
                      props.handleError(e.target.value, 'brandUid');
                      if (e.target.value.trim()) {
                        if (e.target.value && e.target.value.length >= 2) {
                          fetchBrandSuggestions({
                            prefix: e.target.value.trim(),
                          });
                        }
                      }
                    } else {
                      setBrandSuggestions([]);
                    }
                  }}
                />
                <Tooltip
                  title={'Select a registered Brand'}
                  placement="right-end"
                />
                {error.brandUid ? (
                  <h6 className="error-msg">{error.brandUid}</h6>
                ) : null}
                {brandSuggestions && Boolean(anchorEl) ? (
                  <ClickAwayListener
                    onClickAway={() => {
                      props.handleChange('', 'brandUid');
                      props.handleChange('', 'brandName');
                      setBrandName('');
                      setAnchorEl(null);
                      props.handleChange('', 'usecase');
                      props.resetBrandUsecases();
                    }}
                  >
                    <div
                      className="auto-suggestions-dropdown"
                      data-testid="selectBrandSuggestionList"
                    >
                      <List className="listUnstyle">
                        {brandSuggestions.length > 0 ? (
                          brandSuggestions.map((item, index) => (
                            <ListItem
                              data-testid={`selectBrandSuggestionListItem-${index}`}
                              key={index}
                              onClick={() => {
                                setBrandName(item.brandName);
                                props.handleChange(item.brandUid, 'brandUid');
                                props.handleChange(item.brandName, 'brandName');
                                setAnchorEl(null);
                                props.getListOfBrandUsecases(item.brandUid);
                              }}
                            >
                              {`${item.brandName} - ${item.brandUid}`}
                            </ListItem>
                          ))
                        ) : brandName && !suggestionLoader ? (
                          <ListItem
                            onClick={() => {
                              props.handleChange('', 'brandUid');
                              props.handleChange('', 'brandName');
                              setBrandName('');
                              setAnchorEl(null);
                            }}
                            className="noOptions"
                            data-testid="selectBrandSuggestionListItemEmptyState"
                          >
                            {'No options available'}
                          </ListItem>
                        ) : brandName && brandName.length < 2 ? (
                          <ListItem
                            data-testid="selectBrandSuggestionListItemInputInProgress"
                            onClick={() => {
                              props.handleChange('', 'brandUid');
                              props.handleChange('', 'brandName');
                              setBrandName('');
                              setAnchorEl(null);
                            }}
                            className="noOptions"
                          >
                            {brandName && brandName.length < 2
                              ? 'Enter at least 2 characters'
                              : 'No options available'}
                          </ListItem>
                        ) : null}
                      </List>
                    </div>
                  </ClickAwayListener>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="select-usecase wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon
                  icon={faBullseyePointer}
                  size="xl"
                  color="#49535D"
                />
              </Box>
              <div className="title">
                <h3 className="heading1">
                  Select a Use-Case
                  <span className="paragraph">
                    {' '}
                    (Please hover over use case names for a description)
                  </span>
                </h3>
                {/* <Tooltip
                              title={'Direct Connect Aggregator is your connectivity partner who provides access to the mobile network operators for delivering your campaigns.'}
                              heading
                          /> */}
              </div>
            </Box>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <UsecasesList
                title="Standard Campaign Type"
                usecase={usecase}
                usecases={usecases}
                handleChange={props.handleChange}
                type="STANDARD"
                campaignInfo={campaignInfo}
                brandUsecases={brandUsecases}
              />
            </Grid>
            <Grid item xs={6}>
              <UsecasesList
                title="Special Campaign Type"
                usecase={usecase}
                usecases={usecases}
                handleChange={props.handleChange}
                campaignInfo={campaignInfo}
                type="SPECIAL"
                brandUsecases={brandUsecases}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
