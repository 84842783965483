import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
} from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import { faArrowProgress } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../../../../components';
import { SelectItem } from '../../../../../utils/types';

interface BulkInitiateMigrationModalProps {
  open?: boolean;
  disabled?: boolean;
  campaignUids: string[];
  options?: SelectItem[];
  onClose?: () => void;
  onSubmit?: (value: string) => void;
}

const BulkInitiateMigrationModal: FunctionComponent<
  BulkInitiateMigrationModalProps
> = ({ open, disabled, campaignUids, options = [], onClose, onSubmit }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setValue('');
      setError('');
    }
  }, [open]);

  const handleChange = (value: string) => {
    setValue(value);
    setError('');
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleApply = () => {
    if (!value) {
      setError('Select a connectivity partner');
      return;
    }

    onSubmit?.(value);
  };

  return (
    <Modal testId="bulkInitiateMigrationModal" open={open}>
      <Flex
        sx={{
          alignItems: 'center',
          columnGap: '12px',
          padding: '12px 0',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
          color: '#333E41',
        }}
      >
        <FontAwesomeIcon icon={faArrowProgress} />
        <Box as="span">Initiate Migration</Box>
      </Flex>
      <Box
        sx={{
          paddingBottom: '8px',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#333E41',
        }}
      >
        <Box as="span" sx={{ fontWeight: 700 }}>
          {campaignUids.length} campaign(s) selected for migration.
        </Box>{' '}
        Please select a new CNP who will receive the messaging campaign(s).
        <br />
        <br />
        The initiator can cancel a migration at any time before it is complete.
        If migrations are not completed within 30 calendar days, they are
        automatically canceled.
        <br />
        <br />
        <Box as="span" sx={{ fontWeight: 700 }}>
          Note:
        </Box>{' '}
        For Sole Proprietors, if your CNP is not shown in the menu, it means
        they are not enabled to receive Sole Proprietor campaigns. Please reach
        out to your CNP for more information.
        <br />
        <br />
        Campaign ID(s):
        <br />
        <Box as="span" sx={{ fontWeight: 600 }}>
          {campaignUids.join(', ')}
        </Box>
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '12px',
          width: '100%',
          color: '#333E41',
        }}
      >
        <Box as="span" sx={{ fontSize: '10px', lineHeight: '12px' }}>
          *Required Field
        </Box>
        <Dropdown
          required
          disabled={disabled}
          label="Select new Connectivity Partner"
          error={!!error}
          helperText={error}
          options={options}
          value={value}
          onChange={handleChange}
        />
      </Flex>
      <Flex
        sx={{
          columnGap: '18px',
          padding: '30px 0',
        }}
      >
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={disabled} onClick={handleApply}>
          Apply
        </Button>
      </Flex>
    </Modal>
  );
};

export default BulkInitiateMigrationModal;
