import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
  FilterConfig,
  FilterType,
  Table,
  TableFilter,
  useFilters,
  usePermission,
  useTable,
} from 'portal-commons';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  cancelCampaignMigrations,
  downloadCnpCampaignsAsCsv,
  electCampaigns,
  getCnpList,
  getAllUsecasesTypes,
  getPartnerCampaigns,
  initiateCampaignMigrations,
} from '../apis';
import {
  AcceptedCnpCampaignListingRow,
  BulkCancelMigrationModal,
  BulkElectModal,
  BulkInitiateMigrationModal,
} from '../components';
import { EmptyFetchedData } from '../constants';
import { BulkCampaignAction } from '../enums';
import { PartnerCampaign } from '../types';
import { PageTitle } from '../../../../components';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import { toastFlashMessage } from '../../../../utils';
import { generateAndDownloadFile } from '../../../../utils/download';
import { SelectItem, UsecaseType } from '../../../../utils/types';
import { BulkOperationError } from '../errors';

const FilterConfigs: Record<string, FilterConfig> = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  downstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  upstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Upstream CNP',
    placeholder: 'Select upstream CNP',
    options: [],
    width: 260,
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
  outSharingStatus: {
    type: FilterType.Dropdown,
    label: 'Upstream Sharing Status',
    placeholder: 'Select sharing status',
    options: [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Accepted', value: 'ACCEPTED' },
    ],
    width: 230,
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  {
    id: 'downstreamCnpName',
    label: 'DOWNSTREAM CNP',
    sortable: false,
    leftDivider: true,
  },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  {
    id: 'upstreamCnpName',
    label: 'UPSTREAM CNP',
    sortable: false,
    leftDivider: true,
  },
  { id: 'sharedToDate', label: 'SHARED TO ON', sortable: true },
  {
    id: '',
    label: 'CARRIER TERMS',
    sortable: false,
    leftDivider: true,
  },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: 'sharingToStatus', label: 'UPSTREAM SHARING STATUS', sortable: false },
];

const UnprovisionedBulkActionOptions: SelectItem[] = [
  {
    label: 'Initiate Migration',
    value: BulkCampaignAction.InitiateMigration,
  },
];

const ProvisionedBulkActionOptions: SelectItem[] = [
  {
    label: 'Elect',
    value: BulkCampaignAction.Elect,
  },
  {
    label: 'Cancel Migration',
    value: BulkCampaignAction.CancelMigration,
  },
];

const AcceptedCnpCampaigns: FunctionComponent = () => {
  const {
    configs: filterConfigs,
    candidateFilters,
    appliedFilters,
    additionalFilters,
    handleEdit: handleFiltersEdit,
    handleApply: handleFiltersApply,
    setOptions: setFilterOptions,
  } = useFilters({
    configs: FilterConfigs,
  });
  const { hasPermission } = usePermission();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({ type: SET_LOADING_MESSAGE, payload: message });
  };
  const clearLoadingMessage = () => {
    dispatch({ type: CLEAR_LOADING_MESSAGE });
  };
  const [cnpMigrationOptions, setCnpMigrationOptions] = useState<SelectItem[]>(
    []
  );
  const [
    soleProprietorCnpMigrationOptions,
    setSoleProprietorCnpMigrationOptions,
  ] = useState<SelectItem[]>([]);
  const [filtersShown, setFiltersShown] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [errorCampaigns, setErrorCampaigns] = useState<string[]>([]);
  const [bulkInitiateMigrationModal, setBulkInitiateMigrationModal] =
    useState(false);
  const [bulkElectModal, setBulkElectModal] = useState(false);
  const [bulkCancelMigrationModal, setBulkCancelMigrationModal] =
    useState(false);
  const [bulkActionProcessing, setBulkActionProcessing] = useState(false);

  const fetchCampaigns = async () => {
    const queries = getQueries();
    const { provisional } = queries;
    if (provisional !== 'true' && provisional !== 'false') {
      return EmptyFetchedData;
    }

    const response = await getPartnerCampaigns(queries);
    if (response) {
      return {
        data: response.records,
        rowsPerPage: response.recordsPerPage,
        total: response.totalRecords,
        page: response.page,
      };
    }
    return EmptyFetchedData;
  };

  const {
    loading: tableLoading,
    pageLoading,
    total: totalCampaigns,
    handleSorting: handleSortingChange,
    handleChangePage: handlePageChange,
    tableData: campaigns,
    pagination,
    filter: tableFilters,
    reload,
  } = useTable<PartnerCampaign>(fetchCampaigns, {
    cappingTotal: 10000,
    deps: [appliedFilters, additionalFilters],
    defaultFilter: {
      sortField: 'sharedOnDate',
      ascendingOrder: false,
    },
  });

  const bulkActionOptions = useMemo(() => {
    const { provisional } = appliedFilters;
    const options =
      provisional === 'true'
        ? ProvisionedBulkActionOptions
        : provisional === 'false'
        ? UnprovisionedBulkActionOptions
        : [];
    return options.map((option) => ({
      ...option,
      disabled: !hasPermission(`cnpCampaignList.${option.value}`),
    }));
  }, [appliedFilters.provisional, hasPermission]);

  const soleProprietorCampaignsSelected = useMemo(
    () =>
      campaigns
        .filter((campaign) => selectedCampaigns.includes(campaign.uid))
        .some((campaign) => campaign.usecase === 'SOLE_PROPRIETOR'),
    [campaigns, selectedCampaigns]
  );

  useEffect(() => {
    fetchFilterOptions();
    fetchCnpOptions();
  }, []);

  useEffect(() => {
    setBulkAction('');
    const { provisional } = appliedFilters;
    if (provisional !== 'true' && provisional !== 'false') {
      handleFiltersApply({
        ...appliedFilters,
        provisional: 'false',
      });
    }
  }, [appliedFilters.provisional]);

  useEffect(() => {
    setErrorCampaigns([]);
  }, [bulkAction]);

  useEffect(() => {
    setSelectedCampaigns([]);
    setErrorCampaigns([]);
  }, [campaigns]);

  useEffect(() => {
    const newErrorCampaigns = errorCampaigns.filter((campaign) =>
      selectedCampaigns.includes(campaign)
    );
    setErrorCampaigns(newErrorCampaigns);
  }, [selectedCampaigns]);

  const getQueries = () => {
    return {
      sharingStatus: 'ACCEPTED',
      outSharingStatus: 'PENDING,ACCEPTED',
      actionableOnly: 'false',
      ...tableFilters,
      ...appliedFilters,
      ...additionalFilters,
    } as Record<string, any>;
  };

  const fetchFilterOptions = async () => {
    const [usecaseTypes, cnpList] = await Promise.all([
      getAllUsecasesTypes(),
      getCnpList(),
    ]);

    const usecaseTypeOptions: SelectItem[] = usecaseTypes.map(
      (item: UsecaseType) => ({
        label: item.displayName,
        value: item.id,
      })
    );
    const updatedCnpOptions: SelectItem[] = cnpList.map((item) => ({
      label: item.displayName,
      value: item.uid,
    }));

    setFilterOptions('usecase', usecaseTypeOptions);
    setFilterOptions('downstreamCnpUid', updatedCnpOptions);
    setFilterOptions('upstreamCnpUid', updatedCnpOptions);
  };

  const fetchCnpOptions = async () => {
    const [cnpMigrationList, soleProprietorCnpMigrationList] =
      await Promise.all([
        getCnpList({ cnpMigrationSupported: true }),
        getCnpList({
          cnpMigrationSupported: true,
          soleProprietorEnabled: true,
        }),
      ]);
    setCnpMigrationOptions(
      cnpMigrationList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setSoleProprietorCnpMigrationOptions(
      soleProprietorCnpMigrationList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    const queries = getQueries();
    if ('page' in queries) {
      delete queries.page;
    }
    const response = await downloadCnpCampaignsAsCsv(queries);
    if (response) {
      const file = new File([response], 'cnp-campaigns.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  const handleBulkActionApply = () => {
    if (bulkAction === BulkCampaignAction.InitiateMigration) {
      setBulkInitiateMigrationModal(true);
    } else if (bulkAction === BulkCampaignAction.Elect) {
      setBulkElectModal(true);
    } else if (bulkAction === BulkCampaignAction.CancelMigration) {
      setBulkCancelMigrationModal(true);
    }
  };

  const handleBulkElect = async (upstreamCnpUid: string) => {
    try {
      const response = await electCampaigns(upstreamCnpUid, selectedCampaigns);
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) elected`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot elect campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  const handleBulkInitiateMigration = async (upstreamCnpUid: string) => {
    try {
      const response = await initiateCampaignMigrations(
        upstreamCnpUid,
        selectedCampaigns
      );
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) migrated`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot migrate campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  const handleBulkCancelMigration = async (explanation?: string) => {
    try {
      const response = await cancelCampaignMigrations(
        selectedCampaigns,
        explanation
      );
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign migration(s) canceled`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot cancel migration for campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  return (
    <>
      <Box
        as="section"
        sx={{
          // Fix dropdown width affected by global styling
          '& .MuiAutocomplete-root': {
            width: 'unset',
          },
        }}
        data-testid="campaignListing"
      >
        {pageLoading ? (
          <Loader />
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: '12px',
            }}
          >
            <PageTitle>
              {totalCampaigns > 0
                ? `${totalCampaigns} Accepted Connectivity Partner Campaigns `
                : 'Accepted Connectivity Partner Campaigns'}
            </PageTitle>
            <Flex
              sx={{
                alignItems: 'center',
                columnGap: '12px',
                width: '100%',
                marginTop: '-8px',
              }}
            >
              <Button
                color="secondary"
                onClick={() => setFiltersShown(!filtersShown)}
                data-testid="tableAddFilterButton"
              >
                <FontAwesomeIcon icon={faFilter} />
                <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
              </Button>
              <Button
                variant="outline"
                color="secondary"
                onClick={handleDownload}
                data-testid="tableDownloadButton"
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
            </Flex>
            {filtersShown && (
              <TableFilter
                configs={filterConfigs}
                candidateValues={candidateFilters}
                appliedValues={appliedFilters}
                onCandidateValuesChange={handleFiltersEdit}
                onAppliedValuesChange={handleFiltersApply}
              />
            )}
            <Flex sx={{ alignItems: 'center', columnGap: '10px' }}>
              <Dropdown
                size="small"
                label="Bulk Actions"
                options={bulkActionOptions}
                value={bulkAction}
                onChange={(value) => {
                  setBulkAction(value);
                }}
              />
              <Button
                disabled={selectedCampaigns.length < 1 || !bulkAction}
                size="small"
                onClick={handleBulkActionApply}
              >
                Apply
              </Button>
              <Box as="span">{selectedCampaigns.length} selected</Box>
            </Flex>
            <Table
              testId="campaignsListingListingTableNew"
              selectable
              loading={tableLoading}
              emptyState="no campaigns to view"
              handleChangePage={handlePageChange}
              createSortHandler={handleSortingChange}
              filter={tableFilters}
              headRows={headRows}
              data={campaigns as Record<string, any>[]}
              rowKey="uid"
              selectedRows={selectedCampaigns}
              onSelectChange={(value) => {
                setSelectedCampaigns(value);
              }}
              renderRow={(data, attributes) => {
                const campaign = data as unknown as PartnerCampaign;
                return (
                  <AcceptedCnpCampaignListingRow
                    key={campaign.uid}
                    selectable={attributes?.selectable}
                    selected={attributes?.selected}
                    error={errorCampaigns.includes(campaign.uid)}
                    campaign={campaign}
                    onChange={attributes?.onSelectedChange}
                  />
                );
              }}
              pagination={pagination}
            />
          </Flex>
        )}
      </Box>
      <BulkInitiateMigrationModal
        open={bulkInitiateMigrationModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        options={
          soleProprietorCampaignsSelected
            ? soleProprietorCnpMigrationOptions
            : cnpMigrationOptions
        }
        onClose={() => {
          setBulkInitiateMigrationModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkInitiateMigration(value);
          setBulkActionProcessing(false);
          setBulkInitiateMigrationModal(false);
        }}
      />
      <BulkElectModal
        open={bulkElectModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        options={
          soleProprietorCampaignsSelected
            ? soleProprietorCnpMigrationOptions
            : cnpMigrationOptions
        }
        onClose={() => {
          setBulkElectModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkElect(value);
          setBulkActionProcessing(false);
          setBulkElectModal(false);
        }}
      />
      <BulkCancelMigrationModal
        open={bulkCancelMigrationModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        onClose={() => {
          setBulkCancelMigrationModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkCancelMigration(value);
          setBulkActionProcessing(false);
          setBulkCancelMigrationModal(false);
        }}
      />
    </>
  );
};

export default AcceptedCnpCampaigns;
