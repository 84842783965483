import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';

interface PageTitleProps {
  note?: string;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({ note, children }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        columnGap: '8px',
        fontWeight: 800,
        fontSize: '27px',
        lineHeight: '32px',
        color: '#333e41',
      }}
    >
      <Box as="span">{children}</Box>
      {note && (
        <Box
          as="span"
          sx={{
            alignSelf: 'flex-end',
            marginBottom: '4px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#666E71',
          }}
        >
          {note}
        </Box>
      )}
    </Flex>
  );
};

export default PageTitle;
