import { Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

interface ModalProps {
  open?: boolean;
  testId?: string;
}

const useStyles = makeStyles({
  container: {
    marginLeft: '170px',
  },
  content: {
    width: '526px',
    padding: '28px 30px 9px 30px !important',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
});

const Modal: FunctionComponent<ModalProps> = ({
  open = false,
  testId,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ container: classes.container }}
      open={open}
      data-testid={testId}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '8px',
            background: '#FFFFFF',
          }}
        >
          {children}
        </Flex>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
