import React, { Fragment, useState, useEffect } from 'react';
import { S3_ASSETS_PATH, mnoNameOrder } from '../../../../constants';
import { sortMnoNestedObjectToList } from '../../../../utils';
import CheckBox from '../../../../ui_elements/CheckBox';
import { Grid, FormControlLabel } from '@material-ui/core';
import { CustomTooltip } from '../../../../shared_elements';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faShareNodes,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';

export default function MnoPreviewNew(props) {
  const { type, mnoInfo, campaignInfo, error } = props;
  const [sortedMnoList, setSortedMnoList] = useState([]);

  useEffect(() => {
    if (mnoInfo && mnoInfo.mnoMetadata) {
      const newSortedMnoList = sortMnoNestedObjectToList(mnoInfo.mnoMetadata);
      setSortedMnoList(newSortedMnoList);
    }
  }, [mnoInfo.mnoMetadata]);

  const handleCheckBoxClick = (event, mnoId) => {
    const checked = event.currentTarget.checked;
    if (mnoId != undefined) {
      const newMnoIds = checked
        ? [...campaignInfo.mnoIds, mnoId]
        : campaignInfo.mnoIds.filter((id) => id !== mnoId);
      props.handleChange(newMnoIds, 'mnoIds', 'campaign');
    }
  };

  return (
    <Fragment>
      {type == 'preview' ? (
        <>
          <Grid item xs={12} data-testid="mnoPreviewNew">
            <div className="mno-preview wrapper mno-select-wrapper">
              <div className="heading-block">
                <Box flexDirection="row" alignItems="center">
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faShareNodes} size="xl" />
                  </Box>
                  <h3 className="heading1">Carrier Terms Preview</h3>
                </Box>
              </div>
              <div className="mno-overview">
                <p className="paragraph title">
                  The below list shows campaign qualification results and terms
                  for each MNO.
                </p>
                <ul className="mno-listing list-unstyled">
                  {mnoInfo.mnoMetadata
                    ? sortedMnoList.map((mnoObject, index) => (
                        <li key={index}>
                          <FormControlLabel
                            control={
                              <CheckBox
                                checked={campaignInfo.mnoIds.includes(
                                  mnoObject.mnoMetadataKey
                                )}
                                handleCheckbox={(e) =>
                                  handleCheckBoxClick(
                                    e,
                                    mnoObject.mnoMetadataKey
                                  )
                                }
                                type="mno-preview"
                              />
                            }
                            labelPlacement="end"
                            className="mno-select"
                            disabled={mnoObject.qualify ? false : true}
                          />
                          <Grid container>
                            <Grid item xs={2} className="name">
                              {mnoObject.mno}
                            </Grid>
                            <Grid item xs={10}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={2}
                                  className="text-center"
                                  data-testid="mnoPreiewNewQualify"
                                >
                                  <h3 className="paragraph bold">
                                    <Box
                                      flexDirection="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      padding={{ top: 'xs' }}
                                    >
                                      <Box margin={{ right: 'xxs' }}>
                                        <FontAwesomeIcon
                                          icon={faAward}
                                          size="xl"
                                        />
                                      </Box>
                                      <span>QUALIFY</span>
                                    </Box>
                                  </h3>
                                  <p className="paragraph light">
                                    {mnoObject.qualify ? (
                                      'Yes'
                                    ) : (
                                      <img
                                        style={{
                                          position: 'relative',
                                          bottom: '5px',
                                        }}
                                        src={`${S3_ASSETS_PATH}/images/not-qualify.svg`}
                                      />
                                    )}
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  data-testid="mnoPreiewNewMnoReview"
                                >
                                  <h3 className="paragraph bold">
                                    <Box
                                      flexDirection="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      padding={{ top: 'xs' }}
                                    >
                                      <Box margin={{ right: 'xxs' }}>
                                        <FontAwesomeIcon
                                          icon={faThumbsUp}
                                          size="xl"
                                        />
                                      </Box>
                                      <span>MNO REVIEW</span>
                                    </Box>
                                  </h3>
                                  <p className="paragraph light">
                                    {mnoObject.mnoReview ? 'Yes' : 'No'}
                                  </p>
                                </Grid>
                                {mnoObject.mno == 'T-Mobile' ? (
                                  <>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '17%' }}
                                      data-testid="mnoPreiewNewBrandTier"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ marginLeft: 5, paddingTop: 5 }}
                                      >
                                        BRAND
                                        <br />
                                        TIER
                                      </h3>
                                      {
                                        <CustomTooltip
                                          arrow
                                          title={`“Please check the Knowledge Base for the ”Daily and Monthly maximum throughput values per Brand” associated with this tier.`}
                                          placement="top"
                                        >
                                          <p
                                            className="paragraph light"
                                            style={{ marginLeft: 5 }}
                                          >
                                            {mnoObject.brandTier
                                              ? mnoObject.brandTier
                                              : 'N/A'}
                                          </p>
                                        </CustomTooltip>
                                      }
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '14%' }}
                                      data-testid="mnoPreiewNewBrandDailyCap"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ paddingTop: 5 }}
                                      >
                                        BRAND
                                        <br />
                                        DAILY CAP
                                      </h3>
                                      {mnoObject.mno == 'T-Mobile' ? (
                                        <p className="paragraph light">
                                          {mnoObject.brandDailyCap
                                            ? mnoObject.brandDailyCap
                                            : mnoObject &&
                                              mnoObject.brandTier &&
                                              mnoObject.brandTier === 'UNCAPPED'
                                            ? 'No'
                                            : 'N/A'}
                                        </p>
                                      ) : (
                                        <p className="paragraph light">
                                          {mnoObject.tpmScope
                                            ? mnoObject.tpmScope
                                            : 'N/A'}
                                        </p>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '10%' }}
                                      data-testid="mnoPreiewNewMnsTpm"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ paddingTop: 5 }}
                                      >
                                        MMS
                                        <br />
                                        TPM
                                      </h3>
                                      {mnoObject.mno == 'T-Mobile' &&
                                      mnoObject.mmsTpm ? (
                                        <CustomTooltip
                                          arrow
                                          title={`“Please check the Knowledge Base for the ”Daily and Monthly maximum throughput values per Brand” associated with this tier.`}
                                          placement="top"
                                        >
                                          <p className="paragraph light">
                                            {mnoObject.mmsTpm
                                              ? mnoObject.mmsTpm
                                              : 'N/A'}
                                          </p>
                                        </CustomTooltip>
                                      ) : (
                                        <p className="paragraph light">
                                          {mnoObject.mmsTpm
                                            ? mnoObject.mmsTpm
                                            : 'N/A'}
                                        </p>
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '17%' }}
                                      data-testid="mnoPreiewNewTpmScope"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ marginLeft: 5, paddingTop: 5 }}
                                      >
                                        TPM
                                        <br />
                                        SCOPE
                                      </h3>
                                      {mnoObject.mno == 'T-Mobile' &&
                                      mnoObject.brandDailyCap ? (
                                        <p
                                          className="paragraph light"
                                          style={{ marginLeft: 5 }}
                                        >
                                          {mnoObject.brandDailyCap}
                                        </p>
                                      ) : (
                                        <p
                                          className="paragraph light"
                                          style={{
                                            marginLeft: 5,
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                            wordBreak: 'keep-all',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                          title={mnoObject.tpmScope || 'N/A'}
                                        >
                                          {mnoObject.tpmScope
                                            ? mnoObject.tpmScope
                                            : 'N/A'}
                                        </p>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '14%' }}
                                      data-testid="mnoPreiewNewSmsTpm"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ paddingTop: 5 }}
                                      >
                                        SMS
                                        <br />
                                        TPM
                                      </h3>
                                      {mnoObject.mno == 'AT&T' ? (
                                        <p className="paragraph light">
                                          {mnoObject.tpm
                                            ? mnoObject.tpm
                                            : 'N/A'}
                                        </p>
                                      ) : (
                                        <p className="paragraph light">
                                          {mnoObject.tpm
                                            ? mnoObject.tpm
                                            : 'N/A'}
                                        </p>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{ maxWidth: '10%' }}
                                      data-testid="mnoPreiewNewMmsTpm"
                                    >
                                      <h3
                                        className="paragraph bold tooltip"
                                        style={{ paddingTop: 5 }}
                                      >
                                        MMS
                                        <br />
                                        TPM
                                      </h3>
                                      {
                                        <p className="paragraph light">
                                          {mnoObject.mmsTpm
                                            ? mnoObject.mmsTpm
                                            : 'N/A'}
                                        </p>
                                      }
                                    </Grid>
                                  </>
                                )}
                                <Grid
                                  item
                                  xs={2}
                                  data-testid="mnoPreiewNewMessageClass"
                                >
                                  <h3
                                    className="paragraph bold"
                                    style={{ paddingTop: 5 }}
                                  >
                                    MESSAGE
                                    <br />
                                    CLASS
                                  </h3>
                                  <p className="paragraph light">
                                    {mnoObject.msgClass
                                      ? mnoObject.msgClass
                                      : 'N/A'}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </li>
                      ))
                    : null}
                </ul>
                <br />
                {error && error.mnoIds ? (
                  <p className="error-msg-brand-relationship">{error.mnoIds}</p>
                ) : null}
              </div>
            </div>
          </Grid>
        </>
      ) : null}
    </Fragment>
  );
}
