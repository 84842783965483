import { AttachmentInfo, BoxV2 as Box, Button, Flex } from 'portal-commons';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AssetsUpload from '../AssetsUpload';
import { toastFlashMessage } from '../../../../../utils';

const LogoUpload: React.FC = () => {
  const { id: brandId } = useParams<{ id: string }>();
  const location = useLocation<{ goBackPage?: string }>();
  const history = useHistory();
  const [files, setFiles] = useState<AttachmentInfo[]>([]);
  const goBackPage = location.state?.goBackPage || `/brands`;

  const handleUpload = () => {
    // TODO: Implement upload logic
    toastFlashMessage(
      `${files.length} logo(s) successfully uploaded`,
      'success'
    );
    onClose();
  };

  const onClose = () => {
    const backPath = `/brands/${brandId}`;
    history.push({
      pathname: backPath,
      state: { goBackPage },
    });
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          p: 'xxl',
          pt: 'xl',
          mt: 'l',
          mb: 30,
          border: '1px solid #C0CCD4',
          flexBasis: '100%',
          flexGrow: 0,
        }}
        data-testid="brandLogoUpload"
      >
        <Flex
          as="h3"
          sx={{
            pb: 'xs',
            alignItems: 'center',
            borderBottom: '1px solid #C0CCD4',
            fontSize: 'H500',
            color: 't.black70',
            fontWeight: 600,
            lineHeight: 'xxl',
            '& svg': {
              fontSize: 'H600',
              mr: 'xs',
            },
          }}
        >
          <FontAwesomeIcon icon={faFileImage} />
          Logo Upload
        </Flex>
        <Box
          sx={{
            mt: 'xs',
            mb: 's',
            fontSize: 'H300',
            color: 't.black70',
            fontWeight: 400,
          }}
        >
          Upload the official logo of your brand to use on RBM.
        </Box>
        <AssetsUpload
          assetsType="Logo"
          dimensions={{ width: 224, height: 224 }}
          fileLimitKB={50}
          files={files}
          onChange={setFiles}
        />
      </Box>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mt: 'xl',
          gap: 's',
        }}
      >
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={files.length < 1} onClick={handleUpload}>
          Upload
        </Button>
      </Flex>
    </>
  );
};

export default LogoUpload;
