import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { getCampaignOperationStatusApi } from '../apiServices';
import { S3_ASSETS_PATH } from '../../../../constants';
import { sortMnoNestedObjectToList } from '../../../../utils';
import '../../../../assets/styles/campaign-register-module.scss';
import { CustomTooltip, GeneralAlertPopup } from '../../../../shared_elements';
import parse from 'html-react-parser';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faThumbsUp,
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';

export default class CampaignRegisterSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mnoMetadata: '',
      loader: false,
      mnoStatusInfo: '',
      showBanner: true,
      genAlertFlag: true,
      nextStepData: '',
    };
    this.getCampaignOperationStatusApi =
      getCampaignOperationStatusApi.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { mnoMetadata, campaignUid, cspData } = this.props.location.state;
      const nextStepData = cspData.find(
        (item) =>
          item.lookup_key == 'csp-campaign-register-form#success-next-steps'
      );
      this.setState({
        mnoMetadata,
        campaignUid,
        nextStepData,
      });
      this.getCampaignOperationStatusApi(campaignUid);
    } else {
      this.props.history.push('/dashboard');
    }
  }

  getStatus = (id) => {
    const { mnoStatusInfo } = this.state;
    const status =
      mnoStatusInfo && mnoStatusInfo.find((item) => item.mnoId == id)
        ? mnoStatusInfo.find((item) => item.mnoId == id).status
        : '';
    return status;
  };

  render() {
    const { mnoMetadata, showBanner, genAlertFlag, campaignUid, nextStepData } =
      this.state;

    let sortedMnoList = [];
    if (mnoMetadata) {
      sortedMnoList = sortMnoNestedObjectToList(mnoMetadata);
    }

    return (
      <section
        className="campaign-register-section"
        data-testid="campaignRegisterSuccess"
      >
        <Container
          maxWidth={false}
          className="campaign-register-container success"
        >
          <Grid container className="">
            {showBanner && (
              <Grid item xs={12}>
                <div className="success-note">
                  <img
                    src={`${S3_ASSETS_PATH}/images/campaign-reg-success.svg`}
                  />
                  <h3 className="heading1">Congratulations!</h3>
                  <p className="heading1">
                    You have a successfully registered the campaign
                  </p>
                  <img
                    data-testid="campaignRegisterSuccessCloseButton"
                    className="close"
                    src={`${S3_ASSETS_PATH}/images/success-close.svg`}
                    onClick={() => this.setState({ showBanner: false })}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                className="success-title"
                data-testid="campaignRegisterSuccessCampaignId"
              >
                <h3 className="heading1 text-center">
                  Campaign ID: {campaignUid}
                </h3>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className="mno-preview wrapper"
                data-testid="campaignRegisterSuccessCarrierStatus"
              >
                <div className="heading-block">
                  <Box flexDirection="row" alignItems="center">
                    <Box margin={{ right: 'xs' }}>
                      <FontAwesomeIcon icon={faShareNodes} size="xl" />
                    </Box>
                    <h3 className="heading1">Carrier Status</h3>
                  </Box>
                </div>
                <div className="mno-overview">
                  <ul className="mno-listing list-unstyled">
                    {mnoMetadata
                      ? sortedMnoList.map((mnoObject, index) => (
                          <li key={index}>
                            <Grid container>
                              <Grid
                                item
                                xs={2}
                                className="name"
                                data-testid={`mnoName-${mnoObject.mno}`}
                              >
                                {mnoObject.mno}
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container direction="row">
                                  <Grid
                                    item
                                    xs={5}
                                    className="text-center"
                                    data-testid="mnoQualify"
                                  >
                                    <h3 className="paragraph bold">
                                      <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Box margin={{ right: 'xxs' }}>
                                          <FontAwesomeIcon
                                            icon={faAward}
                                            size="xl"
                                          />
                                        </Box>
                                        <span>QUALIFY</span>
                                      </Box>
                                    </h3>
                                    <p className="paragraph bold">
                                      {mnoObject.qualify ? (
                                        'Yes'
                                      ) : (
                                        <img
                                          style={{
                                            position: 'relative',
                                            bottom: '5px',
                                          }}
                                          src={`${S3_ASSETS_PATH}/images/not-qualify.svg`}
                                        />
                                      )}
                                    </p>
                                  </Grid>
                                  <Grid item xs={7} data-testid="mnoReview">
                                    <h3 className="paragraph bold">
                                      <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Box margin={{ right: 'xxs' }}>
                                          <FontAwesomeIcon
                                            icon={faThumbsUp}
                                            size="xl"
                                          />
                                        </Box>
                                        <span>MNO REVIEW</span>
                                      </Box>
                                    </h3>
                                    <p className="paragraph bold">
                                      {mnoObject.mnoReview ? 'Yes' : 'No'}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* <Grid item xs={2} style={{maxWidth: "13%"}}>
                                                        <h3 className="paragraph light tooltip" style={{marginLeft: 5}}>{"SURCHARGE"}</h3>
                                                        <p className="paragraph light" style={{marginLeft: 5}}>{mnoObject.surcharge ? mnoObject.surcharge : 'N/A'}</p>
                                                    </Grid> */}
                              {mnoObject.mno == 'T-Mobile' ? (
                                <>
                                  <Grid item xs={1} data-testid="brandTier">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: 5,
                                      }}
                                    >
                                      BRAND
                                      <br />
                                      TIER
                                    </h3>
                                    {
                                      <CustomTooltip
                                        arrow
                                        title={`Please check the Knowledge Base for the ”Daily and Monthly maximum throughput values per Brand” associated with this tier.`}
                                        placement="top"
                                      >
                                        <p
                                          className="paragraph light"
                                          style={{
                                            marginLeft: 5,
                                            paddingLeft: '16px',
                                          }}
                                        >
                                          {mnoObject.brandTier
                                            ? mnoObject.brandTier
                                            : 'N/A'}
                                        </p>
                                      </CustomTooltip>
                                    }
                                  </Grid>
                                  <Grid item xs={1} data-testid="brandDailyCap">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      BRAND
                                      <br />
                                      DAILY CAP
                                    </h3>
                                    {
                                      <p className="paragraph light">
                                        {mnoObject.brandDailyCap
                                          ? mnoObject.brandDailyCap
                                          : mnoObject.brandTier &&
                                            mnoObject.brandTier === 'UNCAPPED'
                                          ? 'No'
                                          : 'N/A'}
                                      </p>
                                    }
                                  </Grid>
                                  <Grid item xs={1} data-testid="MmsTpm">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      MMS
                                      <br />
                                      TPM
                                    </h3>
                                    {
                                      <p className="paragraph light">
                                        {mnoObject.mmsTpm
                                          ? mnoObject.mmsTpm
                                          : 'N/A'}
                                      </p>
                                    }
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item xs={1} data-testid="TpmScope">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: 5,
                                      }}
                                    >
                                      TPM
                                      <br />
                                      SCOPE
                                    </h3>
                                    {
                                      <p
                                        className="paragraph light"
                                        style={{
                                          marginLeft: 5,
                                          paddingLeft: '16px',
                                        }}
                                      >
                                        {mnoObject.tpmScope
                                          ? mnoObject.tpmScope
                                          : 'N/A'}
                                      </p>
                                    }
                                  </Grid>
                                  <Grid item xs={1} data-testid="smsTpm">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      SMS
                                      <br />
                                      TPM
                                    </h3>
                                    {mnoObject.mno == 'AT&T' ? (
                                      <p className="paragraph light">
                                        {mnoObject.tpm ? mnoObject.tpm : 'N/A'}
                                      </p>
                                    ) : (
                                      <p className="paragraph light">
                                        {mnoObject.tpm ? mnoObject.tpm : 'N/A'}
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item xs={1} data-testid="mmsTpm">
                                    <h3
                                      className="paragraph light tooltip"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      MMS
                                      <br />
                                      TPM
                                    </h3>
                                    {
                                      <p className="paragraph light">
                                        {mnoObject.mmsTpm
                                          ? mnoObject.mmsTpm
                                          : 'N/A'}
                                      </p>
                                    }
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={2} data-testid="messageClass">
                                <h3
                                  className="paragraph light"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  MESSAGE
                                  <br />
                                  CLASS
                                </h3>
                                <p className="paragraph light">
                                  {mnoObject.msgClass
                                    ? mnoObject.msgClass
                                    : 'N/A'}
                                </p>
                              </Grid>
                              <Grid item xs={2} data-testid="status">
                                <h3 className="paragraph light">STATUS</h3>
                                <p className="paragraph bold status">
                                  {this.getStatus(mnoObject.mnoMetadataKey) ? (
                                    <img
                                      src={`${S3_ASSETS_PATH}/images/${this.getStatus(
                                        mnoObject.mnoMetadataKey
                                      ).toLowerCase()}-icon.svg`}
                                    />
                                  ) : null}
                                  <span>
                                    {this.getStatus(mnoObject.mnoMetadataKey)
                                      ? this.getStatus(
                                          mnoObject.mnoMetadataKey
                                        ).toLowerCase() === 'approved'
                                        ? 'REGISTERED'
                                        : this.getStatus(
                                            mnoObject.mnoMetadataKey
                                          )
                                      : '--'}
                                  </span>
                                </p>
                              </Grid>
                            </Grid>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="next-steps" data-testid="nextStep">
                <img src={`${S3_ASSETS_PATH}/images/next-arrow.svg`} />
                <div className="content">
                  <h3 className="paragraph">
                    {nextStepData ? nextStepData.title : 'Next Steps'}
                  </h3>
                  <div className="paragraph">
                    {parse(nextStepData ? nextStepData.text : '')}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="bottom-blk">
              <ul className="list-inline">
                <li className="form-group-field">
                  <input
                    data-testid="continueButton"
                    type="submit"
                    onClick={(e) => {
                      this.props.history.push(`/campaigns/${campaignUid}`);
                    }}
                    value={'Continue'}
                  />
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
        {
          <GeneralAlertPopup
            open={genAlertFlag}
            handleClose={() => this.setState({ genAlertFlag: false })}
          />
        }
      </section>
    );
  }
}
