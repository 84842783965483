import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
  RoleGuard,
  Table,
  FilterConfig,
  FilterType,
  TableFilter,
  useFilters,
  usePermission,
  useTable,
} from 'portal-commons';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  cancelCampaignMigrations,
  downloadCampaignsAsCsv,
  electCampaigns,
  fetchBrandSuggestions,
  fetchResellerSuggestions,
  getAllUsecasesTypes,
  getCampaigns,
  getCnpList,
  initiateCampaignMigrations,
} from '../apis';
import {
  BulkCancelMigrationModal,
  BulkElectModal,
  BulkInitiateMigrationModal,
  DownloadLimitedModal,
  MyCampaignListingRow,
} from '../components';
import { EmptyFetchedData } from '../constants';
import { BulkCampaignAction } from '../enums';
import { BulkOperationError } from '../errors';
import { CspCampaign } from '../types';
import { CampaignFilterErrorCode as FilterErrorCode } from '../../index';
import { PageTitle } from '../../../../components';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import { toastFlashMessage } from '../../../../utils';
import { generateAndDownloadFile } from '../../../../utils/download';
import { SelectItem, UsecaseType } from '../../../../utils/types';
import { fieldValidation } from '../../../../utils/validator';

const fetchBrandNameSuggestions = async (query = {}) => {
  const suggestions = await fetchBrandSuggestions(query);
  return suggestions.map((suggestion) => suggestion.brandName);
};

const fetchResellerNameSuggestions = async (query = {}) => {
  const suggestions = await fetchResellerSuggestions(query);
  return suggestions.map((suggestion) => suggestion.companyName);
};

const FilterConfigs: Record<string, FilterConfig> = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  upstreamCnpUid: {
    type: FilterType.Dropdown,
    label: 'Upstream CNP',
    placeholder: 'Select upstream CNP',
    options: [],
    width: 260,
  },
  resellerName: {
    type: FilterType.Text,
    label: 'Reseller Name',
    placeholder: 'Enter reseller name',
    suggestions: [],
    width: 260,
  },
  referenceId: {
    type: FilterType.Text,
    label: 'Reference ID',
    placeholder: 'Enter reference id',
    width: 150,
    error: false,
    helperText: '',
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
};

const FilterLoaders = {
  suggestion: {
    brandName: {
      minLength: 2,
      load: (value: string) => fetchBrandNameSuggestions({ prefix: value }),
    },
    resellerName: {
      minLength: 2,
      load: (value: string) =>
        fetchResellerNameSuggestions({ companyNamePrefix: value }),
    },
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'upstreamCnpName', label: 'UPSTREAM CNP', sortable: false },
  { id: 'resellerName', label: 'RESELLER NAME', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: true },
];

const UnprovisionedBulkActionOptions: SelectItem[] = [
  {
    label: 'Elect',
    value: BulkCampaignAction.Elect,
  },
  {
    label: 'Initiate Migration',
    value: BulkCampaignAction.InitiateMigration,
  },
];

const ProvisionedBulkActionOptions: SelectItem[] = [
  {
    label: 'Elect',
    value: BulkCampaignAction.Elect,
  },
  {
    label: 'Cancel Migration',
    value: BulkCampaignAction.CancelMigration,
  },
];

export interface ErrorCodeType {
  [name: string]: {
    [key: number | string]: string | boolean | number;
  };
}

const MyCampaigns: FunctionComponent = () => {
  const {
    configs: filterConfigs,
    candidateFilters,
    appliedFilters,
    additionalFilters,
    handleEdit: handleFiltersEdit,
    handleApply: handleFiltersApply,
    setOptions: setFilterOptions,
    updateConfigObject: updateFilterConfigs,
  } = useFilters({
    configs: FilterConfigs,
    loaders: FilterLoaders,
  });
  const { hasPermission } = usePermission();
  const history = useHistory();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({ type: SET_LOADING_MESSAGE, payload: message });
  };
  const clearLoadingMessage = () => {
    dispatch({ type: CLEAR_LOADING_MESSAGE });
  };
  const [filtersShown, setFiltersShown] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [errorCampaigns, setErrorCampaigns] = useState<string[]>([]);
  const [cnpOptions, setCnpOptions] = useState<SelectItem[]>([]);
  const [soleProprietorCnpOptions, setSoleProprietorCnpOptions] = useState<
    SelectItem[]
  >([]);
  const [cnpMigrationOptions, setCnpMigrationOptions] = useState<SelectItem[]>(
    []
  );
  const [
    soleProprietorCnpMigrationOptions,
    setSoleProprietorCnpMigrationOptions,
  ] = useState<SelectItem[]>([]);
  const [downloadLimitedModal, setDownloadLimitedModal] = useState(false);
  const [bulkElectModal, setBulkElectModal] = useState(false);
  const [bulkInitiateMigrationModal, setBulkInitiateMigrationModal] =
    useState(false);
  const [bulkCancelMigrationModal, setBulkCancelMigrationModel] =
    useState(false);
  const [bulkActionProcessing, setBulkActionProcessing] = useState(false);

  const fetchCampaigns = async () => {
    const queries = getQueries();
    const { provisional } = queries;
    if (provisional !== 'true' && provisional !== 'false') {
      return EmptyFetchedData;
    }

    const response = await getCampaigns(queries);
    if (response) {
      return {
        data: response.records,
        rowsPerPage: response.recordsPerPage,
        total: response.totalRecords,
        page: response.page,
      };
    }
    return EmptyFetchedData;
  };

  const {
    loading: tableLoading,
    pageLoading,
    total: totalCampaigns,
    handleSorting: handleSortingChange,
    handleChangePage: handlePageChange,
    tableData: campaigns,
    pagination,
    filter: tableFilters,
    reload,
  } = useTable<CspCampaign>(fetchCampaigns, {
    cappingTotal: 10000,
    deps: [appliedFilters, additionalFilters],
    defaultFilter: {
      sortField: 'createDate',
      ascendingOrder: false,
    },
  });

  const bulkActionOptions = useMemo(() => {
    const { provisional } = appliedFilters;
    const options =
      provisional === 'true'
        ? ProvisionedBulkActionOptions
        : provisional === 'false'
        ? UnprovisionedBulkActionOptions
        : [];
    return options.map((option) => ({
      ...option,
      disabled: !hasPermission(`cnpCampaignList.${option.value}`),
    }));
  }, [appliedFilters.provisional, hasPermission]);

  const soleProprietorCampaignsSelected = useMemo(
    () =>
      campaigns
        .filter((campaign) => selectedCampaigns.includes(campaign.uid))
        .some((campaign) => campaign.usecase === 'SOLE_PROPRIETOR'),
    [campaigns, selectedCampaigns]
  );

  useEffect(() => {
    fetchFilterOptions();
    fetchCnpOptions();
  }, []);

  useEffect(() => {
    setBulkAction('');
    const { provisional } = appliedFilters;
    if (provisional !== 'true' && provisional !== 'false') {
      handleFiltersApply({
        ...appliedFilters,
        provisional: 'false',
      });
    }
  }, [appliedFilters.provisional]);

  useEffect(() => {
    setErrorCampaigns([]);
  }, [bulkAction]);

  useEffect(() => {
    setSelectedCampaigns([]);
    setErrorCampaigns([]);
  }, [campaigns]);

  useEffect(() => {
    const newErrorCampaigns = errorCampaigns.filter((campaign) =>
      selectedCampaigns.includes(campaign)
    );
    setErrorCampaigns(newErrorCampaigns);
  }, [selectedCampaigns]);

  const getQueries = () => {
    return {
      ...tableFilters,
      ...appliedFilters,
      ...additionalFilters,
    } as Record<string, any>;
  };

  const fetchFilterOptions = async () => {
    const [usecaseTypes, cnpList] = await Promise.all([
      getAllUsecasesTypes(),
      getCnpList(),
    ]);

    const usecaseOptions: SelectItem[] = usecaseTypes.map(
      (item: UsecaseType) => ({
        label: item.displayName,
        value: item.id,
      })
    );
    const cnpOptions: SelectItem[] = cnpList.map((item) => ({
      label: item.displayName,
      value: item.uid,
    }));

    setFilterOptions('usecase', usecaseOptions);
    setFilterOptions('upstreamCnpUid', cnpOptions);
  };

  const fetchCnpOptions = async () => {
    const [
      cnpList,
      soleProprietorCnpList,
      cnpMigrationList,
      soleProprietorCnpMigrationList,
    ] = await Promise.all([
      getCnpList(),
      getCnpList({ soleProprietorEnabled: true }),
      getCnpList({ cnpMigrationSupported: true }),
      getCnpList({ cnpMigrationSupported: true, soleProprietorEnabled: true }),
    ]);
    setCnpOptions(
      cnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setSoleProprietorCnpOptions(
      soleProprietorCnpList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setCnpMigrationOptions(
      cnpMigrationList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setSoleProprietorCnpMigrationOptions(
      soleProprietorCnpMigrationList.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
  };

  const resetFilterConfigsError = (newValues: Record<string, string>) => {
    const tempFilterConfigs = { ...filterConfigs };
    Object.keys(newValues).forEach((key) => {
      if (
        tempFilterConfigs[key]?.error &&
        newValues[key] !== candidateFilters[key]
      ) {
        tempFilterConfigs[key].error = false;
        tempFilterConfigs[key].helperText = '';
      }
    });
    updateFilterConfigs(tempFilterConfigs);
  };

  const validateFilters = (values: Record<string, string>) => {
    const tempFilterConfigs = { ...filterConfigs };
    Object.keys(values).forEach((key) => {
      if (key in FilterErrorCode) {
        const code = fieldValidation({
          ...(FilterErrorCode as ErrorCodeType)[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          tempFilterConfigs[key].error = true;
          tempFilterConfigs[key].helperText = String(
            (FilterErrorCode as ErrorCodeType)[key][code]
          );
        }
      }
    });
    updateFilterConfigs(tempFilterConfigs);
    return Object.keys(values).every((key) => !tempFilterConfigs[key].error);
  };

  const handleCandidateFiltersChange = (values: Record<string, string>) => {
    resetFilterConfigsError(values);
    handleFiltersEdit(values);
  };

  const handleAppliedFiltersChange = (values: Record<string, string>) => {
    if (!validateFilters(values)) {
      return;
    }

    if (Object.keys(values).length === 0) {
      resetFilterConfigsError(
        filterConfigs as unknown as Record<string, string>
      );
    }
    handleFiltersApply(values);
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    const queries = getQueries();
    if ('page' in queries) {
      delete queries.page;
    }
    const response = await downloadCampaignsAsCsv(queries);
    if (response) {
      const file = new File([response], 'my-campaigns.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  const handleBulkActionApply = () => {
    if (bulkAction === BulkCampaignAction.Elect) {
      setBulkElectModal(true);
    } else if (bulkAction === BulkCampaignAction.InitiateMigration) {
      setBulkInitiateMigrationModal(true);
    } else if (bulkAction === BulkCampaignAction.CancelMigration) {
      setBulkCancelMigrationModel(true);
    }
  };

  const handleBulkElect = async (upstreamCnpUid: string) => {
    try {
      const response = await electCampaigns(upstreamCnpUid, selectedCampaigns);
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) elected`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot elect campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  const handleBulkInitiateMigration = async (upstreamCnpUid: string) => {
    try {
      const response = await initiateCampaignMigrations(
        upstreamCnpUid,
        selectedCampaigns
      );
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign(s) migrated`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot migrate campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  const handleBulkCancelMigration = async (explanation?: string) => {
    try {
      const response = await cancelCampaignMigrations(
        selectedCampaigns,
        explanation
      );
      if (response) {
        toastFlashMessage(
          `Success: ${selectedCampaigns.length} campaign migration(s) canceled`,
          'success'
        );
        setErrorCampaigns([]);
        reload();
      }
    } catch (error) {
      if (error instanceof BulkOperationError) {
        Object.entries(error.details).forEach(([key, value]) => {
          toastFlashMessage(
            `Action failed. Cannot cancel migration for campaign ${key}: ${value.description}`,
            'error'
          );
        });
        setErrorCampaigns(Object.keys(error.details));
      }
    }
  };

  return (
    <>
      <Box
        as="section"
        sx={{
          // Fix dropdown width affected by global styling
          '& .MuiAutocomplete-root': {
            width: 'unset',
          },
        }}
        data-testid="campaignListing"
      >
        {pageLoading ? (
          <Loader />
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: '12px',
            }}
          >
            <PageTitle note="Results capped at first 10,000 records">
              {totalCampaigns > 0 ? `${totalCampaigns} Campaigns` : 'Campaigns'}
            </PageTitle>
            <Flex
              sx={{
                alignItems: 'center',
                columnGap: '12px',
                width: '100%',
                marginTop: '-8px',
              }}
            >
              <Button
                color="secondary"
                onClick={() => setFiltersShown(!filtersShown)}
                data-testid="tableAddFilterButton"
              >
                <FontAwesomeIcon icon={faFilter} />
                <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
              </Button>
              <Button
                variant="outline"
                color="secondary"
                onClick={() => {
                  if (totalCampaigns > 10000) {
                    setDownloadLimitedModal(true);
                  } else {
                    handleDownload();
                  }
                }}
                data-testid="tableDownloadButton"
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
              <Box sx={{ flex: 1 }} />
              <RoleGuard feature="campaignList.addCampaign">
                <Button
                  variant="outline"
                  onClick={() => history.push('/campaign/create')}
                  data-testid="campaignListingAddButton"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Add Campaign
                </Button>
              </RoleGuard>
            </Flex>
            {filtersShown && (
              <TableFilter
                configs={filterConfigs}
                candidateValues={candidateFilters}
                appliedValues={appliedFilters}
                onCandidateValuesChange={(values: Record<string, string>) =>
                  handleCandidateFiltersChange(values)
                }
                onAppliedValuesChange={(values: Record<string, string>) =>
                  handleAppliedFiltersChange(values)
                }
              />
            )}
            <Flex sx={{ alignItems: 'center', columnGap: '10px' }}>
              <Dropdown
                size="small"
                label="Bulk Actions"
                options={bulkActionOptions}
                value={bulkAction}
                onChange={(value) => {
                  setBulkAction(value);
                }}
              />
              <Button
                disabled={selectedCampaigns.length < 1 || !bulkAction}
                size="small"
                onClick={handleBulkActionApply}
              >
                Apply
              </Button>
              <Box as="span">{selectedCampaigns.length} selected</Box>
            </Flex>
            <Table
              testId="campaignsListingListingTableNew"
              selectable
              loading={tableLoading}
              emptyState="no campaigns to view"
              handleChangePage={handlePageChange}
              createSortHandler={handleSortingChange}
              filter={tableFilters}
              headRows={headRows}
              data={campaigns as Record<string, any>[]}
              rowKey="uid"
              selectedRows={selectedCampaigns}
              onSelectChange={(value) => {
                setSelectedCampaigns(value);
              }}
              renderRow={(data, attributes) => {
                const campaign = data as unknown as CspCampaign;
                return (
                  <MyCampaignListingRow
                    key={campaign.uid}
                    selectable={attributes?.selectable}
                    selected={attributes?.selected}
                    error={errorCampaigns.includes(campaign.uid)}
                    campaign={campaign}
                    onChange={attributes?.onSelectedChange}
                  />
                );
              }}
              pagination={pagination}
            />
          </Flex>
        )}
      </Box>
      <DownloadLimitedModal
        open={downloadLimitedModal}
        onClose={() => setDownloadLimitedModal(false)}
        onSubmit={() => {
          setDownloadLimitedModal(false);
          handleDownload();
        }}
      />
      <BulkElectModal
        open={bulkElectModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        options={
          appliedFilters.provisional === 'true'
            ? soleProprietorCampaignsSelected
              ? soleProprietorCnpMigrationOptions
              : cnpMigrationOptions
            : soleProprietorCampaignsSelected
            ? soleProprietorCnpOptions
            : cnpOptions
        }
        onClose={() => {
          setBulkElectModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkElect(value);
          setBulkActionProcessing(false);
          setBulkElectModal(false);
        }}
      />
      <BulkInitiateMigrationModal
        open={bulkInitiateMigrationModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        options={
          soleProprietorCampaignsSelected
            ? soleProprietorCnpMigrationOptions
            : cnpMigrationOptions
        }
        onClose={() => {
          setBulkInitiateMigrationModal(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkInitiateMigration(value);
          setBulkActionProcessing(false);
          setBulkInitiateMigrationModal(false);
        }}
      />
      <BulkCancelMigrationModal
        open={bulkCancelMigrationModal}
        disabled={bulkActionProcessing}
        campaignUids={selectedCampaigns}
        onClose={() => {
          setBulkCancelMigrationModel(false);
        }}
        onSubmit={async (value) => {
          setBulkActionProcessing(true);
          await handleBulkCancelMigration(value);
          setBulkActionProcessing(false);
          setBulkCancelMigrationModel(false);
        }}
      />
    </>
  );
};

export default MyCampaigns;
