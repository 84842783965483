import { BoxV2 as Box, Flex, RoleGuard } from 'portal-commons';
import { FunctionComponent, MouseEventHandler, useRef, useState } from 'react';
import {
  faEllipsisVertical,
  faHourglass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@material-ui/core';

import { BrandAsset } from '../../types';

interface BrandAssetRowProps {
  asset: BrandAsset;
  group: string;
  onSelect?: () => void;
  onVerify?: () => void;
}

interface MenuButtonProps {
  onVerify?: () => void;
}

const MenuButton: FunctionComponent<MenuButtonProps> = ({ onVerify }) => {
  const [anchorElement, setAnchorElement] = useState<Element>();

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleBlur = () => {
    setAnchorElement(undefined);
  };

  const handleVerify: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    onVerify?.();
    setAnchorElement(undefined);
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 16,
        height: 16,
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      <Menu
        className="tcr-menu"
        disableAutoFocusItem
        open={!!anchorElement}
        anchorEl={anchorElement}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBlur={handleBlur}
      >
        <MenuItem onClick={handleVerify}>Verify</MenuItem>
      </Menu>
    </Flex>
  );
};

const BrandAssetRow: FunctionComponent<BrandAssetRowProps> = ({
  asset,
  group,
  onSelect,
  onVerify,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNameClick = () => {
    inputRef.current?.click();
  };

  return (
    <Box
      sx={{
        display: 'contents',
        '> *': {
          alignItems: 'center',
          paddingLeft: '16px',
        },
      }}
    >
      <Flex
        sx={{
          columnGap: '7px',
          color: '#0091B3',
          fontSize: '14px',
          lineHeight: '16px',
        }}
      >
        <input
          ref={inputRef}
          style={{ accentColor: '#D41C62', cursor: 'pointer' }}
          type="radio"
          name={group}
          onClick={onSelect}
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNameClick}>
          {asset.name}
        </Box>
      </Flex>
      <Flex sx={{ columnGap: '7px' }}>
        {asset.verificationStatus?.toLowerCase() === 'pending' && (
          <FontAwesomeIcon icon={faHourglass} size="sm" />
        )}
        {asset.verificationStatus ?? 'N/A'}
      </Flex>
      <RoleGuard feature="brandDetail.verifyAsset">
        <Flex sx={{ color: '#D41C62' }}>
          <MenuButton onVerify={onVerify} />
        </Flex>
      </RoleGuard>
    </Box>
  );
};

export default BrandAssetRow;
