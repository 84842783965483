import React, { useState } from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  ENTITY_TYPES,
  OPEN_CANADIAN_BRAND_NOTICE,
} from '../../../../constants';
import { Tooltip } from 'portal-commons';
import IntegrationReactSelect from '../../../../ui_elements/IntegrationReactSelect';
import PropTypes from 'prop-types';
import CanadianBrandNoticeModal from './CanadianBrandNoticeModal';

const BrandBasicDetailsForm = (props) => {
  const { error, brandInfo, openTooltip } = props;
  const options =
    brandInfo.entityType === 'GOVERNMENT'
      ? []
      : props.countries.filter(
          (country) => !['US', 'CU', 'IR', 'KP', 'SY'].includes(country.value)
        );
  options.unshift({ value: 'US', label: 'United States' });
  const verticalOptions =
    props.verticalTypes &&
    props.verticalTypes.map((item, index) => {
      return { label: item.label, value: item.value };
    });
  const stockExchangeOptions =
    props.stockExchanges &&
    props.stockExchanges.map((item, index) => {
      return { label: item.label, value: item.value };
    });

  const handleEntityTypeChange = (entityType) => {
    props.handleChange(entityType, 'entityType');
    if (entityType === 'GOVERNMENT') {
      props.handleChange({ value: 'US', label: 'United States' }, 'country');
    }
  };

  const hasOpenNotice =
    localStorage.getItem(OPEN_CANADIAN_BRAND_NOTICE) ?? false;
  const [openNotice, setOpenNotice] = useState(false);
  const handleTaxNumberNotice = (country) => {
    if (country === 'CA' && !hasOpenNotice) {
      localStorage.setItem(OPEN_CANADIAN_BRAND_NOTICE, true);
      setOpenNotice(true);
    }
  };

  return (
    <>
      <Grid container spacing={4} data-testid="brandBasicDetailsForm">
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <Tooltip
              title={'Type in the legally registered company name'}
              open={openTooltip}
            />
            <TextField
              error={error.companyName ? true : false}
              value={brandInfo.companyName}
              id="companyName"
              data-testid="brandBasicDetailsFormCompanyNameInput"
              required={
                brandInfo.entityType == 'SOLE_PROPRIETOR' ? false : true
              }
              fullWidth={true}
              label="Legal Company Name"
              autoComplete="off"
              disabled={
                brandInfo.entityType == 'SOLE_PROPRIETOR' ? true : false
              }
              inputProps={{
                'aria-label': 'companyName',
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'companyName')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'companyName')
              }
              onFocus={props.handleFocus}
            />
            <Tooltip title={'Type the legally registered company name'} />
            {error.companyName ? (
              <h6 className="error-msg">{error.companyName}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              required
              error={error.displayName ? true : false}
              value={brandInfo.displayName}
              id="displayName"
              data-testid="brandBasicDetailsFormDisplayNameInput"
              fullWidth={true}
              label="DBA or Brand Name, if different from legal name"
              inputProps={{ 'aria-label': 'displayName' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'displayName')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'displayName')
              }
            />
            <Tooltip
              title={
                "Type in the 'Doing Business As' (DBA) or trade name of the company"
              }
            />
            {error.displayName ? (
              <h6 className="error-msg">{error.displayName}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <FormControl required style={{ width: '100%' }}>
              <InputLabel>
                What type of legal form is the organization?
              </InputLabel>
              <Select
                error={error.entityType ? true : false}
                value={brandInfo.entityType}
                onChange={(event) => handleEntityTypeChange(event.target.value)}
                data-testid="brandBasicDetailsFormEntityTypeSelect"
                fullWidth={true}
                required
              >
                {ENTITY_TYPES.map((entity) => (
                  <MenuItem value={entity.value} key={entity.value}>
                    {entity.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error.entityType ? (
              <h6 className="error-msg">{error.entityType}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <IntegrationReactSelect
              options={options}
              required={true}
              label="Country of Registration"
              value={
                Object.keys(brandInfo.country).length ? brandInfo.country : ''
              }
              handleChange={props.handleChange}
              keyName="country"
            />
            <Tooltip
              title={'Select the country in which the company is located'}
            />
          </div>
          {error.country ? (
            <h6 className="error-msg">{error.country}</h6>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.ein ? true : false}
              value={brandInfo.ein}
              id="ein"
              data-testid="brandBasicDetailsFormEinInput"
              required={
                brandInfo.entityType === 'SOLE_PROPRIETOR' ? false : true
              }
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType !== 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              fullWidth={true}
              label={
                brandInfo.einIssuingCountry.value === 'US'
                  ? 'EIN'
                  : brandInfo.einIssuingCountry.value
                  ? 'Tax Number/ID'
                  : 'Tax Number/ID/EIN'
              }
              inputProps={{ 'aria-label': 'ein' }}
              onFocus={() =>
                handleTaxNumberNotice(brandInfo.einIssuingCountry.value)
              }
              onChange={(event) =>
                props.handleChange(event.target.value, 'ein')
              }
              onBlur={(event) => props.handleError(event.target.value, 'ein')}
            />
            {brandInfo.einIssuingCountry.value == 'US' ? (
              <Tooltip
                title={
                  "The Employer Identification Number (EIN) is a nine-digit number assigned by the IRS.  It's used to identify the tax accounts of employers and certain others who have no employees.  The IRS uses the number to identify taxpayers who are required to file varioius business tax returns.  EINs are used by employers, sole proprietors, corporations, partnerships, non-profit organizations, trusts, estates of decendants, government agencies, certain individuals, and other business entities."
                }
              />
            ) : (
              ''
            )}
            {error.ein ? <h6 className="error-msg">{error.ein}</h6> : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <IntegrationReactSelect
              options={options}
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType !== 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              label={
                brandInfo.einIssuingCountry &&
                brandInfo.einIssuingCountry.value === 'US'
                  ? 'EIN Issuing Country'
                  : brandInfo.einIssuingCountry.value
                  ? 'Tax Number/ID Issuing Country'
                  : 'Tax Number/ID/EIN Issuing Country'
              }
              value={brandInfo.einIssuingCountry}
              handleChange={props.handleChange}
              keyName="einIssuingCountry"
            />
            <Tooltip
              title={`Please select the country that has issued the ${
                brandInfo.einIssuingCountry &&
                brandInfo.einIssuingCountry.value === 'US'
                  ? 'EIN'
                  : brandInfo.einIssuingCountry.value
                  ? 'Tax Number/ID'
                  : 'Tax Number/ID/EIN'
              }.`}
            />
            {error.einIssuingCountry ? (
              <h6 className="error-msg">{error.einIssuingCountry}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <div className="form-group-field">
            <FormControl
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType !== 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              style={{ width: '100%' }}
              data-testid="brandBasicDetailsFormAltBusinessIdTypeForm"
            >
              <InputLabel>{`DUNS or GIIN or LEI Number`}</InputLabel>
              <Select
                error={error.altBusinessIdType ? true : false}
                value={brandInfo.altBusinessIdType}
                onChange={(event) =>
                  props.handleChange(event.target.value, 'altBusinessIdType')
                }
                fullWidth={true}
                data-testid="brandBasicDetailsFormAltBusinessIdTypeSelect"
              >
                <MenuItem value={'DUNS'}>DUNS</MenuItem>
                <MenuItem value={'GIIN'}>GIIN</MenuItem>
                <MenuItem value={'LEI'}>LEI</MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              title={`Please select an "Alternative Business ID". You can provide the DUNS Number, GIIN, or LEI. This is a complementary and optional field to help better identify the Brand. The "DUNS Number" in particular is important in identifying non-US Brands.`}
            />
            {error.altBusinessIdType ? (
              <h6 className="error-msg">{error.altBusinessIdType}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="form-group-field">
            <TextField
              error={error.altBusinessId ? true : false}
              value={brandInfo.altBusinessId}
              id="altBusinessId"
              data-testid="brandBasicDetailsFormAltBusinessIdInput"
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.altBusinessIdType &&
                brandInfo.entityType !== 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              fullWidth={true}
              label={
                brandInfo.altBusinessIdType
                  ? `${brandInfo.altBusinessIdType} Number`
                  : 'DUNS or GIIN or LEI Number'
              }
              inputProps={{ 'aria-label': 'ein' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'altBusinessId')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'altBusinessId')
              }
            />
            <Tooltip
              title={`Please enter the "Alternative Business ID". This is a complementary and optional field to help better identify the Brand.`}
            />
            {error.altBusinessId ? (
              <h6 className="error-msg">{error.altBusinessId}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <div className="form-group-field">
            <TextField
              error={error.street ? true : false}
              value={brandInfo.street}
              id="street"
              data-testid="brandBasicDetailsFormStreetInput"
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              required
              fullWidth={true}
              label="Address/Street"
              inputProps={{ 'aria-label': 'street' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'street')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'street')
              }
            />
            {error.street ? (
              <h6 className="error-msg">{error.street}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="form-group-field">
            <TextField
              error={error.city ? true : false}
              value={props.brandInfo.city}
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              id="city"
              data-testid="brandBasicDetailsFormCityInput"
              required
              fullWidth={true}
              label="City"
              inputProps={{ 'aria-label': 'city' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'city')
              }
              onBlur={(event) => props.handleError(event.target.value, 'city')}
            />
            {error.city ? <h6 className="error-msg">{error.city}</h6> : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <div className="form-group-field">
            {brandInfo.country.value === 'US' ? (
              <IntegrationReactSelect
                required
                options={props.usStates}
                label="State"
                disabled={!(props.countryFlag && brandInfo.entityType)}
                value={brandInfo.state}
                handleChange={props.handleChange}
                keyName="state"
                error={!!error.state}
              />
            ) : (
              <TextField
                error={error.state ? true : false}
                value={brandInfo.state}
                id="state"
                data-testid="brandBasicDetailsFormStateInput"
                disabled={
                  props.countryFlag && brandInfo.entityType ? false : true
                }
                required
                fullWidth={true}
                label="State / Region"
                inputProps={{ 'aria-label': 'state' }}
                onChange={(event) =>
                  props.handleChange(event.target.value, 'state')
                }
                onBlur={(event) =>
                  props.handleError(event.target.value, 'state')
                }
              />
            )}
            {error.state ? <h6 className="error-msg">{error.state}</h6> : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.postalCode ? true : false}
              value={brandInfo.postalCode}
              id="postalCode"
              data-testid="brandBasicDetailsFormPostalCodeInput"
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              fullWidth={true}
              required
              label={
                brandInfo.country.value === 'US'
                  ? 'ZIP Code'
                  : brandInfo.country.value
                  ? 'Postal Code'
                  : 'Postal Code/ZIP Code'
              }
              inputProps={{ 'aria-label': 'postalCode' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'postalCode')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'postalCode')
              }
            />
            {error.postalCode ? (
              <h6 className="error-msg">{`${error.postalCode} ${
                brandInfo.country.value === 'US' ? 'ZIP code' : 'Postal Code'
              }`}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {props.countryFlag ? (
            <div className="form-group-field disabled">
              <TextField
                value={brandInfo.country.label}
                id="country"
                data-testid="brandBasicDetailsFormCountryInput"
                fullWidth={true}
                label="Country"
                disabled={true}
                inputProps={{ 'aria-label': 'country' }}
              />
              {error.country ? (
                <h6 className="error-msg">{error.country}</h6>
              ) : null}
            </div>
          ) : (
            <div className="form-group-field disabled">
              <FormControl style={{ opacity: 0.7 }}>
                <InputLabel>Country</InputLabel>
                <Select fullWidth={true} disabled={true}></Select>
              </FormControl>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.website ? true : false}
              value={brandInfo.website}
              id="website"
              data-testid="brandBasicDetailsFormWebsiteInput"
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              required={false}
              fullWidth={true}
              label="Website/Online Presence"
              inputProps={{ 'aria-label': 'website' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'website')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'website')
              }
            />
            <Tooltip title="If opt-ins are collected via website/online presence then please enter the website/online presence here." />
            {error.website ? (
              <h6 className="error-msg">{error.website}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.stockSymbol ? true : false}
              value={brandInfo.stockSymbol}
              id="stockSymbol"
              data-testid="brandBasicDetailsFormStockSymbolInput"
              required={brandInfo.entityType === 'PUBLIC_PROFIT' ? true : false}
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType == 'PUBLIC_PROFIT'
                  ? false
                  : true
              }
              fullWidth={true}
              label="Stock Symbol"
              inputProps={{ 'aria-label': 'stockSymbol' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'stockSymbol')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'stockSymbol')
              }
            />
            {error.stockSymbol ? (
              <h6 className="error-msg">{error.stockSymbol}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <IntegrationReactSelect
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType == 'PUBLIC_PROFIT'
                  ? false
                  : true
              }
              required={brandInfo.entityType === 'PUBLIC_PROFIT' ? true : false}
              options={stockExchangeOptions}
              label="Stock Exchange"
              error={error.stockExchange ? true : false}
              value={stockExchangeOptions.find(
                (option) => option.value === brandInfo.stockExchange
              )}
              handleChange={(selectedOption, keyName) =>
                props.handleChange(selectedOption.value, keyName)
              }
              keyName="stockExchange"
            />
            {error.stockExchange ? (
              <h6 className="error-msg">{error.stockExchange}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <IntegrationReactSelect
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              options={verticalOptions}
              label="Vertical Type"
              required={
                brandInfo.entityType === 'SOLE_PROPRIETOR' ? false : true
              }
              error={error.vertical ? true : false}
              value={verticalOptions.find(
                (option) => option.value === brandInfo.vertical
              )}
              handleChange={(selectedOption, keyName) =>
                props.handleChange(selectedOption.value, keyName)
              }
              keyName="vertical"
            />
            {error.vertical ? (
              <h6 className="error-msg">{error.vertical}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.referenceId ? true : false}
              value={brandInfo.referenceId}
              id="referenceId"
              data-testid="brandBasicDetailsFormReferenceIdInput"
              required={
                brandInfo.entityType === 'SOLE_PROPRIETOR' ? true : false
              }
              disabled={
                props.countryFlag && brandInfo.entityType ? false : true
              }
              fullWidth={true}
              label="Reference ID"
              inputProps={{ 'aria-label': 'referenceId' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'referenceId')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'referenceId')
              }
            />
            <Tooltip title="The unique number or code used to reference your client inside your organization. This information is mandatory when selecting a Sole Proprietor entity type." />
            {error.referenceId ? (
              <h6 className="error-msg">{error.referenceId}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.firstName ? true : false}
              value={brandInfo.firstName}
              id="firstName"
              data-testid="brandBasicDetailsFormFirstNameInput"
              required={
                brandInfo.entityType === 'SOLE_PROPRIETOR' ? true : false
              }
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType === 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              fullWidth={true}
              label="First Name"
              inputProps={{ 'aria-label': 'firstName' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'firstName')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'firstName')
              }
            />
            {error.firstName ? (
              <h6 className="error-msg">{error.firstName}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              error={error.lastName ? true : false}
              value={brandInfo.lastName}
              id="lastName"
              data-testid="brandBasicDetailsFormLastNameInput"
              required={
                brandInfo.entityType === 'SOLE_PROPRIETOR' ? true : false
              }
              disabled={
                props.countryFlag &&
                brandInfo.entityType &&
                brandInfo.entityType === 'SOLE_PROPRIETOR'
                  ? false
                  : true
              }
              fullWidth={true}
              label="Last Name"
              inputProps={{ 'aria-label': 'lastName' }}
              onChange={(event) =>
                props.handleChange(event.target.value, 'lastName')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'lastName')
              }
            />
            {error.lastName ? (
              <h6 className="error-msg">{error.lastName}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <TextField
              inputProps={{ 'aria-label': 'mobilePhone' }}
              id="mobilePhone"
              data-testid="brandBasicDetailsFormMobilePhoneInput"
              label="Mobile Phone"
              fullWidth
              required={brandInfo.entityType === 'SOLE_PROPRIETOR'}
              disabled={
                !(
                  props.countryFlag &&
                  brandInfo.entityType === 'SOLE_PROPRIETOR'
                )
              }
              error={!!error.mobilePhone}
              value={brandInfo.mobilePhone}
              onChange={(event) =>
                props.handleChange(event.target.value, 'mobilePhone')
              }
              onBlur={(event) =>
                props.handleError(event.target.value, 'mobilePhone')
              }
            />
            <Tooltip title="This field is required for Sole Proprietor brands and will be used to perform OTP which will allow your brand to be moved to Verified status." />
            {error.mobilePhone ? (
              <h6 className="error-msg">{error.mobilePhone}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <CanadianBrandNoticeModal
        open={openNotice}
        handleClose={() => setOpenNotice(false)}
      />
    </>
  );
};
BrandBasicDetailsForm.propTypes = {
  brandInfo: PropTypes.shape({
    companyName: PropTypes.string,
    displayName: PropTypes.string,
    country: PropTypes.object,
    entityType: PropTypes.string,
  }).isRequired,
  error: PropTypes.objectOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleError: PropTypes.func,
};

export default BrandBasicDetailsForm;
