import { BrandAsset, BrandAssetStatus, BrandAssetType } from '../../types';

export const MOCK_DATA: BrandAsset[] = [
  {
    name: 'Banner-1.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/0D19A133D3D842C88AE4FDDF37F35346',
    verificationStatus: BrandAssetStatus.Expired,
    reasons: '',
    vettedDate: 1736222228755,
    mimeType: 'image/jpeg',
    createDate: 1736322221755,
    expireDate: 1736422221755,
  },
  {
    name: 'Banner-2.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/B880C9D752B44262A7341991168435D1',
    reasons: '',
    mimeType: 'image/jpeg',
    createDate: 1736522821755,
  },
  {
    name: 'Banner-3.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/0D19A133D3D842C88AE4FDDF37F35347',
    verificationStatus: BrandAssetStatus.Unverified,
    reasons: `Lorem ipsum dolor sit amet, magna mediocritatem ad mea, dicat saepe exerci ut usu.\n
Consul conclusionemque eu quo. Pro cu feugait sapientem, aliquid scaevola disputando in usu. In wisi putant eum, mazim tritani scripserit ea quo, ad persius persequeris definitionem nam. Cu mel modo probo, ne tamquam equidem evertitur eos. Nec ea augue nonumy facete, sanctus tractatos iracundia ea cum.\n
In eos facete sanctus saperet, mei ex possim omnium minimum, his quis delenit splendide in.`,
    vettedDate: 1736822221755,
    mimeType: 'image/jpeg',
    createDate: 1735822108755,
  },
  {
    name: 'Banner-4.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/B880C9D752B44262A7341991168435D2',
    verificationStatus: BrandAssetStatus.Verified,
    reasons: '',
    vettedDate: 1736822228755,
    mimeType: 'image/jpeg',
    createDate: 1736822221755,
    expireDate: 1736922221755,
  },
  {
    name: 'Banner-5.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/0D19A133D3D842C88AE4FDDF37F35348',
    verificationStatus: BrandAssetStatus.Pending,
    vettedDate: 1736822228755,
    mimeType: 'image/jpeg',
    createDate: 1728882221755,
  },
  {
    name: 'Banner-6.jpg',
    type: BrandAssetType.Banner,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/B880C9D752B44262A7341991168435D3',
    reasons: '',
    mimeType: 'image/jpeg',
    createDate: 1729862221755,
  },
  {
    name: 'Logo-1.jpg',
    type: BrandAssetType.Logo,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/E68294C4EBD548B2BB9A950696DBC277',
    verificationStatus: BrandAssetStatus.Unverified,
    reasons: `Lorem ipsum dolor sit amet, magna mediocritatem ad mea, dicat saepe exerci ut usu.\n
Consul conclusionemque eu quo. Pro cu feugait sapientem, aliquid scaevola disputando in usu. In wisi putant eum, mazim tritani scripserit ea quo, ad persius persequeris definitionem nam. Cu mel modo probo, ne tamquam equidem evertitur eos. Nec ea augue nonumy facete, sanctus tractatos iracundia ea cum.\n
In eos facete sanctus saperet, mei ex possim omnium minimum, his quis delenit splendide in.`,
    vettedDate: 1736822221755,
    mimeType: 'image/jpeg',
    createDate: 1735822108755,
  },
  {
    name: 'Logo-2.jpg',
    type: BrandAssetType.Logo,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/7AFA562CAE814564A205E58E7B9828D0',
    verificationStatus: BrandAssetStatus.Verified,
    reasons: '',
    vettedDate: 1736822228755,
    mimeType: 'image/jpeg',
    createDate: 1736822221755,
    expireDate: 1736922221755,
  },
  {
    name: 'Logo-3.jpg',
    type: BrandAssetType.Logo,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/7AFA562CAE814564A205E58E7B9828D1',
    verificationStatus: BrandAssetStatus.Pending,
    vettedDate: 1736822228755,
    mimeType: 'image/jpeg',
    createDate: 1728882221755,
  },
  {
    name: 'Logo-4.jpg',
    type: BrandAssetType.Logo,
    attachmentUuid: 'CNUC23Y/SUPPORT_DOC/E68294C4EBD548B2BB9A950696DBC278',
    mimeType: 'image/jpeg',
    createDate: 1729862221755,
  },
];
