import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, DropdownV2, BoxV2 as Box, Tooltip } from 'portal-commons';
import PropTypes from 'prop-types';

import { US_STATE_LIST } from '../../../../constants';
import { fieldValidator } from '../helper/cspFieldValidator';
import { isValidInput } from '../../../../utils';
import { TwoColumnGrid } from './CspVerificationSteps';

const EXCLUDED_COUNTRIES = ['US', 'CU', 'IR', 'KP', 'SY'];
const TOOLTIP_OVERRIDE_STYLE = { top: '12px' };
const ENTITY_TYPE_OPTIONS = [
  {
    label: 'Publicly Traded Company',
    value: 'PUBLIC_PROFIT',
  },
  {
    label: 'Private Company',
    value: 'PRIVATE_PROFIT',
  },
  {
    label: 'Non-Profit Organization',
    value: 'NON_PROFIT',
  },
];

const CspBasicDetailsNew = ({
  errors = {},
  cspInfo = {},
  countries = [],
  stockExchanges = [],
  validationRules,
}) => {
  const { clearErrors, setValue, control } = useFormContext();
  const countryFlag = cspInfo.country ? Object.keys(cspInfo.country).length : 0;
  const countryOptions = useMemo(() => {
    const options = countries.filter(
      (country) => !EXCLUDED_COUNTRIES.includes(country.value)
    );
    options.unshift({ value: 'US', label: 'United States' });
    return options;
  }, [countries]);

  const stockExchangeOptions = useMemo(() => {
    return stockExchanges.map((item) => {
      return { label: item.label, value: item.value };
    });
  }, [stockExchanges]);

  const [openFieldTooltip, setOpenFieldTooltip] = useState('');
  const [focusing, setFocusing] = useState('');

  const clearFields = (fields = []) => {
    fields.forEach((filedKey) => {
      setValue(filedKey, '');
    });
    clearErrors(fields);
  };

  const einLabel =
    cspInfo.country?.value === 'US'
      ? 'EIN'
      : cspInfo.country?.value
      ? 'Tax Number/ID'
      : 'Tax Number/ID/EIN';

  const countryVal =
    cspInfo.country && typeof cspInfo.country === 'object'
      ? cspInfo.country.value
      : cspInfo.country;

  const { entityType } = cspInfo;
  const isPublicCompany = entityType === 'PUBLIC_PROFIT';

  const noXssInput = (value = '') => {
    return isValidInput(value) || 'Invalid input: contains HTML';
  };

  return (
    <TwoColumnGrid
      sx={{
        mt: 'm',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Controller
          control={control}
          defaultValue=""
          name="companyName"
          rules={{
            validate: {
              base: (v) => fieldValidator.companyName(v),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({
            field: { onChange, onBlur, value, ...rest },
            fieldState: { error = {} },
          }) => (
            <>
              <TextInput
                error={!!error.message}
                value={value}
                id="companyName"
                data-testid="cspBasicDetailsNewCompanyNameInput"
                label="Legal Company Name"
                autoComplete="off"
                onChange={(val) => {
                  onChange(val);
                  clearErrors('displayName');
                  setValue('displayName', val);
                }}
                onBlur={(e) => {
                  setFocusing('');
                  onBlur(e);
                }}
                required
                onFocus={() => setFocusing('companyName')}
                note={error.message}
                {...rest}
              />
              <Tooltip
                data-testid="cspDetailsCompanyNameTooltip"
                title={'Type in the legally registered company name'}
                open={
                  focusing === 'companyName' ||
                  openFieldTooltip === 'companyName'
                }
                // use below callbacks to handle uncontrolled tooltip behavior : hover
                onOpen={() =>
                  focusing !== 'companyName' &&
                  setOpenFieldTooltip('companyName')
                }
                onClose={() =>
                  focusing !== 'companyName' && setOpenFieldTooltip('')
                }
                style={TOOLTIP_OVERRIDE_STYLE}
              />
            </>
          )}
        />
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Controller
          control={control}
          defaultValue=""
          name="displayName"
          rules={{
            validate: {
              base: (v) => fieldValidator.displayName(v),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({ field, fieldState: { error = {} } }) => (
            <>
              <TextInput
                required
                error={!!error.message}
                data-testid="cspBasicDetailsNewDisplayNameInput"
                id="displayName"
                fullWidth={true}
                label="DBA or Brand Name, if different from legal name"
                note={error.message}
                {...field}
              />
            </>
          )}
        />
        <Tooltip
          title={
            "Type in the 'Doing Business As' (DBA) or trade name of the company"
          }
          style={TOOLTIP_OVERRIDE_STYLE}
        />
      </Box>
      <Box
        sx={{ position: 'relative' }}
        data-testid="cspBasicDetailsNewCountrySelect"
      >
        <Controller
          control={control}
          defaultValue=""
          name="country"
          rules={{
            validate: {
              base: (v) => fieldValidator.country(v),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error = {} },
          }) => (
            <>
              <DropdownV2
                options={countryOptions}
                error={!!error.message}
                helperText={error.message}
                required
                label="Country of Registration"
                onChange={(val) => {
                  const option = countryOptions.find((c) => c.value === val);
                  onChange(option);
                  clearFields(['state', 'street', 'city', 'postalCode', 'ein']);
                }}
                value={value?.value ?? ''}
                {...rest}
              />
            </>
          )}
        />
        <Tooltip
          title={'Select the country in which the company is located'}
          style={TOOLTIP_OVERRIDE_STYLE}
        />
      </Box>
      <Controller
        control={control}
        defaultValue=""
        name="entityType"
        rules={{
          validate: {
            base: (v) => fieldValidator.entityType(v),
            xss: (v) => noXssInput(v),
          },
        }}
        render={({
          field: { onChange, ...rest },
          fieldState: { error = {} },
        }) => (
          <>
            <DropdownV2
              data-testid="cspBasicDetailsNewEntityTypeSelect"
              options={ENTITY_TYPE_OPTIONS}
              error={!!error.message}
              helperText={error.message}
              required
              label="What type of legal form is the organization?"
              onChange={(val) => {
                onChange(val);
                clearFields(['stockSymbol', 'ein', 'stockExchange']);
              }}
              {...rest}
            />
          </>
        )}
      />
      <Box
        sx={{ position: 'relative' }}
        data-testid="cspBasicDetailsNewCountrySelect"
      >
        <Controller
          control={control}
          defaultValue=""
          name="ein"
          rules={{
            validate: {
              base: (v) =>
                fieldValidator.ein(v, {
                  country: countryVal,
                  label: einLabel,
                  validationRules: validationRules,
                }),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({ field, fieldState: { error = {} } }) => (
            <>
              <TextInput
                data-testid="cspBasicDetailsNewEinInput"
                error={!!error.message}
                note={error.message}
                id="ein"
                required={true}
                disabled={countryFlag && cspInfo.entityType ? false : true}
                label={einLabel}
                {...field}
              />
            </>
          )}
        />
        {countryVal === 'US' && (
          <Tooltip
            style={TOOLTIP_OVERRIDE_STYLE}
            title={
              "The Employer Identification Number (EIN) is a nine-digit number assigned by the IRS.  It's used to identify the tax accounts of employers and certain others who have no employees.  The IRS uses the number to identify taxpayers who are required to file varioius business tax returns.  EINs are used by employers, sole proprietors, corporations, partnerships, non-profit organizations, trusts, estates of decendants, government agencies, certain individuals, and other business entities."
            }
          />
        )}
      </Box>
      <Controller
        control={control}
        defaultValue=""
        name="street"
        rules={{
          validate: {
            base: (v) => fieldValidator.street(v),
            xss: (v) => noXssInput(v),
          },
        }}
        render={({ field, fieldState: { error = {} } }) => (
          <TextInput
            error={!!error.message}
            id="street"
            data-testid="cspBasicDetailsNewStreetInput"
            disabled={countryFlag && entityType ? false : true}
            required={true}
            label="Address/Street"
            note={error.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="city"
        rules={{
          validate: {
            base: (v) => fieldValidator.city(v),
            xss: (v) => noXssInput(v),
          },
        }}
        render={({ field, fieldState: { error = {} } }) => (
          <>
            <TextInput
              error={!!error.message}
              disabled={countryFlag && entityType ? false : true}
              id="city"
              data-testid="cspBasicDetailsNewCityInput"
              required={true}
              label="City"
              note={error.message}
              {...field}
            />
          </>
        )}
      />
      <div data-testid="cspBasicDetailsNewCountryStateInput">
        <Controller
          control={control}
          defaultValue=""
          name="state"
          rules={{
            validate: {
              base: (v) => fieldValidator.state(v, { country: countryVal }),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({ field, fieldState: { error = {} } }) => (
            <>
              {countryVal === 'US' ? (
                <DropdownV2
                  required={true}
                  options={US_STATE_LIST}
                  label="State"
                  disabled={countryFlag && entityType ? false : true}
                  error={!!error.message}
                  helperText={error.message}
                  {...field}
                />
              ) : (
                <TextInput
                  error={!!error.message}
                  id="state"
                  disabled={countryFlag && entityType ? false : true}
                  required={true}
                  label="State / Region"
                  note={error.message}
                  {...field}
                />
              )}
            </>
          )}
        />
      </div>
      <Controller
        control={control}
        name="postalCode"
        defaultValue=""
        rules={{
          validate: {
            base: (v) => fieldValidator.postalCode(v, { country: countryVal }),
            xss: (v) => noXssInput(v),
          },
        }}
        render={({ field, fieldState: { error = {} } }) => (
          <>
            <TextInput
              data-testid="cspBasicDetailsNewPostalCodeInput"
              error={!!error.message}
              id="postalCode"
              disabled={countryFlag && entityType ? false : true}
              required={true}
              label={
                countryVal === 'US'
                  ? 'ZIP Code'
                  : countryVal
                  ? 'Postal Code'
                  : 'Postal Code/ZIP Code'
              }
              note={error.message}
              {...field}
            />
          </>
        )}
      />
      <TextInput
        value={cspInfo.country?.label ?? ''}
        id="country"
        data-testid="cspBasicDetailsNewCountryInput"
        label="Country"
        readOnly
        error={!!errors.country?.message}
        note={errors.country?.message}
      />
      <div data-testid="cspBasicDetailsWebsiteField">
        <Controller
          control={control}
          defaultValue=""
          name="website"
          rules={{
            validate: {
              base: (v) => fieldValidator.website(v),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({ field, fieldState: { error = {} } }) => (
            <>
              <TextInput
                error={!!error.message}
                id="website"
                data-testid="cspBasicDetailsWebsiteInput"
                disabled={countryFlag && entityType ? false : true}
                required={true}
                label="Website"
                note={error.message}
                {...field}
              />
            </>
          )}
        />
      </div>
      <Controller
        control={control}
        defaultValue=""
        name="stockSymbol"
        rules={{
          validate: {
            base: (v) =>
              fieldValidator.stockSymbol(v, {
                entityType: entityType,
              }),
            xss: (v) => noXssInput(v),
          },
        }}
        render={({ field, fieldState: { error = {} } }) => (
          <>
            <TextInput
              error={!!error.message}
              id="stockSymbol"
              data-testid="cspBasicDetailsStockSymbolInput"
              required={isPublicCompany}
              disabled={countryFlag && isPublicCompany ? false : true}
              label="Stock Symbol"
              note={error.message}
              {...field}
            />
          </>
        )}
      />
      <div data-testid="cspBasicDetailsStockExchangeSelect">
        <Controller
          control={control}
          defaultValue=""
          name="stockExchange"
          rules={{
            validate: {
              base: (v) =>
                fieldValidator.stockExchange(v, {
                  entityType: cspInfo.entityType,
                }),
              xss: (v) => noXssInput(v),
            },
          }}
          render={({ field, fieldState: { error = {} } }) => (
            <>
              <DropdownV2
                disabled={countryFlag && isPublicCompany ? false : true}
                required={isPublicCompany}
                options={stockExchangeOptions}
                label="Stock Exchange"
                error={!!error.message}
                helperText={error.message}
                {...field}
              />
            </>
          )}
        />
      </div>
    </TwoColumnGrid>
  );
};
CspBasicDetailsNew.propTypes = {
  cspInfo: PropTypes.shape({
    companyName: PropTypes.string,
    displayName: PropTypes.string,
    country: PropTypes.object,
    entityType: PropTypes.string,
  }).isRequired,
  errors: PropTypes.object,
  stockExchanges: PropTypes.array,
  countries: PropTypes.array,
  validationRules: PropTypes.array.isRequired,
};

export default CspBasicDetailsNew;
