export interface BrandDetail {
  uid: string | null;
  referenceId: string | null;
  cspUid: string;
  status: string;
  displayName: string;
  entityType: string;
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  ein: string | null;
  einIssuingCountry: string;
  altBusinessId: string | null;
  altBusinessIdType: string | null;
  phone: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  stockSymbol: string | null;
  stockExchange: string | null;
  brandRelationshipScore: number;
  website: string | null;
  submissionCount: number;
  vettedDate: Date | number | null;
  vertical: string;
  evpUid: string | null;
  evpVettingId: string | null;
  evpVettingScore: number | null;
  evpVettingClass: string | null;
  trustLevel: number;
  evpVettingDate: Date | number | null;
  createDate: Date | number;
  updateDate: Date | number;
  mobilePhone: string | null;
  mobilePhoneVerifiedDate: Date | number | null;
  universalEin: string | null;
  identityStatus: string;
  optionalAttributes?: OptionalAttributes;
  businessContactEmail?: string;
  businessContactEmailVerifiedDate?: number;
  businessContactFirstName?: string;
  businessContactLastName?: string;
  businessContactTitle?: string;
}

export interface OptionalAttributes {
  [key: string]: string;
}

export interface BrandFeedback {
  id: string;
  displayName: string;
  description: string;
  fields: string[];
}

export interface VettingAppeal {
  evpUid?: string;
  cspUid?: string;
  brandUid?: string;
  vettingId: string;
  vettingClass?: string;
  status: string;
  categoryList: string;
  attachmentUuidList?: string;
  explanation?: string;
  appealOutcome?: string | null;
  fullReport?: string | null;
  statusUpdateDate: number;
  createDate: number;
  updateDate?: number;
  attachments?: AttachmentInfo[];
}

export interface VettingAppealOutcomeFeedback {
  id: string;
  displayName: string;
  description: string;
}

export interface VettingAppealOutcome {
  vetStatus: string;
  vetScore?: number;
  optionalAttributes?: {
    governmentEntity?: boolean;
    section527?: boolean;
    taxExemptStatus?: string;
  };
  feedback?: {
    category?: VettingAppealOutcomeFeedback[];
  };
}

export interface MNOSuspendState {
  networkId: number;
  displayName: string;
  suspended?: boolean;
}

export interface AttachmentInfo {
  uuid: string;
  fileName: string;
  mimeType: string;
}

export interface AttachmentInfoExtras extends AttachmentInfo {
  file: File;
}

export interface AppealCategoryData {
  appealCategoryId: string;
  displayName: string;
  description: string;
  evpSupport: string[];
  vettingType: string[];
}

export interface AppealRequestData {
  categories: string[];
  explanation: string;
  attachments: string[];
}

export interface ExtVettingRecord {
  evpUid: string;
  brandUid: string;
  vettingClass: string;
  vettingId: string;
  vettingToken: string | null;
  tcrBillable: boolean;
  tcrBillAmount: number | null;
  vettedDate: number | Date | null;
  score: number | null;
  fullReport: string | null;
  vettingStatus: string;
  reasons: string | null;
  additionalRequestPayload: string | null;
  createDate: number | Date;
  updateDate: number | Date;
  expirationDate?: number | Date;
}

export enum EditModal {
  company = 'company',
  contact = 'contact',
  companyName = 'companyName',
  brandRelationship = 'brandRelationship',
}

export enum ModalType {
  newVetting = 1,
  vettingResult = 2,
  importVetting = 3,
  vettingStatus = 4,
  politicalVetting = 5,
}

export type VettingClassType =
  | 'STANDARD'
  | 'ENHANCED'
  | 'POLITICAL'
  | 'EXEMPT'
  | 'POLITICAL_FEDERAL'
  | 'POLITICAL_STATE'
  | 'POLITICAL_LOCAL';
export interface ImportVettingInstruction {
  info: string;
  parameters: {
    vettingId: { label: string; show: boolean };
    vettingToken: { label: string; show: boolean };
  };
  supported: boolean;
}
export interface NewVettingInstruction {
  info: string;
  price: {
    [key in VettingClassType]?: number;
  };
  supported: boolean;
  waitTime: string;
}
export interface VettingProvider {
  displayName: string;
  tcrBillable: boolean;
  uid: string;
  vettingClasses: VettingClassType[];
  vettingInstruction: {
    importVet: ImportVettingInstruction;
    newVet: NewVettingInstruction;
  };
}

export interface VerticalType {
  id: string;
  displayName: string;
  description: string;
}

export enum BrandAssetType {
  Banner = 'BANNER',
  Logo = 'LOGO',
}

export enum BrandAssetStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Unverified = 'UNVERIFIED',
  Expired = 'EXPIRED',
}
export interface BrandAsset {
  name: string;
  type: BrandAssetType;
  attachmentUuid: string;
  verificationStatus?: BrandAssetStatus | null;
  reasons?: string;
  vettedDate?: number;
  mimeType?: string;
  createDate?: number | Date | null;
  expireDate?: number | Date | null;
}

export interface BrandAssetExtended extends BrandAsset {
  file?: File;
}

export interface BrandAssetAppeal {
  brandId: string;
  assetName: string;
  appealStatus: string;
  categoryList: string[];
  attachmentUuidList: string[];
  explanation: string;
  appealOutcome: {
    vetStatus: string;
    feedback: {
      reasons: string[];
    };
  };
  appealStatusUpdateDate: number | Date | null;
  createDate: number | Date;
}
