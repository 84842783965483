import React, { useEffect, useMemo, useState, useRef } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Container, makeStyles } from '@material-ui/core';

import {
  fetchEventHistory,
  getAllEventTypes,
  downloadEventHistoryAsCsv,
} from '../apiServices';
import { globalGetService } from '../../../../utils/globalApiServices';
import { useDispatch } from 'react-redux';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import EventsListingRow from '../components/EventsListingRow';
import '../../../../assets/styles/events-listing-module.scss';
import { MAX_EVENT_RECORDS } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Table,
  FilterConfig,
  FilterType,
  TableFilter,
  WithFiltersConfig,
} from 'portal-commons';
import { Event } from '../types';
import {
  AdditionalFilters,
  CombinedFilters,
  DEFAULT_ADDITIONAL_FILTERS,
  Pagination,
  SelectItem,
} from '../../../../utils/types';
import { toastFlashMessage } from '../../../../utils';
import { generateAndDownloadFile } from '../../../../utils/download';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { withFilters } from '../../../../hocs';

const Today = new Date(new Date().setHours(0, 0, 0, 0));
const dateRangePresets = [
  {
    label: 'Today',
    start: Today,
    end: Today,
  },
  {
    label: 'Last 7 Days',
    start: moment(Today).subtract(7, 'days').toDate(),
    end: Today,
  },
  {
    label: 'Last 1 Month',
    start: moment(Today).subtract(1, 'months').toDate(),
    end: Today,
  },
  {
    label: 'Last 6 Months',
    start: moment(Today).subtract(6, 'months').toDate(),
    end: Today,
  },
];

const FILTER_CONFIGS = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 160,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 160,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 255,
  },
  eventType: {
    type: FilterType.Dropdown,
    label: 'Event Type',
    placeholder: 'Select event type',
    options: [],
    width: 285,
  },
  dateRange: {
    type: FilterType.DateRange,
    label: 'Date Range',
    placeholder: 'Select date range',
    delayUpdate: false,
    dateRangePresets,
  },
  billable: {
    type: FilterType.Dropdown,
    label: 'Billable Events',
    placeholder: 'Select billable events',
    options: [
      { label: 'Billable Events', value: 'true' },
      { label: 'Non-Billable Events', value: 'false' },
    ],
  },
};

const headRows = [
  { label: 'EVENT', id: 'eventType', sortable: true },
  { label: 'CATEGORY', id: 'categoryType', sortable: true },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: true },
  { label: 'BRAND ID', id: 'brandUid', sortable: true },
  { label: 'DATE & TIME', id: 'createDate', sortable: true },
  { label: 'DESCRIPTION', id: 'description', sortable: false },
];

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  note: {
    marginTop: 5,
    marginLeft: '12px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666E71',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '12px',
  },
});

const fetchBrandSuggestions = async (query = {}): Promise<string[]> => {
  const response = await globalGetService('csp/brands/suggestions', {
    ...query,
    limit: 20,
  });
  return response?.ok
    ? response.data?.map(
        (d: { brandName: string; brandUid: string }) => d?.brandName
      )
    : [];
};

interface Props extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
    setOptions: (field: string, options: SelectItem[]) => void;
  };
}

const EmptyPaginationData: Pagination<Event> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
};

const EventsList: React.FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    });
  };

  const clearLoadingMessage = () => {
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
    });
  };
  const [loader, setLoader] = useState(true);
  const [eventInfo, setEventInfo] =
    useState<Pagination<Event>>(EmptyPaginationData);
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilters>(
    DEFAULT_ADDITIONAL_FILTERS
  );
  const [tableLoader, setTableLoader] = useState(false);
  const [filtersShown, setFiltersShown] = useState(false);
  const listQueryRef = useRef<Record<string, unknown>>({});

  const restrictRecords = useMemo(() => {
    return eventInfo.totalRecords > MAX_EVENT_RECORDS
      ? MAX_EVENT_RECORDS
      : eventInfo.totalRecords;
  }, [eventInfo]);

  const restrictPagination = useMemo(() => {
    return {
      page: eventInfo.page,
      rowsPerPage: eventInfo.recordsPerPage,
      totalRecords: restrictRecords,
    };
  }, [eventInfo, restrictRecords]);

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const eventTypes = await getAllEventTypes();
    setOptions(
      'eventType',
      eventTypes.map((item) => ({
        label: item.id,
        value: item.id,
      }))
    );
  };

  const parseSearchParams = (params = ''): CombinedFilters => {
    let newAdditionFilters = { ...additionalFilters };
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      newAdditionFilters = { sortField: '', ascendingOrder: false };
    } else if (queries.sortField || queries.ascendingOrder) {
      newAdditionFilters = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }
    setAdditionalFilters(newAdditionFilters);

    return {
      ...newAdditionFilters,
      ...appliedFilters,
      page: (queries?.page as string) ?? 1,
    };
  };

  useEffect(() => {
    const params = location.search
      ? parseSearchParams(location.search)
      : { ...additionalFilters };
    fetchEventList(params);
  }, [location.search]);

  const dateRangePreprocess = (queries: CombinedFilters) => {
    const { dateRange, ...restQueries } = queries;
    const DATE_FORMAT = 'YYYY-MM-DD';
    if (dateRange) {
      const dates = (dateRange as string).replace(/\s/gi, '').split('-');
      if (dates.length === 2) {
        return {
          ...restQueries,
          startDate: convertTimeWithTimezone(dates[0], undefined, DATE_FORMAT),
          endDate: convertTimeWithTimezone(dates[1], undefined, DATE_FORMAT),
        };
      }
    }
    return restQueries;
  };

  const fetchEventList = async (query: CombinedFilters) => {
    setTableLoader(true);
    // store the last list query params for CSV download
    listQueryRef.current = dateRangePreprocess(query);
    const eventData = await fetchEventHistory(listQueryRef.current);
    if (eventData) {
      setEventInfo(eventData);
      if (
        eventData.records.length === 0 &&
        eventData.totalRecords > 0 &&
        eventData.page > 1
      ) {
        const lastPageNo = Math.ceil(
          eventData.totalRecords / eventData.recordsPerPage
        );
        setLoader(true);
        writeQueryString({ ...query, page: lastPageNo });
        return;
      }
    }
    setLoader(false);
    setTableLoader(false);
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;

    writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  const createSortHandler = (sortField: string, ascendingOrder: boolean) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (eventInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: eventInfo.page,
        sortField,
        ascendingOrder,
      });
    }
  };

  const writeQueryString = (searchParams = {}) => {
    history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    // the download query should be equal to the last successful list query
    const { page, ...restQuery } = {
      ...listQueryRef.current,
    } as CombinedFilters;
    const response = await downloadEventHistoryAsCsv(restQuery);
    if (response) {
      const file = new File([response], 'event-history.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  useEffect(() => {
    document.title = 'The Campaign Registry - Events';
    fetchFilterOptions();
  }, []);

  return (
    <section
      className="events-listing-section"
      style={{ padding: '0px' }}
      data-testid="eventLists"
    >
      {loader ? (
        <Loader />
      ) : (
        <Container maxWidth={false} style={{ padding: '0px' }}>
          <div className={classes.title}>
            <span>
              {!loader && restrictRecords > 0
                ? `${restrictRecords} Events`
                : 'Events'}
            </span>
            <span className={classes.note}>
              This page can only show the latest 10,000 records. Contact support
              for details on your remaining records.
            </span>
          </div>
          <div className={classes.buttons}>
            <Button
              color="secondary"
              onClick={() => setFiltersShown(!filtersShown)}
              data-testid="tableAddFilterButton"
            >
              <FontAwesomeIcon icon={faFilter} />
              <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleDownload}
              data-testid="tableDownloadButton"
            >
              <FontAwesomeIcon icon={faArrowDownToLine} />
              Download
            </Button>
          </div>
          {filtersShown && (
            <Grid container style={{ marginBottom: '12px' }}>
              <TableFilter
                configs={filter.configs}
                candidateValues={filter.candidateFilters}
                appliedValues={filter.appliedFilters}
                onCandidateValuesChange={filter.handleEdit}
                onAppliedValuesChange={filter.handleApply}
                data-testid="eventListTableFilter"
              />
            </Grid>
          )}
          <Grid container justifyContent="center" spacing={0}>
            <Table
              testId="eventListingTable"
              className="events-listing-table"
              headRows={headRows}
              emptyState="no events to view"
              disableHover
              loading={tableLoader}
              tableData={eventInfo.records.map((record, idx) => {
                return (
                  <EventsListingRow key={`record_${idx}`} event={record} />
                );
              })}
              handleChangePage={handleChangePage}
              createSortHandler={createSortHandler}
              filter={additionalFilters as CombinedFilters}
              pagination={restrictPagination}
            />
          </Grid>
        </Container>
      )}
    </section>
  );
};

export default withFilters(EventsList, {
  configs: FILTER_CONFIGS,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value: string) => fetchBrandSuggestions({ prefix: value }),
      },
    },
  },
});
