import { Button, usePermission } from 'portal-commons';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import InitiateMigrationModal from './InitiateMigrationModal';
import CancelMigrationModal from './CancelMigrationModal';
import { CNP_MIGRATION_ENABLED } from '../../../../constants';
import { campaignActionEligibility } from '../apis';

export default function CNPDetails(props) {
  const { campaign, nominationInfo, electedDcas, cnpOptions, isMigrating } =
    props;
  const [showElectBtn, setShowElectBtn] = useState(false);
  const [showStartMigration, setShowStartMigration] = useState(false);
  const [showCancelMigration, setShowCancelMigration] = useState(false);
  const [openMigrationDialog, setOpenMigrationDialog] = useState(false);
  const [openCancelMigrationDialog, setOpenCancelMigrationDialog] =
    useState(false);
  const { hasPermission } = usePermission();

  useEffect(() => {
    if (campaign.status === 'EXPIRED') {
      setShowElectBtn(false);
    } else if (nominationInfo.status) {
      if (
        ['DECLINED'].includes(nominationInfo.status) &&
        hasPermission('campaignDetail.electCnp')
      ) {
        setShowElectBtn(true);
      } else {
        setShowElectBtn(false);
      }
    } else if (hasPermission('campaignDetail.electCnp')) {
      setShowElectBtn(true);
    }
  }, [campaign.status, nominationInfo.status]);

  const updateCampaignActionEligibility = async (upstreamCnpUid) => {
    const response = await campaignActionEligibility(
      [campaign.uid],
      upstreamCnpUid
    );

    if (response && response[campaign.uid]) {
      const { startMigration, cancelMigration } = response[campaign.uid];
      setShowStartMigration(startMigration);
      setShowCancelMigration(cancelMigration);
    }
  };

  useEffect(() => {
    if (campaign.status === 'EXPIRED') {
      setShowStartMigration(false);
      setShowCancelMigration(false);
    } else {
      updateCampaignActionEligibility(nominationInfo?.upstreamCnpUid);
    }
  }, [campaign.status, nominationInfo?.upstreamCnpUid]);

  return (
    <>
      <div className="cnp-dca-details" data-testid="cnpDcaDetails">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <div
              className="cnp-details-wrapper"
              data-testid="cnpDcaDetailsConnectivityPartner"
            >
              <Grid item xs={4}>
                <p className="paragraph">Connectivity Partner:</p>
              </Grid>
              <Grid container xs={8} className="flex-centered">
                <Grid item xs={4} style={{ minWidth: '150px' }}>
                  <p className="paragraph">
                    <span>
                      {nominationInfo.status === 'DECLINED'
                        ? '--'
                        : nominationInfo.upstreamCnpName
                        ? nominationInfo.upstreamCnpName
                        : nominationInfo.upstreamCnpUid
                        ? nominationInfo.upstreamCnpUid
                        : 'NONE'}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={7}
                  style={{ display: 'inline-flex', gap: '4px' }}
                >
                  {showElectBtn ? (
                    <Button
                      className="elect-cnp-btn"
                      color="secondary"
                      shape="square"
                      size="small"
                      onClick={() => props.handleClickCnpAction(true)}
                    >
                      <img
                        src={
                          require('../../../../assets/images/elect-cnp-icon.svg')
                            .default
                        }
                        className="icon"
                      />
                      <span>Elect CNP</span>
                      <NotificationsNoneIcon className="alert-icon" />
                    </Button>
                  ) : null}
                  {CNP_MIGRATION_ENABLED && showStartMigration && (
                    <Button
                      shape="square"
                      color="secondary"
                      size="small"
                      disabled={
                        !hasPermission('campaignDetail.migrateCnp') ||
                        isMigrating
                      }
                      onClick={() => setOpenMigrationDialog(true)}
                    >
                      Initiate Migration
                    </Button>
                  )}
                  {CNP_MIGRATION_ENABLED && showCancelMigration && (
                    <Button
                      shape="square"
                      color="secondary"
                      size="small"
                      disabled={
                        !hasPermission('campaignDetail.migrateCnp') ||
                        isMigrating
                      }
                      onClick={() => setOpenCancelMigrationDialog(true)}
                    >
                      Cancel Migration
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <div
                  className={`dca-election-info ${
                    electedDcas.length ? 'completed' : 'pending'
                  }`}
                  data-testid="cnpDcaDetailsElectionInfo"
                >
                  {electedDcas.length ? (
                    <React.Fragment>
                      <img
                        src={
                          require(`../../../../assets/images/dca-election-completed-icon.svg`)
                            .default
                        }
                      />
                      <span>DCA Election Completed</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        src={
                          require(`../../../../assets/images/dca-election-pending-icon.svg`)
                            .default
                        }
                      />
                      <span>DCA Election Pending</span>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <InitiateMigrationModal
        open={openMigrationDialog}
        options={cnpOptions}
        onClose={() => setOpenMigrationDialog(false)}
        onSubmit={(value) => {
          setOpenMigrationDialog(false);
          props.handleMigration(value);
        }}
      />
      <CancelMigrationModal
        open={openCancelMigrationDialog}
        campaignUid={campaign.uid}
        onClose={() => setOpenCancelMigrationDialog(false)}
        onSubmit={(value) => {
          setOpenCancelMigrationDialog(false);
          props.handleCancelMigration(value);
        }}
      />
    </>
  );
}
