import { useEffect, useState } from 'react';
import { Button, MultimediaList, AttachmentPreview } from 'portal-commons';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { CustomTooltip } from '../../../../../shared_elements';
import {
  downloadAttachmentApi,
  downloadAttachmentToDesktop,
} from '../../apiServices';
import {
  AppealCategoryData,
  VettingAppeal,
  VettingAppealOutcome,
} from '../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    width: '700px',
    padding: '35px 30px 50px 30px',
    marginLeft: '200px',
  },
  title: {
    padding: '0',
    '& > *': {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '23px',
      color: '#071822',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    marginTop: '17px',
  },
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#49535D',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    width: '100%',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#00698F',
  },
  value: {
    flex: '1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#49535D',
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
  },
  categoryTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '17px',
    color: '#49535D',
  },
  categoryContent: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#49535D',
  },
  explanation: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#49535D',
    wordWrap: 'break-word',
    textAlign: 'justify',
    paddingRight: 2,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 226,
    background: '#FFFFFF',
  },
  appealOutcome: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  viewIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18,
    '&:hover': {
      borderRadius: '50%',
      background: '#D1E4EC',
      color: '#0091B3',
    },
  },
});

const parseDate = (timestamp: number | Date) => {
  const date = new Date(timestamp);
  return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
    -2
  )}/${date.getFullYear()}`;
};

interface Props {
  open: boolean;
  title: string;
  appeal: VettingAppeal;
  appealCategories: AppealCategoryData[];
  onClose: () => void;
}

const AppealHistoryModal: React.FC<Props> = ({
  open,
  title,
  appeal,
  appealCategories,
  onClose,
}) => {
  const classes = useStyles();
  const [attachmentPreviewIndex, setAttachmentPreviewIndex] = useState(-1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [previewFiles, setPreviewFiles] = useState<{ [k: string]: any }>({});
  const [outcome, setOutcome] = useState<VettingAppealOutcome | undefined>();

  const getAppealOutcome = () => {
    if (appeal && appeal.status.toLowerCase() !== 'pending' && outcome) {
      return outcome.vetScore || outcome.vetStatus;
    }
    return 'N/A';
  };

  const getTaxExemptStatus = () => {
    if (outcome && outcome.optionalAttributes) {
      if (outcome.optionalAttributes.section527) {
        return '527';
      }
      if (outcome.optionalAttributes.taxExemptStatus) {
        return outcome.optionalAttributes.taxExemptStatus;
      }
    }
    return 'N/A';
  };

  const getGovernmentEntity = () => {
    if (outcome && outcome.optionalAttributes) {
      if (typeof outcome.optionalAttributes.governmentEntity === 'boolean') {
        return outcome.optionalAttributes.governmentEntity ? 'True' : 'False';
      }
    }
    return 'N/A';
  };

  const handlePreview = async (index: number) => {
    const attachment = appeal.attachments ? appeal.attachments[index] : null;
    if (attachment) {
      setAttachmentPreviewIndex(index);
      if (!previewFiles[attachment.uuid]) {
        try {
          const response = await downloadAttachmentApi(attachment.uuid);
          setPreviewFiles({
            ...previewFiles,
            [attachment.uuid]: new File([response], attachment.fileName, {
              type: attachment.mimeType,
            }),
          });
        } catch (e) {
          // Ignore
        }
      }
    }
  };

  const handleDownload = async (index: number) => {
    const attachment = appeal.attachments ? appeal.attachments[index] : null;
    if (attachment) {
      try {
        await downloadAttachmentToDesktop(attachment.uuid, attachment.fileName);
      } catch (e) {
        // Ignore
      }
    }
  };

  useEffect(() => {
    if (appeal && appeal.appealOutcome) {
      setOutcome(JSON.parse(appeal.appealOutcome) as VettingAppealOutcome);
    } else {
      setOutcome(undefined);
    }
  }, [appeal]);

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.container,
      }}
      data-testid="appealHistoryModal"
    >
      {title && (
        <DialogTitle
          classes={{
            root: classes.title,
          }}
          data-testid="appealHistoryModalTitle"
        >
          {title} Appeal Request
        </DialogTitle>
      )}

      <DialogContent
        classes={{
          root: classes.content,
        }}
        data-testid="appealHistoryModalContent"
      >
        <div className={classes.sections}>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>Appeal History Details</div>
            <div className={classes.status}>
              <div className={classes.label}>
                Vetting Status:
                <br />
                Request Date:
                <br />
                Status Update Date:
                <br />
                {title} Appeal Outcome:
                <br />
                Tax Exempt Status:
                <br />
                Government Entity:
              </div>
              <div className={classes.value}>
                {appeal
                  ? `${appeal.status.slice(0, 1).toUpperCase()}${appeal.status
                      .slice(1)
                      .toLowerCase()}`
                  : 'N/A'}
                <br />
                {appeal ? parseDate(appeal.createDate) : 'N/A'}
                <br />
                {appeal
                  ? appeal.status.toLowerCase() === 'pending'
                    ? 'Pending'
                    : parseDate(appeal.statusUpdateDate)
                  : 'N/A'}
                <br />
                <div className={classes.appealOutcome}>
                  {getAppealOutcome()}
                  {(outcome?.feedback?.category?.length || 0) > 0 && (
                    <CustomTooltip
                      placement={'top-end'}
                      arrow
                      title={
                        <ul style={{ listStyleType: 'none', maxWidth: 300 }}>
                          {outcome?.feedback?.category?.map((item) => (
                            <li key={item.id}>
                              {item.id} - {item.description}
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <span className={classes.viewIcon} data-testid="feedback">
                        <FontAwesomeIcon
                          icon={faEye}
                          size="xs"
                          className={classes.viewIcon}
                        />
                      </span>
                    </CustomTooltip>
                  )}
                </div>
                {getTaxExemptStatus()}
                <br />
                {getGovernmentEntity()}
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Appeal Categories</div>
            {appeal &&
              appeal.categoryList.split(',').map((category) =>
                appealCategories
                  .filter(
                    (appealCategory) =>
                      appealCategory.appealCategoryId === category
                  )
                  .map((appealCategory) => (
                    <div
                      key={appealCategory.appealCategoryId}
                      className={classes.category}
                    >
                      <div className={classes.categoryTitle}>
                        {appealCategory.displayName}
                      </div>
                      <div className={classes.categoryContent}>
                        {appealCategory.description}
                      </div>
                    </div>
                  ))
              )}
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Explanation</div>
            <div className={classes.explanation}>
              {appeal && appeal.explanation ? appeal.explanation : 'N/A'}
            </div>
          </div>
        </div>

        <MultimediaList
          title="Sample Multimedia Files"
          emptyMessage="No files have been attached"
          style={{ marginTop: '20px', height: '160px' }}
          attachments={appeal && appeal.attachments ? appeal.attachments : []}
          onSelect={handlePreview}
          onDownload={handleDownload}
          loading={
            attachmentPreviewIndex > -1 &&
            appeal.attachments &&
            !previewFiles[appeal.attachments[attachmentPreviewIndex].uuid]
          }
          data-testid="appealHistorySampleMultiMediaList"
        />

        <div className={classes.actions}>
          <Button
            portal="csp"
            onClick={onClose}
            data-testid="appealHistoryModalCloseButton"
          >
            Close
          </Button>
        </div>
      </DialogContent>

      {attachmentPreviewIndex > -1 &&
        appeal.attachments &&
        appeal.attachments[attachmentPreviewIndex] &&
        previewFiles[appeal.attachments[attachmentPreviewIndex].uuid] && (
          <AttachmentPreview
            onClose={() => setAttachmentPreviewIndex(-1)}
            file={previewFiles[appeal.attachments[attachmentPreviewIndex].uuid]}
            mimeType={appeal.attachments[attachmentPreviewIndex].mimeType}
          />
        )}
    </Dialog>
  );
};

export default AppealHistoryModal;
